import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { useMemo } from 'react'
import BarDCPercent from '../BarDCPercent'
import styles from './CardDCSnapshotCluster.module.css'

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
})

export default function CardDCSnapshotCluster({
  singleData,
  allData,
  color1 = 'var(--color-primary-solid)',
  color2 = 'var(--color-primary-light)',
  title,
  className,
  noBorder = false,
}) {
  const domainTotalAmount = useMemo(() => {
    if (allData) {
      return extent(allData.map((d) => d.total_amount))
    }
    return []
  }, [allData])

  const scaleCircle = useMemo(() => {
    if (domainTotalAmount) {
      return scaleLinear()
        .domain([domainTotalAmount[0], domainTotalAmount[1]])
        .range([20, 50])
    }
  }, [domainTotalAmount])

  const disbursmentPercent = useMemo(() => {
    return Math.round(
      (singleData.total_disbursment / singleData.total_amount) * 100
    )
  }, [singleData])

  const awardedPercent = useMemo(() => {
    return Math.round(
      (singleData.total_approved / singleData.total_amount) * 100
    )
  }, [singleData])

  return (
    <div
      style={{ overflowX: 'hidden', overflowY: 'auto' }}
      className={`${className}`}
    >
      <div className={styles.Title}>{title}</div>
      <div className="d-flex align-items-center mb-1" style={{ height: '30%' }}>
        <div className="col-md-4">
          <svg
            width={'100%'}
            height={'100%'}
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="gradient">
                <stop offset="13.63%" stopColor={color1} />
                <stop offset="86.37%" stopColor={color2} />
              </linearGradient>
            </defs>
            <g>
              <circle
                fill="url('#gradient')"
                cx={'50%'}
                cy={'50%'}
                r={scaleCircle(singleData.total_amount)}
              ></circle>
              <rect
                className="rect-cluster"
                stroke="#EEF1FA"
                strokeWidth={0.5}
                rx={10}
                x={20}
                y={'50%'}
                width={120}
                height={45}
                fill="rgba(249, 250, 254, 0.9)"
              ></rect>
              <text
                fontSize={12}
                y={'60%'}
                x={35}
                fontWeight={325}
                // stroke="black"
              >
                Approved amount
              </text>
              <g>
                <text
                  y={'72%'}
                  x={
                    amountFormatter.format(singleData.total_amount).length < 7
                      ? '35%'
                      : '28%'
                  }
                >
                  <tspan fontSize={12} offset={10} fontWeight={300}>
                    $
                  </tspan>
                  <tspan>{'   '}</tspan>
                  <tspan fontWeight={350} fontSize={14}>
                    {amountFormatter.format(singleData.total_amount)}
                  </tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
        <div className={`col-md-8`}>
          <div className="d-flex align-items-center">
            <div className={styles.LabelAmount}>Cumulative disbursement</div>
            <div className={styles.ValueAmount}>
              <span className={styles.Unit}>$</span>
              {amountFormatter.format(singleData.total_disbursment)}
            </div>
          </div>
          <BarDCPercent
            color={'linear-gradient(119.02deg, #68C5EA 13.63%, #ABDDF1 86.37%)'}
            colorText={'var(--color-sectors-solid-ene)'}
            percent={disbursmentPercent}
          />
          <div className="d-flex align-items-center">
            <div className={styles.LabelAmount}>Cumulative contract award</div>
            <div className={styles.ValueAmount}>
              <span className={styles.Unit}>$</span>
              {amountFormatter.format(singleData.total_approved)}
            </div>
          </div>
          <BarDCPercent
            color={'linear-gradient(119.02deg, #055A82 13.63%, #7698A8 86.37%)'}
            percent={awardedPercent}
            colorText={'var(--color-sectors-solid-psm)'}
          />
          {!noBorder && (
            <div className="pe-3">
              <hr style={{ marginTop: 5, marginBottom: 5 }} />
            </div>
          )}
          <div className="d-flex align-items-center">
            <div className={styles.LabelAmount}>Number of products</div>
            <div className={styles.ValueAmount}>{singleData.num_projects}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Spinner() {
  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <div className="spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
      </div>
    </div>
  )
}

import { rj, SUCCESS, useRunRj } from 'react-rocketjump'
import api from '../api'

const PortfolioInfoTrendState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[0]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'PorfolioInfoTrend',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/products/portfolio-information-trend', params),
  })

const PortfolioInfoSnapshotState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[1]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'PorfolioInfoSnapshot',
    effectCaller: 'configured',
    effect:
      (t) =>
      (year, params = {}) =>
        api.auth(t).get(`/api/products/portfolio-information/${year}`, params),
  })

const PortfolioInfoSnapshotTotals = rj().effect({
  name: 'PorfolioInfoSnapshotTotals',
  effectCaller: 'configured',
  effect: (t) => (year, params = {}) =>
    api.auth(t).get(`/api/products/portfolio-information-sovereign/${year}`, params),
})

const PortfolioSnapshotMinMaxCountry = rj().effect({
  name: 'PortfolioSnapshotMinMaxCountry',
  effectCaller: 'configured',
  effect: (t) => (year, params = {}) =>
    api.auth(t).get(`/api/products/portfolio-information-min-max-country/${year}`, params),
})

const PortfolioSnapshotTotalsYear = rj().effect({
  name: 'PortfolioSnapshotTotalsYear',
  effectCaller: 'configured',
  effect: (t) => (year, params = {}) =>
    api.auth(t).get(`/api/products/adb-totals/${year}`, params),
})

export function usePortfolioInfoTrend(params) {
  return useRunRj(PortfolioInfoTrendState, [params], false)
}

export function usePortfolioInfoSnapshot(year, params) {
  return useRunRj(PortfolioInfoSnapshotState, [year, params], false)
}

export function usePortfolioInfoSnapshotTotals(year, params) {
  return useRunRj(PortfolioInfoSnapshotTotals, [year, params], false)
}

export function usePortfolioInfoSnapshotMinMaxCountry(year, params) {
  return useRunRj(PortfolioSnapshotMinMaxCountry, [year, params], false)
}

export function usePortfolioSnapshotTotals(year, params) {
  return useRunRj(PortfolioSnapshotTotalsYear, [year, params], false)
}


import { createContext, useContext, useMemo } from 'react'
import { useAuthState } from 'use-eazy-auth'
import { useLastImportApi } from './hooks/lastImport'

const LastImportContext = createContext(null)

/**
 * @returns {string | null}
 */
export function useLastImportDate() {
  const [state] = useContext(LastImportContext)
  return state.data
}

export function useRefreshImportDate() {
  const actions = useContext(LastImportContext)[1]
  return actions.run
}

export function LastImportProvider({ children, preview = false }) {
  const filtersForLastImport = useMemo(() => {
    if (preview) {
      return {
        preview,
      }
    }
    return {}
  }, [preview])
  const stateNActions = useLastImportApi(filtersForLastImport)
  const [state] = stateNActions
  if (state.data === null) {
    // TODO: Spinner Boy
    return null
  }
  return (
    <LastImportContext.Provider value={stateNActions}>
      {children}
    </LastImportContext.Provider>
  )
}

export default function LastImport({ children, preview = false }) {
  const { authenticated } = useAuthState()
  if (authenticated) {
    return <LastImportProvider preview={preview}>{children}</LastImportProvider>
  }
  return children
}

import styles from './About.module.css'
import arrow from './assets/arrow-black.svg'

export default function SmallIslandDevelopingStates() {
  return (
    <div className="row pt-5 mb-5" data-test="text-small-island">
      <div className="offset-md-1 col-md-10">
        <h2 className={styles.Title}>Small Island Developing States</h2>
        <div className={styles.Subtitle}>
          Small Island Developing States (SIDS) are island states which are
          small, remote and geographically dispersed. They have narrow asset
          bases and are vulnerable to environmental challenges, such as climate
          change. These structural constraints manifest themselves in heightened
          economic vulnerability and elevated cost structures.
        </div>
        <div className={styles.BlockContent}>
          <div className={styles.MiniTitle}>
            list of adb member countries identified as sids
          </div>
          <div className={styles.Paragraph}>
            A total of 16 ADB developing member countries (DMCs) are classified
            as SIDS. It includes Cook Islands, Fiji, Federated States of
            Micronesia, Kiribati, Maldives, Marshall Islands, Nauru, Niue,
            Palau, Papua New Guinea, Samoa, Solomon Islands, Timor-Leste, Tonga,
            Tuvalu, and Vanuatu.
          </div>
          <div className={styles.Paragraph}>
            Nine of the SIDS DMCs are also classified as Fragile and Conflict
            Affected-Situations (FCAS). The DMCS which are classified as both
            FCAS and SIDS include Federal States of Micronesia, Kiribati,
            Marshall Islands, Nauru, Palau, Papua New Guinea, Solomon Islands,
            Timor-Leste, and Tuvalu.
          </div>
          <div className={styles.MiniTitle}>
            diverse characteristics of sids
          </div>
          <div className={styles.Paragraph}>
            <ul>
              <li className="mb-3">
                <span className="fw-350">Larger Resource Exporters</span>: Papua
                New Guinea, Solomon Islands, and Timor-Leste - largest SIDS by
                population, with substantial agricultural and mineral resources
                for export income.
              </li>
              <li className="mb-3">
                <span className="fw-350">Tourism-Based Economies</span>: Cook
                Islands, Fiji, Maldives, Palau, Samoa, and Vanuatu - significant
                income from inbound tourism.
              </li>
              <li>
                <span className="fw-350">Smaller Islands and Atolls</span>:
                Kiribati, Marshall Islands, Micronesia, Nauru, Tonga, and Tuvalu
                - narrowest resource bases and the most acute challenges in
                terms of geographic remoteness and dispersion.
              </li>
            </ul>
          </div>
          <div className={styles.Paragraph}>
            Above grouping is not deterministic or exclusive—as they share
            common characteristics. It is also conceivable that small islands
            and atolls can become tourism-based economies in the future.
          </div>
          <div className={styles.MiniTitle}>
            A differentiated approach for FCAS and SIDS
          </div>
          <div className={styles.Paragraph}>
            The challenges of countries experiencing fragility and conflict
            require long-term solutions, and building capable and legitimate
            institutions is critical. Refining ADB’s approach to context
            sensitive situations is an important element in implementing
            Strategy 2030. Focusing on FCAS and defining the necessary
            adjustments in SIDS operations will be key part of the ADB’s support
            for the fulfillment of the Sustainable Development Goals (SDGs).
            Consistent with the broader global dialogue on the
            peace-humanitarian nexus and aid effectiveness, ADB is adapting its
            approach to FCAS and SIDS developing and strengthening linkages with
            the seven operational priorities and the private sector plan that
            support the implementation of Strategy 2030.
          </div>
          <div className={styles.MiniTitle}>External links</div>
          <div className={styles.ParagraphLink}>
            <a
              rel="noreferrer"
              target={'_blank'}
              className={styles.ExternalLink}
              href="https://www.adb.org/what-we-do/themes/fcas-sids/main"
            >
              FCAS and SIDS Approach (FSA)
              <span className="ms-5">
                <img src={arrow} alt="Indicative pipeline" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import GradientClusters from '../GradientsClusters'
import { scaleLinear } from 'd3'
import { titleCase } from '../../pages/CompletedProducts/CountryBeeSwarm'
import { amountFormatter } from '../CardSnapshotCluster/CardSnapshotCluster'

export function ChartCirclePack({
  data,
  computeLabel,
  computeValue,
  computeColor,
  marginLeft = 20,
  marginRight = 20,
  marginTop = 20,
  smallerRadius = 10,
  marginBottom = 20,
  cluster,
  paddingCircles = cluster && cluster !== 'project__sector_code' ? 30 : 10,
  width: forcedWidth,
  height: forcedHeight,
  dataMin,
  dataMax,
  country,
  openProject,
  hoverProject,
  year,
}) {
  const svgRef = useRef()
  const divTooltip = useRef()
  const [, setFontsDidLoad] = useState(false)

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsDidLoad(true)
    })
  }, [])

  useEffect(() => {
    if (!svgRef.current) {
      return
    }
    const svg = d3.select(svgRef.current)

    const size = svgRef.current.parentElement.offsetWidth

    const width = forcedWidth ?? size
    const height = forcedHeight ?? size

    let tooltip = d3
      .select(divTooltip.current)
      .attr('class', 'tooltip')
      .style('opacity', 0)

    let d3Data = data
    if (Array.isArray(d3Data)) {
      d3Data = { fakeRoot: true, children: d3Data }
    }
    const root = d3.hierarchy(d3Data)
    root.sum((d) => Math.max(0, computeValue(d)))
    const descendants = root.descendants()
    const leaves = descendants.filter((node) => !node.children)
    root.sort(d3.descending)

    let layout = d3
      .pack()
      .size([
        width - marginLeft - marginRight,
        height - marginTop - marginBottom,
      ])
      .padding(paddingCircles)

    if (dataMin !== undefined && dataMax !== undefined) {
      const scale = scaleLinear()
        .domain([dataMin, dataMax])
        .range([smallerRadius, size / 10])
      layout = layout.radius((node) => scale(node.value))
    }

    layout(root)

    svg
      .attr('viewBox', [-marginLeft, -marginTop, width, height])
      .attr('width', width)
      .attr('height', height)
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
      .attr('text-anchor', 'middle')

    const circles = svg.selectAll('g').data(leaves)

    const newCircles = circles.enter().append('g')

    newCircles
      .append('circle')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('fill', (d) => (d.children ? '#fff' : computeColor(d)))
      .attr('fill-opacity', 0)
      .classed('pointer', true)
      .attr('r', 0)
      .transition()
      .duration(1200)
      .attr('r', (d) => d.r)
      .attr('fill-opacity', (d) => {
        return d.children ? 0 : 1
      })

    if (typeof computeLabel === 'function') {
      newCircles.append('rect').classed('label-shadow', true)
      newCircles.append('rect').classed('label-bg', true)
      newCircles
        .append('text')
        .text(computeLabel)
        .attr('stroke', 'var(--color-basic-dark-grey)')
        .attr('stroke-width', 0.2)
        .attr('x', (d) => d.x)
        .attr('font-weight', 350)
        .attr('font-size', 12)
        .attr('y', (d) => d.y)
    }

    const allCircles = circles.merge(newCircles)

    if (hoverProject) {
      allCircles
        .select('circle')
        .attr('class', 'circle-chart')
        .attr('cx', (d) => d.x)
        .attr('cy', (d) => d.y)
        .attr('fill', (d) => (d.children ? '#fff' : computeColor(d)))
        .attr('r', 0)
        .classed('pointer', true)
        .attr('fill-opacity', (d) => {
          return d.children
            ? 0
            : hoverProject
            ? d.data.pk === hoverProject
              ? 1
              : 0.2
            : 1
        })
        .attr('r', (d) => d.r)
    } else {
      allCircles
        .select('circle')
        .attr('class', 'circle-chart')
        .attr('cx', (d) => d.x)
        .attr('cy', (d) => d.y)
        .attr('fill', (d) => (d.children ? '#fff' : computeColor(d)))
        .attr('r', 0)
        .classed('pointer', true)
        .attr('fill-opacity', (d) => {
          return d.children ? 0 : 1
        })
        .attr('r', (d) => d.r)
    }

    const textWidths = []

    allCircles
      .select('text')
      .text(computeLabel)
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y)
      .attr('text-anchor', (d) =>
        d.r > 40
          ? 'middle'
          : d.x > (width - marginLeft - marginRight) / 2
          ? 'start'
          : 'end'
      )
      .attr('alignment-baseline', (d) =>
        d.r > 40
          ? 'central'
          : d.y > (height - marginTop - marginBottom) / 2
          ? 'hanging'
          : 'baseline'
      )
      .attr('dy', (d) =>
        d.r > 40 ? 0 : d.y > (height - marginTop - marginBottom) / 2 ? 7 : -7
      )
      .attr('dx', (d) =>
        d.r > 40 ? 0 : d.x > (width - marginLeft - marginRight) / 2 ? 7 : -7
      )
      .each(function (d, i) {
        textWidths.push(this.getBBox())
      })
      .style('opacity', 0)
      .transition()
      .duration(1200)
      .style('opacity', 1)

    allCircles
      .select('.label-bg')
      .attr('x', (d, i) => textWidths[i].x - 7)
      .attr('y', (d, i) => textWidths[i].y - 7)
      .attr('width', (d, i) => textWidths[i].width + 14)
      .attr('height', (d, i) => textWidths[i].height + 14)
      .attr('rx', 7.35)
      .attr('ry', 7.35)
      .style('box-shadow', '0px 0px 8px rgba(13, 142, 202, 0.22)')
      .style('fill', 'rgba(249, 250, 254, 0.9)')
      .style('stroke', '#EEF1FA')
      .style('stroke-width', '0.5px')
      .style('opacity', 0)
      .transition()
      .duration(1200)
      .style('opacity', 1)

    country &&
      allCircles
        .on('click', function (d) {
          openProject.open(d.target.__data__.data.project__pk)
        })
        .on('mousemove', function (d) {
          d3.selectAll('circle').attr('fill-opacity', 0.2)
          d3.selectAll('circle').attr('stroke-opacity', 0.2)
          d3.select(d.target).attr('fill-opacity', 1)
          d3.select(d.target).attr('stroke-opacity', 1)
          const targetId = d.target.__data__.data.pk.toString()
          if (tooltip.attr('data-target-id') !== targetId) {
            tooltip
              .html(
                `<span class='fw-350 text-color-basic-dark-grey'> ${
                  d.target.__data__.data.project__country_name
                } (${
                  d.target.__data__.data.fcas && d.target.__data__.data.sids
                    ? 'FCAS / SIDS'
                    : d.target.__data__.data.sids
                    ? 'SIDS'
                    : 'FCAS'
                })</span><br />
              <div class='project-title-tooltip' data-test="label-info">
              ${titleCase(d.target.__data__.data.project__project_title)}</div>
              <div class='mt-3 d-flex'>
              <div class='fw-300 fs-12 width-100 me-3'>Sector:</div><div class='width-100 fw-350'>${
                d.target.__data__.data.project__sector
              }</div></div>
                <div class='mt-3 d-flex'>
                <div class='fw-300 fs-12 width-100 me-3'>Amount ($ mn ):</div><div class='fw-350'>${amountFormatter.format(
                  d.target.__data__.data.amount
                )}</div></div>
                <div class='mt-3 d-flex'>
                <div class='fw-300 fs-12 width-100 me-3'>Closing Date:</div><div class='fw-350'>${
                  d.target.__data__.data.closing_date
                }</div></div>
                <div class='mt-3 d-flex'>
                <div class='fw-300 fs-12 width-100 me-3'>Product:</div><div class='fw-350'>${
                  d.target.__data__.data.product_type
                }</div></div>
                <div class='text-end mt-3 fw-325 text-primary-asian'>
                Click to learn more
                </div>
                          `
              )
              .style('opacity', 1)
              .style('width', 180)
              .style('display', 'block')
              .style('padding', '10px')
              .style('background', 'white')
              .style('border-radius', '10px')
              .style('box-shadow', '0px 0px 8px rgba(13, 142, 202, 0.22)')
              .style('border', '0.5px solid #EEF1FA')
              .attr('data-target-id', targetId)
          }

          let x = d.clientX + 10
          let y = d.clientY + 10

          const txp = d.clientX > window.innerWidth / 2 ? '-100%' : '0px'
          const typ = d.clientY > window.innerHeight / 2 ? '-100%' : '0px'

          const txf = d.clientX > window.innerWidth / 2 ? '-20px' : '0px'
          const tyf = d.clientY > window.innerHeight / 2 ? '-20px' : '0px'

          tooltip
            .style('left', x + 'px')
            .style('top', y + 'px')
            .style(
              'transform',
              `translate(${txp}, ${typ}) translate(${txf}, ${tyf})`
            )
        })
        .on('mouseout', function (_) {
          d3.selectAll('circle').attr('fill-opacity', 1)
          d3.selectAll('circle').attr('stroke-opacity', 1)
          tooltip.style('opacity', 0)
        })

    circles.exit().remove()
  }, [
    computeColor,
    computeLabel,
    computeValue,
    data,
    hoverProject,
    country,
    dataMax,
    dataMin,
    forcedHeight,
    forcedWidth,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    paddingCircles,
    smallerRadius,
    openProject,
  ])

  return (
    <>
      <svg ref={svgRef} data-test="circle">
        <defs>
          <GradientClusters />
        </defs>
      </svg>
      <div ref={divTooltip}></div>
    </>
  )
}

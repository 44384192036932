import AutoTSBreadcrumb from './AutoTSBreadcrumb'
import AutoTSSwitch from './AutoTSSwitch'
import MenuLayout from './MenuLayout'
import iconSectors from './assets/info-sectors.svg'
import { useState } from 'react'
import TooltipSectors from './TooltipSectors'

/**
 * @param {{
 *  children: React.ReactNode
 *  breadcrumbLabel: string
 *  subtitle: string
 *  filters: React.ReactNode
 * }} props
 */
export default function AutoTSMenuLayout({
  children,
  breadcrumbLabel,
  showSectorTooltip = false,
  dataTest,
  ...props
}) {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <MenuLayout {...props}>
      <div className="h-100 d-flex flex-column" data-test={dataTest}>
        <div className="d-flex align-items-center justify-content-between">
          <AutoTSBreadcrumb label={breadcrumbLabel} />
          <div className="d-flex align-items-center">
            {showSectorTooltip && (
              <div
                className="me-4"
                onMouseLeave={() => setShowTooltip(false)}
                onMouseEnter={() => setShowTooltip(true)}
              >
                <img src={iconSectors} alt="Show Sectoris" />
                <span className='ms-2 label-sectors'>SECTORS</span>
              </div>
            )}
            <AutoTSSwitch />
            {showTooltip &&
              <TooltipSectors />
            }
          </div>
        </div>
        <div className="flex-1 overflow-hidden">{children}</div>
      </div>
    </MenuLayout>
  )
}

import styles from './Select.module.css'
import ReactSelect from 'react-select'
import { useMemo } from 'react'
import { find } from 'lodash'

export default function Select({
  className = '',
  onChange,
  label = null,
  value,
  classSelect = '',
  options,
  isDisabled = false,
  yearSelect = false,
  closeMenuOnSelect = true,
  isMulti,
  ...props
}) {
  const objVale = useMemo(() => {
    if (isMulti) {
      return options.filter((o) => value.includes(o.value))
    } else {
      return find(options, { value }) ?? null
    }
  }, [isMulti, options, value])

  return (
    <div className={className} data-test="option">
      {label && <label className={`${styles.Label} form-label`}>{label}</label>}
      <ReactSelect
        isDisabled={isDisabled}
        // styles={customStyles}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
          }),
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        openMenuOnClick={true}
        value={objVale}
        className={`react-select-container ${classSelect}`}
        classNamePrefix={yearSelect ? 'react-select-year' : 'react-select'}
        onChange={onChange}
        isSearchable={false}
        hideSelectedOptions={false}
        options={options}
        isClearable={false}
        isMulti={isMulti}
        {...props}
      />
    </div>
  )
}

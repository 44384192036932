import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { useRef, useState } from 'react'
import { Menu } from '../../components/MenuLayout/MenuLayout'
import arrowRight from './assets/arrow-right.svg'
import styles from './Home.module.css'
import Tutorial from '../../components/Tutorial'
import Portfolio from './components/Portfolio'
import Ppr from './components/Ppr'
import Disbursement from './components/Disbursment'
import Completed from './components/Completed'
import Indicative from './components/Indicative'
import Search from './components/Search'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { ReactComponent as CloseVideo } from './assets/close-video.svg'
import { useAuthUser } from 'use-eazy-auth'
import PreviewMode from '../../components/PreviewMode'

function Cards({ activeSection }) {
  return (
    <svg
      width="453"
      height="578"
      viewBox="0 0 453 578"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test={`card-${activeSection}`}
    >
      <g filter="url(#filter0_d_2162_65310)">
        <g filter="url(#filter1_d_2162_65310)">
          <rect
            width="220.774"
            height="426.264"
            rx="9.5573"
            transform="matrix(-0.990527 0.137318 0.129188 0.99162 307.44 85.1311)"
            fill="#F9FAFE"
          />
        </g>
      </g>
      <g filter="url(#filter2_d_2162_65310)">
        <g filter="url(#filter3_d_2162_65310)">
          <rect
            width="220.774"
            height="426.264"
            rx="9.5573"
            transform="matrix(0.990527 0.137318 -0.129188 0.99162 145.72 85.1311)"
            fill="#F9FAFE"
          />
        </g>
      </g>
      <g opacity="0.2" filter="url(#filter4_f_2162_65310)">
        <rect
          width="426.462"
          height="260.153"
          rx="95.573"
          transform="matrix(0.00764433 -0.999971 -0.996961 -0.0779022 354.693 535.148)"
          fill="#0D8ECA"
        />
      </g>
      <g filter="url(#filter5_d_2162_65310)">
        <g filter="url(#filter6_d_2162_65310)">
          <rect
            x="115.4"
            y="97.9897"
            width="220.774"
            height="426.264"
            rx="9.5573"
            fill="#F9FAFE"
          />
        </g>
      </g>
      {activeSection === 'portfolio' && <Portfolio />}
      {activeSection === 'ppr' && <Ppr/>}
      {activeSection === 'disbursment' && <Disbursement />}
      {activeSection === 'completed' && <Completed />}
      {activeSection === 'indicative' && <Indicative />}
      {activeSection === 'search' && <Search />}
      <defs>
        <filter
          id="filter0_d_2162_65310"
          x="66.7578"
          y="63.1311"
          width="317.502"
          height="495.788"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7625 0 0 0 0 0.749792 0 0 0 0 0.749792 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2162_65310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2162_65310"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2162_65310"
          x="85.9111"
          y="82.3518"
          width="279.444"
          height="458.567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.556863 0 0 0 0 0.792157 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2162_65310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2162_65310"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_2162_65310"
          x="68.8994"
          y="63.1311"
          width="317.502"
          height="495.788"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7625 0 0 0 0 0.749792 0 0 0 0 0.749792 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2162_65310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2162_65310"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_2162_65310"
          x="87.8047"
          y="82.3518"
          width="279.444"
          height="458.567"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.556863 0 0 0 0 0.792157 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2162_65310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2162_65310"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_f_2162_65310"
          x="0.485558"
          y="0.0104599"
          width="452.313"
          height="623.56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="47.7865"
            result="effect1_foregroundBlur_2162_65310"
          />
        </filter>
        <filter
          id="filter5_d_2162_65310"
          x="93.4004"
          y="75.9897"
          width="264.774"
          height="470.264"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7625 0 0 0 0 0.749792 0 0 0 0 0.749792 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2162_65310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2162_65310"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_2162_65310"
          x="111.4"
          y="93.9897"
          width="228.774"
          height="434.264"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0509804 0 0 0 0 0.556863 0 0 0 0 0.792157 0 0 0 0.22 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2162_65310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2162_65310"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_di_2162_65310"
          x="149.541"
          y="170.36"
          width="152.492"
          height="152.492"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.713726 0 0 0 0 0.788235 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2162_65310"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2162_65310"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.713726 0 0 0 0 0.788235 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_2162_65310"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2162_65310"
          x1="165.034"
          y1="212.907"
          x2="286.541"
          y2="280.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D8ECA" />
          <stop offset="1" stopColor="#72D2FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default function Home() {
  const [menu, actionsMenu] = useModalTrigger()
  const { user } = useAuthUser()
  const [tutorial, actionsTutorial] = useModalTrigger()
  const [activeSection, setActiveSection] = useState('portfolio')
  const [videoPlaying, setPlaying] = useState(false)
  const navigate = useNavigate()
  const vidRef = useRef(null)
  const [searchParams] = useSearchParams()
  const preview = searchParams.get('preview') ?? ''

  const handlePlayVideo = () => {
    setPlaying(true)
  }

  return (
    <div className="h-100 w-100 bg-white position-relative" data-test="page-homepage">
      <div className="h-100 w-100">
        <video
          autoPlay
          loop
          muted
          className={styles.Video}
          type="video/mp4"
          ref={vidRef}
        >
          <source src={user.video_urls.adb_loop} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className={styles.BackgroundVideo}></div>
        <Modal
          fullscreen
          isOpen={videoPlaying}
          toggle={() => setPlaying(!videoPlaying)}
          data-test="modal-video"
        >
          <ModalBody>
            <video
              className={styles.Video}
              type="video/mp4"
              controls
              autoPlay
              // ref={vidBigRef}
            >
              <source src={user.video_urls.adb_video} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
            <div
              onClick={() => setPlaying(!videoPlaying)}
              className={styles.CloseVideo}
            >
              <CloseVideo />
            </div>
          </ModalBody>
        </Modal>

        <div
          className={`${styles.FixedMenu} pointer`}
          onClick={() => actionsMenu.open()}
          data-test="burger-menu"
        >
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              rx="22.5"
              className={styles.BurgerCircle}
              fill="#F9FAFE"
            />
            <path
              d="M15 29H31M15 17H31H15ZM15 23H31H15Z"
              stroke="#0D8ECA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              rx="22.5"
              stroke="#0D8ECA"
            />
          </svg>
        </div>
        <div className={styles.ContainerTitle}>
          <div className={styles.AsianTitle}>asian development bank</div>
          <div className={styles.Title}>
            FCAS and SIDS Portfolio
            <br />
            Visualization Tool
          </div>
          <div onClick={handlePlayVideo} className={styles.ButtonWatch} data-test="button-video">
            Watch the video
          </div>
        </div>
        <Menu menu={menu} actionsMenu={actionsMenu} />
      </div>
      <div>
        <div className="text-center pt-5 pb-3 fs-16">
          <span className="fw-300">EXPLORE</span>
          <span className="fw-350"> OUR DATA</span>
        </div>
        <div className={`${styles.Box} mt-2`}>
          <div className="row">
            <div className={`pt-5 mt-5 offset-md-1 col-md-4`}>
              <div
                className={
                  activeSection === 'portfolio'
                    ? styles.BoxItemActive
                    : styles.BoxItem
                }
                onClick={() => navigate('/portfolio-distribution/trend?preview=' + preview)}
                onMouseEnter={() => setActiveSection('portfolio')}
                data-test="item-portfolio"
              >
                Portfolio distribution
                {activeSection === 'portfolio' && (
                  <div className={styles.ClickToExplore}>
                    Click to explore{' '}
                    <span>
                      <img src={arrowRight} alt="Arrow right" />
                    </span>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate('/project-performance-rating/trend?preview=' + preview)}
                className={
                  activeSection === 'ppr'
                    ? styles.BoxItemActive
                    : styles.BoxItem
                }
                onMouseEnter={() => setActiveSection('ppr')}
                data-test="item-ppr"
              >
                Project Performance Rating
                {activeSection === 'ppr' && (
                  <div className={styles.ClickToExplore}>
                    Click to explore{' '}
                    <span>
                      <img src={arrowRight} alt="Arrow right" />
                    </span>
                  </div>
                )}
              </div>
              <div
                onClick={() =>
                  navigate('/disbursment-and-contract-awarded/trend?preview=' + preview)
                }
                onMouseEnter={() => setActiveSection('disbursment')}
                className={
                  activeSection === 'disbursment'
                    ? styles.BoxItemActive
                    : styles.BoxItem
                }
                data-test="item-disbursment"
              >
                Contract Award and Disbursement
                {activeSection === 'disbursment' && (
                  <div className={styles.ClickToExplore}>
                    Click to explore{' '}
                    <span>
                      <img src={arrowRight} alt="Arrow right" />
                    </span>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate('/completed-products?preview=' + preview)}
                onMouseEnter={() => setActiveSection('completed')}
                className={
                  activeSection === 'completed'
                    ? styles.BoxItemActive
                    : styles.BoxItem
                }
                data-test="item-completed"
              >
                Completed products
                {activeSection === 'completed' && (
                  <div className={styles.ClickToExplore}>
                    Click to explore{' '}
                    <span>
                      <img src={arrowRight} alt="Arrow right" />
                    </span>
                  </div>
                )}
              </div>
              {/* <div
                onClick={() => navigate('/indicative-pipelines')}
                onMouseEnter={() => setActiveSection('indicative')}
                className={
                  activeSection === 'indicative'
                    ? styles.BoxItemActive
                    : styles.BoxItem
                }
                data-test="item-indicative"
              >
                Indicative Pipeline
                {activeSection === 'indicative' && (
                  <div className={styles.ClickToExplore}>
                    Click to explore{' '}
                    <span>
                      <img src={arrowRight} alt="Arrow right" />
                    </span>
                  </div>
                )}
              </div> */}
              <div
                onClick={() => navigate('/search?preview=' + preview)}
                onMouseEnter={() => setActiveSection('search')}
                className={
                  activeSection === 'search'
                    ? styles.BoxItemActive
                    : styles.BoxItem
                }
                data-test="item-search"
              >
                Search
                {activeSection === 'search' && (
                  <div className={styles.ClickToExplore}>
                    Click to explore{' '}
                    <span>
                      <img src={arrowRight} alt="Arrow right" />
                    </span>
                  </div>
                )}
              </div>
              <div
                className={styles.ButtonTutorial}
                onClick={() => actionsTutorial.open()}
                data-test="item-tutorial"
              >
                Explore the tutorial
              </div>
              <Link
                className={styles.LearnMore}
                to={'/about?preview=' + preview}
                data-test="item-about"
              >
                About FCAS and SIDS
              </Link>
            </div>
            <div className="col-md-3">
              <Cards activeSection={activeSection} />
            </div>
            <div className="col-md-3 d-flex align-items-center">
              {activeSection && (
                <div>
                  {activeSection === 'portfolio' && (
                    <div className={'BoxText'}>
                      Obtain information on current and past portfolio, compare
                      portfolio size across different years to identify trends,
                      drill down information by group, sector, country and other
                      distribution
                    </div>
                  )}
                  {activeSection === 'ppr' && (
                    <div className={'BoxText'}>
                      Learn how projects have performed including challenges
                      encountered through the years
                    </div>
                  )}
                  {activeSection === 'disbursment' && (
                    <div className={'BoxText'}>
                      See how contract award and disbursement relate to
                      committed amount
                    </div>
                  )}
                  {activeSection === 'completed' && (
                    <div className={'BoxText'}>
                      Learn about completed products across the timeline
                    </div>
                  )}
                  {activeSection === 'indicative' && (
                    <div className={'BoxText'}>
                      Discover the list of indicative projects in the pipeline
                    </div>
                  )}
                  {activeSection === 'search' && (
                    <div className={'BoxText'}>
                      Obtain additional information using advanced filter panel
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pt-5 bg-white pb-5 border-bottom text-center fs-16 fw-300">
          If you want more information about the tool, please contact:{' '}
          <span className="fw-350">
            <a className="no-link" href="mailto:glaurio.consultant@adb.org">
              glaurio.consultant@adb.org
            </a>
          </span>
        </div>
        <div className={styles.BoxAbout}>
          <div className="col-md-4">
            <div className="fs-12 fw-350">About ADB</div>
            <div className="fs-10 fw-300 mt-3">
              {' '}
              The Asian Development Bank (ADB) is committed to achieving
              <br /> a prosperous, inclusive, resilient, and sustainable Asia
              and the <br />
              Pacific, while sustaining its efforts to eradicate extreme
              poverty. <br /> It assists its members and partners by providing
              loans, technical <br /> assistance, grants, and equity investments
              to promote social <br /> and economic development.
            </div>
          </div>
          <div className="offset-md-2 col-md-4">
            <div>
              <div className="fs-12 fw-350">Headquarters</div>
              <div className="fs-10 fw-300 mt-3">
                6 ADB Avenue, Mandaluyong City 1550, Metro Manila, Philippines
                <br />
                <br /> +63 2 8632 4444
                <br /> +63 2 8636 2444
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tutorial
        openSection={tutorial.value}
        tutorial={tutorial}
        actionsTutorial={actionsTutorial}
      />
      {preview && <PreviewMode />}
    </div>
  )
}

export const OPTIONS_CLUSTER = [
  {
    value: '',
    label: 'Total',
  },
  {
    value: 'product_type',
    label: 'Modality',
    wordColor: 'modality',
  },
  {
    value: 'project__sector_code',
    label: 'Sector',
    wordColor: 'sectors',
  },
  {
    value: 'fund_mnemonic',
    label: 'Fund',
    wordColor: 'fund',
  },
]

export const OPTIONS_CLUSTER_DC = [
  {
    value: '',
    label: 'Total',
  },
  {
    value: 'product_type',
    label: 'Modality',
    wordColor: 'modality',
  },
]

export const COLOR_CLUSTER_WORD = {
  product_type: 'modality',
  project__sector_code: 'sectors',
  fund_mnemonic: 'fund',
}

export const OPTIONS_GROUP = [
  {
    value: 'fcas',
    label: 'FCAS',
  },
  {
    value: 'sids',
    label: 'SIDS',
  },
  {
    value: 'non-sovereign',
    label: 'NON SOVEREIGN',
  },
]

export const LABELS_AND_COLORS_GROUP = {
  fcas: {
    label: 'FCAS',
    order: 0,
    color1: 'var(--color-primary-solid)',
    color2: 'var(--color-primary-light)',
    gradient: 'primary',
  },
  sids: {
    label: 'SIDS',
    order: 1,
    color1: 'var(--color-primary-solid)',
    color2: 'var(--color-primary-light)',
    gradient: 'primary',
  },
  'non-sovereign': {
    label: 'Nonsovereign (FCAS & SIDS)',
    order: 2,
    color1: 'var(--color-basic-dark-grey)',
    color2: 'var(--color-basic-light-grey)',
    gradient: 'gray',
  },
  combo: {
    label: 'Sovereign (FCAS+SIDS)',
    order: 0,
    color1: 'var(--color-primary-solid)',
    color2: 'var(--color-primary-light)',
    gradient: 'primary',
  },
}

export const OPTIONS_GROUP_DC = [
  {
    value: 'fcas',
    label: 'FCAS',
  },
  {
    value: 'sids',
    label: 'SIDS',
  },
]

export const OPTIONS_GROUP_PPR = [
  {
    value: 'fcas',
    label: 'FCAS',
  },
  {
    value: 'sids',
    label: 'SIDS',
  },
]


export const COUNTRIES_PPR_NO_SHOW = ['AFG', 'MYA']

export const COUNTRIES_EXCEPTIONS_TREND = ['NIU','LAO','PAL']

export const SECTORS_ORDER = [
  'ANR',
  'EDU',
  'ENE',
  'FIN',
  'HLT',
  'ICT',
  'IND',
  'PSM',
  'TRA',
  'WUS',
  'Others',
]

export const MODALITY_ORDERS = [
  'Loan',
  'Grant',
  'Equity',
  'Guarantee',
  'TA',
  'Direct Investment',
  'Fund',
]

export const MODALITY_ORDERS_TOTALS_NONSOV = [
  'Loan',
  'Guarantee',
  'Equity',
  'Fund',
  'Direct Investment',
  'TA',
]

export const FUND_ORDERS = [
  'ADF',
  'COL',
  'OCR',
  'TASF',
  'Others',
]

export const MAP_XLSX_PAGES = [
  "Archive quarters",
  "UPDATED - Completed",
  "PPR - By Year",
  "UPDATE - PPR - Details",
  "NEW - Indicative Pipelines",
  "ADB Total Sovereign",
  "ADB Total Nonsovereign",
]
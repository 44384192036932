import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'

export default function PreviewMode() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 20,
        width: 'calc(25% - 40px)',
        left: 20,
        height: 'auto',
        backgroundColor: 'white',
        color: 'var(--color-primary-solid)',
        zIndex: 1000,
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
        border: '1px solid var(--color-primary-solid)',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.25)',
      }}
    >
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center">
          <div className="text-uppercase">PREVIEW MODE</div>
          <div>You are currently in preview mode.</div>
          <div>
            <Button
              style={{
                backgroundColor: 'var(--color-primary-solid)',
                borderColor: 'var(--color-primary-solid)',
              }}
              onClick={() => {
                navigate(pathname, { replace: true })
                window.location.reload()
              }}
              className="mt-3"
            >
              Exit Preview
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

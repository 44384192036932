// import * as Sentry from '@sentry/react'
import EazyAuth, { useAuthActions } from 'use-eazy-auth'
import { ConfigureRj } from 'react-rocketjump'
import api from './api'
import { of } from 'rxjs'

const SAML_URL_LOGOUT =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000/saml2/logout/'
    : '/saml2/logout/'

function loginCall(params) {
  if (params.ssoToken) {
    return of({ accessToken: params.ssoToken })
  } else {
    return api
      .mapResponse((r) => ({
        accessToken: r.response.access,
        refreshToken: r.response.refresh,
      }))
      .post('/api/token', params)
  }
}

const meCall = (token) => api.auth(token).get('/api/me')

// const refreshTokenCall = (refreshToken) =>
//   api
//     .mapResponse((r) => ({
//       accessToken: r.response.access,
//       refreshToken: refreshToken,
//     }))
//     .post('/api/or/dash/token/refresh', {
//       refresh: refreshToken,
//     })

function InnerAuth({ children }) {
  const { callAuthApiObservable } = useAuthActions()
  return (
    <ConfigureRj effectCaller={callAuthApiObservable}>{children}</ConfigureRj>
  )
}

function Auth({ children }) {
  return (
    <EazyAuth
      onAuthenticate={(user) => {
        if (process.env.REACT_APP_SENTRY_RELEASE) {
          // Sentry.setUser({
          //   id: user.id,
          //   email: user.email,
          // })
        }
      }}
      onLogout={() => {
        if (process.env.REACT_APP_SENTRY_RELEASE) {
          // Sentry.setUser(null)
        }
        window.location.href = SAML_URL_LOGOUT
      }}
      loginCall={loginCall}
      meCall={meCall}
      // refreshTokenCall={refreshTokenCall}
    >
      <InnerAuth>{children}</InnerAuth>
    </EazyAuth>
  )
}

export default Auth

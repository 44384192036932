import { Route, Routes } from 'react-router-dom'
import DCSnapshotGroup from './DCSnapshotGroup'
import DCSnapshotIndex from './DCSnapshotIndex'
import DCTrendGroup from './DCTrendGroup'
import DCTrendIndex from './DCTrendIndex'

export default function DisbursmentAndContract() {
  return (
    <Routes>
      <Route path="trend" element={<DCTrendIndex />} />
      <Route path="trend/:group" element={<DCTrendGroup />} />
      <Route path="snapshot" element={<DCSnapshotIndex />} />
      <Route path="snapshot/:group/" element={<DCSnapshotGroup />} />
    </Routes>
  )
}

import { Link } from 'react-router-dom'
import backIcon from './assets/back.svg'

/**
 * @param {{
 *  breadcrumb?: { to: string, label: string }[]
 * }} props
 */
export default function Breadcrumb({ breadcrumb }) {
  if (breadcrumb.length > 0) {
    return (
      <nav className="breadcrumb-portfolio" aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb-portfolio">
          <Link to={breadcrumb[breadcrumb.length - 2].to}>
            <span className="me-2">
              <img src={backIcon} alt="Back" />
            </span>
          </Link>
          {breadcrumb.map((b) => (
            <li
              key={b.to}
              className="breadcrumb-item active"
              aria-current="page"
            >
              <Link className="no-link" to={b.to}>
                {b.label}
              </Link>
            </li>
          ))}
        </ol>
      </nav>
    )
  }
  return <div />
}

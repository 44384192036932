import MenuLayout from './MenuLayout'

/**
 * @param {{
 *  children: React.ReactNode
 *  breadcrumbLabel: string
 *  subtitle: string
 *  filters: React.ReactNode
 * }} props
 */
export default function AutoAboutMenuLayout({
  children,
  breadcrumbLabel,
  ...props
}) {
  return (
    <MenuLayout {...props}>
      <div className="h-100 d-flex flex-column">
        <div className="flex-1 overflow-hidden">{children}</div>
      </div>
    </MenuLayout>
  )
}

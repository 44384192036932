import styles from './TrendSnapshotSwitch.module.css'

export default function TrendSnapshotSwitch({ value, onChange }) {
  return (
    <div className={styles.ContainerSwitch}>
      <div
        onClick={() => onChange('trend')}
        className={value === 'trend' ? styles.ActiveItem : styles.Item}
        data-test="switch-trend"
      >
        Trend
      </div>
      <div
        onClick={() => onChange('snapshot')}
        className={value === 'snapshot' ? styles.ActiveItem : styles.Item}
        data-test="switch-snapshot"
      >
        Snapshot
      </div>
    </div>
  )
}

import { rj, SUCCESS, useRunRj } from 'react-rocketjump'
import api from '../api'

const SearchState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[0]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'PorfolioInfoTrend',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/products', params),
  })

  export function useSearchProducts(params) {
    return useRunRj(SearchState, [params], false)
  }
import {
  difference,
  groupBy,
  maxBy,
  orderBy,
  range,
  uniq,
  uniqBy,
} from 'lodash'
import { PureComponent, useEffect, useMemo, useRef, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import {
  Area,
  AreaChart,
  ComposedChart,
  Dot,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import styles from './ProjectDetail.module.css'
import back from './assets/back.svg'
import { useRunRj } from 'react-rocketjump'
import { ProjectState } from '../../hooks/project'
import MenuLayout from '../MenuLayout'
import goHover from './assets/go-hover.svg'
import { useLastImportDate } from '../../LastImport'

function ResizeCallback({ width, callback }) {
  const callbackRef = useRef(callback)
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (typeof callbackRef.current === 'function') {
      callbackRef.current(width)
    }
  }, [width])
  return null
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload, yearSelected } = this.props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={-20}
          dy={16}
          dx={12}
          fontWeight={yearSelected === payload.value ? 400 : 300}
          fontSize={12}
          textAnchor="end"
          fill={
            yearSelected === payload.value
              ? 'var(--color-basic-dark-grey)'
              : 'var(--color-light-blue-grey)'
          }
        >
          {payload.value}
        </text>
      </g>
    )
  }
}

function differenceYears(data, years) {
  const missYears = difference(years, uniq(data.map((d) => d.year)))
  let filledData = data.concat(
    missYears.map((year) => ({
      year,
      year_disbursement: null,
      year_award: null,
      approval_no: data[0].approval_no,
      fund_mnemonic: data[0].fund_mnemonic,
      product_type: data[0].product_type,
    }))
  )

  return orderBy(filledData, 'year')
}

export default function ProjectDetail({
  isOpen,
  toggle,
  fromWhere = 'Search',
  project,
  onExited,
  year,
}) {
  const [{ data: projectData }] = useRunRj(ProjectState, [project])

  const colorStrong = 'var(--color-sectors-solid-psm)'
  const colorLight = 'var(--color-sectors-solid-ene)'

  const yearsRef = useRef()

  const [yearSelected, setYearSelected] = useState(null)
  const [positionsX, setPositionsX] = useState([])

  const lastImport = useLastImportDate()

  useEffect(() => {
    if (project && projectData) {
      if (year) {
        setYearSelected(parseInt(year))
      } else {
        setYearSelected(maxBy(projectData.global_trend, 'year').year)
      }
    }
  }, [projectData, project, year])

  const years = useMemo(() => {
    return project && projectData
      ? uniqBy(projectData.global_trend_by_type, 'year').map((d) => d.year)
      : []
  }, [project, projectData])

  const projectByApproval = useMemo(() => {
    if (project && projectData) {
      const grouped = groupBy(projectData.global_trend_by_type, 'approval_no')
      let data = []
      Object.keys(grouped).map((key) =>
        data.push(differenceYears(grouped[key], years))
      )
      return data
    }
  }, [project, projectData, years])

  const yearsPositions = useMemo(() => {
    if (project && projectData) {
      return uniqBy(projectData.global_trend, 'year').map((d) => d.year)
    }
  }, [projectData, project])

  return (
    projectData && (
      <Modal
        toggle={toggle}
        onClosed={onExited}
        fullscreen
        className={styles.Modal}
        isOpen={isOpen}
      >
        <ModalHeader className={styles.ModalHeader}>
          <MenuLayout
            subtitle={''}
            is100={false}
            classLeft={false}
            className={'ps-2'}
            title=""
          />
          <div className="ms-2">
            <div
              className={styles.Back}
              onClick={() => {
                setPositionsX([])
                setYearSelected(null)
                toggle()
              }}
            >
              <img src={back} alt="Back" />
              <span className={styles.BackLabel}>{fromWhere}</span>
            </div>
            <div>
              <div className={styles.Title}>
                {projectData.project.project_title}
              </div>
              <div>
                <span className={styles.ProjectCode}>
                  ({projectData.project.project_code})
                </span>{' '}
                <span className={styles.SectorLabel}>Sector:</span>{' '}
                <span className={styles.SectorValue}>
                  {projectData.project.sector}
                </span>
                <span className={styles.SectorLabel}>Country:</span>{' '}
                <span className={styles.SectorValue}>
                  {projectData.project.country_name}
                </span>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className={styles.ModalContent}>
          {yearSelected && (
            <div className="position-relative" data-test="page-projects-detail">
              {positionsX.length > 1 && (
                <div
                  className="position-absolute"
                  style={{
                    top: 30,
                    zIndex: 1,
                    bottom: 0,
                    left: positionsX[yearsPositions.indexOf(yearSelected)] + 20,
                    width: 40,
                    background:
                      'linear-gradient(270deg, #EEF1FA 0%, rgba(238, 241, 250, 0) 30.21%, rgba(238, 241, 250, 0) 70.83%, #EEF1FA 98.96%)',
                    border: '3px solid #E2EDF6',
                    borderRadius: '10px 10px 0px 0px',
                  }}
                ></div>
              )}
              <div className="row ps-3 pe-3 ms-3 me-3">
                <div className="col-md-8" ref={yearsRef}>
                  <ResponsiveContainer width={'100%'} height={0}>
                    <ResizeCallback
                      callback={(width) => {
                        const numYears = yearsPositions.length
                        const chartWidth = width - 120 - 30
                        const pos = range(numYears).map(
                          (i) => (chartWidth / (numYears - 1)) * i + 120
                        )
                        setPositionsX(pos)
                      }}
                    />
                  </ResponsiveContainer>
                  <ResponsiveContainer width={'100%'} height={30}>
                    <ComposedChart
                      margin={{ left: 60, right: 30 }}
                      className="top-years-lines"
                      data={projectData.global_trend}
                    >
                      <YAxis axisLine={false} tickLine={false} />
                      <XAxis
                        tickSize={13}
                        fontSize={12}
                        stroke="var(--color-light-blue-grey)"
                        axisLine={false}
                        tickLine={true}
                        xAxisId={'years'}
                        tick={
                          <CustomizedAxisTick yearSelected={yearSelected} />
                        }
                        orientation="top"
                        dataKey={(x) =>
                          x.year
                            .toString()
                            .replace(
                              lastImport.last_year,
                              `${lastImport.last_year} ${lastImport.last_quarter}`
                            )
                        }
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div
                style={{ height: 180 }}
                className="row ps-3 pb-4 pe-3 asian-card-without-cursor-detail ms-3 me-3"
              >
                <div className="col-md-8 position-relative">
                  <div className={styles.GoHover}>
                    <img className="me-1" src={goHover} alt="Go hover" />
                    Hover the mouse over the years to discover more
                  </div>
                  <div
                    className="position-absolute fs-12 fw-400 text-uppercase text-color-basic-dark-grey"
                    style={{ left: 0, top: 20 }}
                  >
                    PROJECT ($ million)
                  </div>
                  <ResponsiveContainer
                    className={'mt-3'}
                    width="100%"
                    height={140}
                  >
                    <AreaChart
                      margin={{ left: 60, top: 10, right: 30 }}
                      height={140}
                      data={projectData.global_trend}
                      onMouseMove={(e) => {
                        if (e && e.activeLabel) {
                          setYearSelected(e.activeLabel)
                        }
                      }}
                    >
                      <YAxis
                        fontSize={12}
                        stroke="var(--grey-3)"
                        fontWeight={300}
                        tickCount={2}
                        ticks={0}
                        tickFormatter={() => ''}
                        axisLine={false}
                        tickLine={false}
                      ></YAxis>
                      <XAxis tick={false} axisLine={false} dataKey="year" />
                      <Area
                        type="monotone"
                        dataKey="year_award"
                        stroke={colorStrong}
                        fill={colorStrong}
                        // label={<CustomizedLabel strokeColor={colorStrong} />}
                        dot={(props) => {
                          return (
                            <Dot {...props} className="recharts-area-dot" />
                          )
                        }}
                        fillOpacity={0.2}
                        strokeWidth={3}
                      />
                      <Area
                        type="monotone"
                        dataKey="year_disbursement"
                        stroke={colorLight}
                        fill={colorLight}
                        // label={<CustomizedLabel strokeColor={colorLight} />}
                        dot={(props) => {
                          return (
                            <Dot {...props} className="recharts-area-dot" />
                          )
                        }}
                        fillOpacity={0.2}
                        strokeWidth={3}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className="col-md-4 ps-3">
                  <div
                    className="asian-card-without-cursor mt-4"
                    style={{ height: 140 }}
                  >
                    <div className="row p-3">
                      <div className="col-md-6">
                        <div className="fs-10 fw-300 text-start">
                          Net amount
                        </div>
                        <div className="fw-350 fs-16">
                          {projectData.global_trend.filter(
                            (d) => d.year === yearSelected
                          )[0] &&
                          projectData.global_trend.filter(
                            (d) => d.year === yearSelected
                          )[0].year_amount
                            ? projectData.global_trend.filter(
                                (d) => d.year === yearSelected
                              )[0].year_amount
                              ? projectData.global_trend
                                  .filter((d) => d.year === yearSelected)[0]
                                  .year_amount.toFixed(2)
                              : ''
                            : ''}
                          <span className="fw-300 fs-12 ms-1">$ million</span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="fs-10 fw-300">Status</div>
                        <div className="fw-350 fs-16">
                          {projectData.project.update_status || 'Ongoing'}
                        </div>
                      </div>
                    </div>
                    <div className="row  p-3">
                      <div className="col-md-6">
                        <div className="fs-10 fw-300">
                          Cumulative disbursement
                        </div>
                        <div className="fw-350 mt-1 fs-16 d-flex align-items-center">
                          <div className={'CircleSectorDisbursment'}></div>
                          <div className="ms-2">
                            {projectData.global_trend.filter(
                              (d) => d.year === yearSelected
                            )[0]
                              ? projectData.global_trend.filter(
                                  (d) => d.year === yearSelected
                                )[0].year_disbursement
                                ? projectData.global_trend
                                    .filter((d) => d.year === yearSelected)[0]
                                    .year_disbursement.toFixed(2)
                                : ''
                              : ''}
                            <span className="fw-300 fs-12 ms-1">$ million</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="fs-10 fw-300">
                          Cumulative contract award
                        </div>
                        <div className="fw-350 mt-1 fs-16 d-flex align-items-center">
                          <div className={'CircleSectorAward'}></div>
                          <div className="ms-2">
                            {projectData.global_trend.filter(
                              (d) => d.year === yearSelected
                            ).length > 0
                              ? projectData.global_trend.filter(
                                  (d) => d.year === yearSelected
                                )[0].year_award
                                ? projectData.global_trend
                                    .filter((d) => d.year === yearSelected)[0]
                                    .year_award.toFixed(2)
                                : ''
                              : ''}
                            <span className="fw-300 fs-12 ms-1">$ million</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 h-100 overflow-y-scroll ps-3 pb-4 pe-3 asian-card-without-cursor-detail ms-3 me-3">
                <div className="col-md-8 position-relative">
                  <div
                    style={{ left: 0 }}
                    className="position-absolute text-uppercase mb-2 pt-2 fs-12 fw-375 text-color-basic-dark-grey"
                  >
                    products ($ million)
                  </div>
                  {Object.keys(projectByApproval).map((approval) => (
                    <ResponsiveContainer
                      key={approval}
                      className={'mt-5'}
                      width="100%"
                      height={80}
                    >
                      <AreaChart
                        margin={{ left: 60, top: 10, right: 30 }}
                        height={90}
                        data={projectByApproval[approval]}
                        onMouseMove={(e) => {
                          if (e && e.activeLabel) {
                            setYearSelected(e.activeLabel)
                          }
                        }}
                      >
                        {/* <CartesianGrid
                          horizontal={false}
                          strokeDasharray="3 3"
                        /> */}
                        <XAxis tick={false} axisLine={false} dataKey="year" />
                        <YAxis
                          fontSize={12}
                          stroke="var(--grey-3)"
                          fontWeight={300}
                          tickCount={2}
                          tickFormatter={() => ''}
                          axisLine={false}
                          tickLine={false}
                        >
                          <Label
                            fill="var(--color-basic-black)"
                            fontWeight={375}
                            fontSize={14}
                            value={projectByApproval[approval][0].product_type}
                            strokeWidth={0.5}
                            dy={-20}
                            dx={-35}
                            // position="center"
                          />
                          <Label
                            fill="var(--color-basic-dark-grey)"
                            fontWeight={300}
                            fontSize={12}
                            value={`${
                              projectByApproval[approval][0].fund_mnemonic ===
                              'Others'
                                ? 'Others / Cofinancing'
                                : projectByApproval[approval][0].fund_mnemonic
                            }`}
                            strokeWidth={0.5}
                            dy={0}
                            dx={-35}
                            // position="center"
                          />
                          <Label
                            fill="rgba(13, 142, 202, 0.6)"
                            fontWeight={400}
                            fontSize={12}
                            value={`(No. ${projectByApproval[
                              approval
                            ][0].approval_no.replace('.0', '')})`}
                            strokeOpacity={0.6}
                            dx={-28}
                            dy={20}
                            // position="center"
                          />
                        </YAxis>
                        <Area
                          type="monotone"
                          dataKey="year_award"
                          stroke={colorStrong}
                          fill={colorStrong}
                          // label={<CustomizedLabel strokeColor={colorStrong} />}
                          dot={(props) => {
                            return (
                              <Dot {...props} className="recharts-area-dot" />
                            )
                          }}
                          fillOpacity={0.2}
                          //   dot={(props) => {
                          //     if (showDotsYears.includes(props.payload.year)) {
                          //       return <Dot {...props} className="recharts-area-dot" />
                          //     }
                          //     return null
                          //   }}
                          strokeWidth={3}
                        />
                        <Area
                          type="monotone"
                          dataKey="year_disbursement"
                          stroke={colorLight}
                          fill={colorLight}
                          // label={<CustomizedLabel strokeColor={colorLight} />}
                          dot={(props) => {
                            return (
                              <Dot {...props} className="recharts-area-dot" />
                            )
                          }}
                          fillOpacity={0.2}
                          strokeWidth={3}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  ))}
                </div>
                <div className="col-md-4 ps-3">
                  {Object.keys(projectByApproval).map((type) => (
                    <div
                      className="asian-card-without-cursor mt-5"
                      key={type}
                      style={{ height: 70 }}
                    >
                      <div className="row p-4">
                        <div className="col-md-6">
                          <div className="fw-350 mt-1 fs-16 d-flex align-items-center">
                            <div className={'CircleSectorDisbursment'}></div>
                            <div className="ms-2">
                              {projectByApproval[type].filter(
                                (d) => d.year === yearSelected
                              ).length > 0
                                ? projectByApproval[type].filter(
                                    (d) => d.year === yearSelected
                                  )[0].year_disbursement
                                  ? projectByApproval[type]
                                      .filter((d) => d.year === yearSelected)[0]
                                      .year_disbursement.toFixed(2)
                                  : ''
                                : ''}
                              <span className="fw-300 fs-12 ms-1">
                                $ million
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="fw-350 mt-1 fs-16 d-flex align-items-center">
                            <div className={'CircleSectorAward'}></div>
                            <div className="ms-2">
                              {projectByApproval[type].filter(
                                (d) => d.year === yearSelected
                              )[0]
                                ? projectByApproval[type].filter(
                                    (d) => d.year === yearSelected
                                  )[0].year_award
                                  ? projectByApproval[type]
                                      .filter((d) => d.year === yearSelected)[0]
                                      .year_award.toFixed(2)
                                  : ''
                                : ''}
                              <span className="fw-300 fs-12 ms-1">
                                $ million
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    )
  )
}

import { createElement, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router'
import { useAuthState, useAuthUser } from 'use-eazy-auth'

export function RequireAuth({
  children,
  redirectTest,
  redirectTo = '/login',
  spinner,
  spinnerComponent,
  rememberReferrer = true,
}) {
  const { authenticated, bootstrappedAuth, loginLoading } = useAuthState()
  const { user } = useAuthUser()
  const location = useLocation()

  const userRedirectTo = useMemo(() => {
    if (user && typeof redirectTest === 'function') {
      const userRedirectTo = redirectTest(user)
      if (userRedirectTo) {
        return userRedirectTo
      }
    }
    return null
  }, [user, redirectTest])

  if (!bootstrappedAuth || loginLoading) {
    // Spinner or Spinner Component
    return spinnerComponent ? createElement(spinnerComponent) : spinner ?? null
  }

  if (authenticated) {
    // Redirect a logged user?
    if (userRedirectTo) {
      return <Navigate to={userRedirectTo} />
    }
    return children
  }

  return (
    <Navigate
      to={redirectTo}
      state={
        rememberReferrer
          ? {
              referrer: location,
            }
          : undefined
      }
    />
  )
}

export function RequireGuest({
  children,
  spinner,
  spinnerComponent,
  redirectTo = '/',
  redirectToReferrer = true,
}) {
  const { authenticated, bootstrappedAuth } = useAuthState()
  const location = useLocation()

  if (authenticated) {
    // Redirect to referrer location
    if (redirectToReferrer && location.state && location.state.referrer) {
      const { referrer } = location.state
      const toReferrer = {
        pathname: referrer.pathname,
        search: referrer.search,
        hash: referrer.hash,
      }
      return <Navigate to={toReferrer} />
    }
    return <Navigate to={redirectTo} />
  }

  if (!bootstrappedAuth) {
    // Spinner as element as component or null
    return spinnerComponent ? createElement(spinnerComponent) : spinner ?? null
  }

  return children
}

import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const LastImportState = rj().effect({
  effectCaller: 'configured',
  effect: (t) => (filters) =>
    api.auth(t).get('/api/last-import', filters),

})

export function useLastImportApi(filters) {
  return useRunRj(LastImportState, [filters], false)
}

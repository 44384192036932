import { rj, SUCCESS } from 'react-rocketjump'
import api from '../api'

export const ProjectState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[0]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'ProjectState',
    effectCaller: 'configured',
    effect:
      (t) =>
      (id, params = {}) =>
        api.auth(t).get(`/api/projects/${id}/project_detail`, params),
  })

//   export function useProject(id, params) {
//     return useRj(ProjectState, [id, params], false)
//   }
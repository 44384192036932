import { find } from 'lodash'
import { useMatch, useResolvedPath, useSearchParams } from 'react-router-dom'
import { useDomain } from '../Domain'
import Breadcrumb from './Breadcrumb/Breadcrumb'

export default function AutoTSBreadcrumb({ label }) {
  const domain = useDomain()
  const prefix = useResolvedPath('..')?.pathname
  const match = useMatch(`${prefix}/:type/*`)
  const type = match.params.type
  const [group, country] = match.params['*'].split('/')

  const [searchParams] = useSearchParams()
  let breadcrumb = []
  if (group) {
    breadcrumb.push({
      label,
      to: `${prefix}/${type}?${searchParams.toString()}`,
    })
    breadcrumb.push({
      label: group.toUpperCase(),
      to: `${prefix}/${type}/${group}?${searchParams.toString()}`,
    })
    if (country) {
      const countryName = find(domain.countries_data, { code: country })?.name
      breadcrumb.push({
        label: countryName,
        to: `${prefix}/${type}/${group}/${country}?${searchParams.toString()}`,
      })
    }
  }

  return <Breadcrumb breadcrumb={breadcrumb} />
}

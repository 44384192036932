import { Link, useSearchParams } from 'react-router-dom'
import BarChartStackedMulti from '../../components/BarChartStackedMulti'
import Spinner from '../../components/Spinner'
import { useDomain } from '../../Domain'
import { usePPR } from '../../hooks/ppr'
import LayoutPPR from './LayoutPPR'
import { useMemo } from 'react'
import PreviewMode from '../../components/PreviewMode'

export default function PPRTrendIndex() {
  const [searchParams] = useSearchParams()
  const preview = searchParams.get('preview') ?? ''

  const filtersPreview = useMemo(() => {
    return {
      preview,
    }
  }, [preview])
  const [{ data, loading }] = usePPR(filtersPreview)
  const domain = useDomain()

  return (
    <LayoutPPR dataTest="page-ppr-trend">
      <div className="h-100 w-100 d-flex flex-column ps-5 pe-5 pb-5">
        <div className="mb-1 percentage-info">
          Percentages may not add up due to rounding.
        </div>
        {!data && loading && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <Spinner />
          </div>
        )}
        {data &&
          data.map((group, i) => (
            <Link
              to={group.group+`?preview=${preview}`}
              className="h-100 w-100 no-link"
              key={group.group}
            >
              <BarChartStackedMulti
                years={domain.years}
                title={group.group.toUpperCase()}
                showYearsAxis={i === 0}
                showYearsTicks={true}
                syncId={'asian'}
                cardBackground={true}
                data={group.years}
              />
            </Link>
          ))}
      </div>
      {preview && <PreviewMode />}
      {/* <AutoTipModal type={'ppr-trend'} imageSource={imageSource} /> */}
    </LayoutPPR>
  )
}

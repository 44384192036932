import { rj, useRunRj } from 'react-rocketjump'
import api from '../api'

const DomainState = rj().effect({
  effectCaller: 'configured',
  effect: (t) => (params) =>
    api.auth(t).get('/api/products/domain', params),

})

export function useDomainApi(params) {
  console.log('useDomainApi', params)
  return useRunRj(DomainState, [params])
}

import { createContext, useContext, useMemo } from 'react'
import { useAuthState } from 'use-eazy-auth'
import { useDomainApi } from './hooks/domain'

const DomainContext = createContext(null)

/**
 * @returns {{
 *   countries: string[]
 *   countries_data: { name: string, code: string }[]
 *   funds_mnemonic: string[]
 *   product_types: string[]
 *   sectors: string[]
 *   years: number[]
 * }}
 */
export function useDomain(preview = false) {
  const [state] = useContext(DomainContext, preview)
  return state.data
}

export function DomainProvider({ children, preview = false }) {
  const filtersForDomain = useMemo(() => {
    if (preview) {
      return {
        preview
      }
    }
    return {}
  }, [preview])

  const stateNActions = useDomainApi(filtersForDomain)
  const [state] = stateNActions
  if (state.data === null) {
    // TODO: Spinner Boy
    return null
  }
  return (
    <DomainContext.Provider value={stateNActions}>
      {children}
    </DomainContext.Provider>
  )
}

export default function Domain({ children, preview = false }) {
  const { authenticated } = useAuthState()
  if (authenticated) {
    return <DomainProvider preview={preview}>{children}</DomainProvider>
  }
  return children
}

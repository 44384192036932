import AutoAboutMenuLayout from '../../components/AutoAboutMenuLayout'

/**
 * @param {{
 *  children: React.ReactNode
 *  filters: React.ReactNode
 * }} props
 */
export default function LayoutAbout({ children, ...props }) {
  return (
    <AutoAboutMenuLayout noDate title="" subtitle="" breadcrumbLabel="" {...props}>
      {children}
    </AutoAboutMenuLayout>
  )
}

import styles from './About.module.css'

export default function DataSourceAndUpdates() {
  return (
    <div className="row pt-5 mb-5" data-test="text-data-source">
      <div className="offset-md-1 col-md-10">
        <h2 className={styles.Title}>Data Source and Update</h2>
        <div className={styles.BlockContent}>
          <div className={styles.Paragraph}>
            Portfolio information and project performance ratings of sovereign
            operations in FCAS and SIDS are based on data provided by the
            Portfolio Management Division (PFPM-PPFD). Nonsovereign portfolio
            information is provided by the Private Sector Operations Department
            (PSOD).
          </div>
          <div className={styles.Paragraph}>
            Information provided in DataViz is as of year-end unless indicated.
            Current year information is updated on a quarterly basis.
          </div>
          <div className={styles.Paragraph}>
            Note:{' '}
            <ul className='mt-2'>
              <li>
                ADB placed its development assistance in Afghanistan on hold
                effective from 15 August 2021, but the bank remains committed to
                supporting the economic and social development of the Afghan
                people.
              </li>
              <li>
                ADB placed a temporary hold on sovereign project disbursements
                and new contracts in Myanmar effective 1 February 2021. ADB
                continues to monitor the situation in the country.
              </li>
              <li>
                Palau was classified as FCAS during the last quarter of 2022 and
                included in FCAS monitoring beginning 2023.
              </li>
              <li>
                Lao PDR joined the list of FCAS DMCs in the last quarter of 2020
                and included in FCAS monitoring beginning 2021.
              </li>
              <li>Niue became a member of ADB in March 2019.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

import * as d3 from 'd3'
import { keyBy } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { useDomain } from '../../Domain'
import { useLastImportDate } from '../../LastImport'

const HEIGHT = 15

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
})

export default function CountryBeeSwarmTicks({ extent, indicativePipelines }) {
  const refBeeswarm = useRef()
  const redrawRef = useRef()

  const domain = useDomain()

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  useEffect(() => {
    const handler = () => {
      redrawRef.current?.()
    }
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  const customTicks = useMemo(() => {
    const customTicks = []
    let iterDate = new Date(extent[0])
    iterDate.setMonth(6)
    while (iterDate < extent[1]) {
      customTicks.push(new Date(iterDate))
      iterDate.setFullYear(iterDate.getFullYear() + 1)
    }
    return customTicks
  }, [extent])

  const lastImport = useLastImportDate()

  useEffect(() => {
    let height = HEIGHT
    let margin = {
      top: 0,
      right: 40,
      bottom: 34,
      left: 80,
    }

    // Data structure describing chart scales
    let Scales = {
      time: 'scaleTime',
    }

    // Data structure describing volume of displayed data
    let Count = {
      year: 'closing_date',
    }

    // Data structure describing legend fields value
    let Legend = {
      amount: 'amount',
    }

    let chartState = {}

    chartState.year = Count.year
    chartState.scale = Scales.time
    chartState.legend = Legend.amount

    let svg = d3.select(refBeeswarm.current).attr('height', height)

    svg.append('g').attr('class', 'x axis').attr('transform', 'translate(0,0)')

    function redraw() {
      const width = window.innerWidth - 20

      svg.attr('width', width)

      // Set scale type based on button clicked
      let xScale = d3.scaleTime().range([margin.left, width - margin.right])

      xScale.domain(extent)

      const tickNodes = svg
        .selectAll('.ticks')
        .data(customTicks, (d) => d.getFullYear().toString())

      tickNodes.exit().remove()

      tickNodes
        .enter()
        .append('text')
        .classed('ticks', true)
        .classed('text-style-kpi-s-light', true)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .attr('dominant-baseline', 'hanging')
        .text((node) =>
          node.getFullYear() === lastImport.last_year && !indicativePipelines
            ? `${node.getFullYear()} ${lastImport.last_quarter}`
            : node.getFullYear()
        )
        .merge(tickNodes)
        .attr('x', (node) => xScale(node))
        .attr('y', 0)

      // Create simulation with specified dataset
    }
    redrawRef.current = redraw

    redraw()
  }, [countriesByCode, customTicks, extent, indicativePipelines, lastImport.last_quarter, lastImport.last_year])

  return (
    <svg
      ref={refBeeswarm}
      style={{
        position: 'sticky',
        top: 0,
        background: 'var(--color-basic-lightblue-1)',
      }}
    ></svg>
  )
}

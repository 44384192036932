import dayjs from 'dayjs'
import { chain, keyBy, orderBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { Fragment, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
// import AutoTipModal from '../../components/AutoTipModal'
import MenuLayout from '../../components/MenuLayout'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import { SECTORS_ORDER } from '../../consts'
import { useDomain } from '../../Domain'
import { useCompleted } from '../../hooks/completed'
import { useSectorsCompletedOptions } from '../../hooks/options'
import mapOrder from '../../utils'
import { LimitedChipsContainer } from '../Search/Search'
import completedIcon from './assets/completed.svg'
import netAmountIcon from './assets/net-amount.svg'
import styles from './CompletedProducts.module.css'
import CountryBeeSwarm from './CountryBeeSwarm'
import CountryBeeSwarmTicks from './CountryBeeSwarmTicks'
import PreviewMode from '../../components/PreviewMode'

const ALL_DEFAULT_VALUES = ['']

export default function CompletedProducts() {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = {
    group: searchParams.get('group') ?? '',
    country: searchParams.getAll('country'),
    sector_code: searchParams.getAll('sector_code'),
    preview: searchParams.get('preview') ?? '',
  }
  const apiParams = useMemoCompare({
    preview: params.preview,
  })

  const uiCountries =
    params.country.length > 0 ? params.country : ALL_DEFAULT_VALUES

  const uiSectors =
    params.sector_code.length > 0 ? params.sector_code : ALL_DEFAULT_VALUES

  const [{ data: _data, dataParams, loading }] = useCompleted(apiParams)

  const data = useMemo(() => {
    if (!_data) {
      return _data
    }
    return _data.map((countryData) => ({
      ...countryData,
      products: countryData.products.map((p) => ({ ...p, visible: true })),
    }))
  }, [_data])

  const yearsDomain = useMemo(() => {
    const allYears = chain(data).flatMap('products').map('year')
    const min = allYears.min().value()
    const max = allYears.max().value()
    return [dayjs(`${min}-01-01`).toDate(), dayjs(`${max}-12-31`).toDate()]
  }, [data])

  const amountsDomain = useMemo(() => {
    const allAmounts = chain(data).flatMap('products').map('amount')
    const min = allAmounts.min().value()
    const max = allAmounts.max().value()
    return [min, max]
  }, [data])

  const filteredDataForSelects = useMemo(() => {
    let result = data
    if (!result) {
      return result
    }
    // filter by group all='' || sids='sids' || fcas='fcas'
    result = result.map((countryData) => ({
      ...countryData,
      products: countryData.products.filter(
        (product) =>
          params.group === '' ||
          (params.group === 'fcas' && product.fcas) ||
          (params.group === 'sids' && product.sids)
      ),
    }))
    // cleanup
    result = result.filter((countryData) => countryData.products.length > 0)
    return result
  }, [data, params.group])

  const filteredData = useMemo(() => {
    let result = data
    if (!result) {
      return result
    }
    // filter by country
    result = result.filter(
      (item) =>
        params.country.length === 0 || params.country.includes(item.country)
    )
    // filter by group all='' || sids='sids' || fcas='fcas'
    result = result.map((countryData) => ({
      ...countryData,
      products: countryData.products.map((product) => {
        const test =
          params.group === '' ||
          (params.group === 'fcas' && product.fcas) ||
          (params.group === 'sids' && product.sids)
        return { ...product, visible: product.visible && test }
      }),
    }))
    // filter by sector
    result = result.map((countryData) => ({
      ...countryData,
      products: countryData.products.map((product) => {
        const test =
          params.sector_code.length === 0 ||
          params.sector_code.includes(product.project_data.sector_code)
        return { ...product, visible: product.visible && test }
      }),
    }))
    return result
  }, [data, params.country, params.group, params.sector_code])

  const sectorOptions = mapOrder(
    useSectorsCompletedOptions(),
    SECTORS_ORDER,
    'label'
  )

  const dataGrouped = useMemo(() => {
    if (filteredData) {
      let groupedData = keyBy(filteredData, 'country')
      for (let k in groupedData) {
        if (groupedData[k].products.every((p) => !p.visible)) {
          delete groupedData[k]
        }
      }
      return groupedData
    }
    return []
  }, [filteredData])

  const domain = useDomain()

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!filteredDataForSelects || filteredDataForSelects.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(filteredDataForSelects, 'country').map(
      (c) => ({
        value: c.country,
        label: countriesByCode[c.country].name,
      })
    )
    return orderBy(distinctCountries, 'label')
  }, [countriesByCode, filteredDataForSelects])

  return (
    <div
      className="h-100 overflow-hidden position-relative mb-0 pb-0"
      style={{ backgroundColor: 'var(--color-basic-lightblue-1)' }}
      data-test="page-completed"
    >
      <div className="d-flex justify-content-between">
        <MenuLayout
          subtitle={'Completed products'}
          classLeft={false}
          className={'ps-4 pt-3'}
          info="completed"
          title=""
        />
        <div className="mt-4 d-flex justify-content-end w-100 me-3">
          <div className="w-100">
            {dataParams && data && (
              <div className="d-flex justify-content-end">
                <div className={styles.ContainerSwitch}>
                  <div
                    onClick={() => {
                      setSearchParams({
                        ...params,
                        group: '',
                      })
                    }}
                    className={
                      params.group === '' ? styles.ActiveItem : styles.Item
                    }
                    data-test="switch-all"
                  >
                    All
                  </div>
                  <div
                    onClick={() => {
                      setSearchParams({
                        ...params,
                        group: 'fcas',
                      })
                    }}
                    className={
                      params.group === 'fcas' ? styles.ActiveItem : styles.Item
                    }
                    data-test="switch-fcas"
                  >
                    FCAS
                  </div>
                  <div
                    onClick={() => {
                      setSearchParams({
                        ...params,
                        group: 'sids',
                      })
                    }}
                    className={
                      params.group === 'sids' ? styles.ActiveItem : styles.Item
                    }
                    data-test="switch-sids"
                  >
                    SIDS
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-end mt-5">
              <Select
                onChange={(e) => {
                  if (
                    params.country.length !== 0 &&
                    e.filter((d) => d.value === '').length > 0
                  ) {
                    setSearchParams({
                      ...params,
                      country: [],
                    })
                  } else {
                    setSearchParams({
                      ...params,
                      country: e.map((c) => c.value).filter(Boolean),
                    })
                  }
                }}
                classSelect={'bg-transparent'}
                closeMenuOnSelect={false}
                id="Countries"
                placeholder={'All countries'}
                components={
                  params &&
                  data &&
                  params.country &&
                  params.country.length > 0 && {
                    MultiValue: LimitedChipsContainer,
                  }
                }
                isMulti
                value={uiCountries}
                isDisabled={!data}
                options={[{ value: '', label: 'All countries' }].concat(
                  countriesToUse
                )}
              />
              <Select
                className="ms-3"
                style={{ width: 400, zIndex: 1000 }}
                onChange={(e) => {
                  if (
                    params.sector_code.length !== 0 &&
                    e.filter((d) => d.value === '').length > 0
                  ) {
                    setSearchParams({
                      ...params,
                      sector_code: [],
                    })
                  } else {
                    setSearchParams({
                      ...params,
                      sector_code: e.map((c) => c.value).filter(Boolean),
                    })
                  }
                }}
                components={
                  params &&
                  params.sector_code &&
                  params.sector_code.length > 0 && {
                    MultiValue: LimitedChipsContainer,
                  }
                }
                isMulti
                closeMenuOnSelect={false}
                id="Sectors"
                placeholder="All sectors"
                isDisabled={!data}
                value={uiSectors}
                options={[{ value: '', label: 'All sectors' }].concat(
                  sectorOptions
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="overflow-y-scroll"
        style={{ maxHeight: 'calc(100vh - 220px)' }}
      >
        {!data && loading && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
            <Spinner />
          </div>
        )}
        {data && (
          <>
            <CountryBeeSwarmTicks extent={yearsDomain} />
            {orderBy(Object.keys(dataGrouped)).map((country, i) => (
              <Fragment key={country}>
                <CountryBeeSwarm
                  displayAxis={false}
                  key={country}
                  country={country}
                  group={dataParams.group}
                  data={dataGrouped[country].products}
                  extent={yearsDomain}
                  radiusExtent={amountsDomain}
                />
              </Fragment>
            ))}
          </>
        )}
      </div>
      <div className={styles.LegendCompleted}>
        <div className="d-flex align-items-center">
          <img className="me-1" src={netAmountIcon} alt="Net Amount" />
          NET AMOUNT
        </div>
        <div className="ms-4 d-flex align-items-center">
          <img className="me-1" src={completedIcon} alt="Completed" />
          COMPLETED
        </div>
        {/* <div className="ms-4 d-flex align-items-center">
          <img className="me-1" src={cancelledIcon} alt="Cancelled" />
          CANCELLED
        </div> */}
      </div>
      {/* <AutoTipModal type={'completed'} imageSource={imageSource} /> */}
      {apiParams.preview && (
        <PreviewMode />
      )}
    </div>
  )
}

import { Route, Routes } from 'react-router-dom'
import PPRSnapshotGroup from './PPRSnapshotGroup'
import PPRSnapshotGroupCountry from './PPRSnapshotGroupCountry'
import PPRSnapshotIndex from './PPRSnapshotIndex'
import PPRTrendGroup from './PPRTrendGroup'
import PPRTrendGroupCountry from './PPRTrendGroupCountry'
import PPRTrendIndex from './PPRTrendIndex'

export default function ProjectPerfomanceRating() {
  return (
    <Routes>
      <Route path="trend" element={<PPRTrendIndex />} />
      <Route path="trend/:group" element={<PPRTrendGroup />} />
      <Route path="trend/:group/:country" element={<PPRTrendGroupCountry />} />
      <Route path="snapshot" element={<PPRSnapshotIndex />} />
      <Route path="snapshot/:group/" element={<PPRSnapshotGroup />} />
      <Route
        path="snapshot/:group/:country"
        element={<PPRSnapshotGroupCountry />}
      />
    </Routes>
  )
}

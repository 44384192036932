import { keyBy, orderBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { Fragment, useMemo } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import BarChartStacked from '../../components/BarChartStacked'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import { COUNTRIES_PPR_NO_SHOW } from '../../consts'
import { useDomain } from '../../Domain'
import { useYearsOptions } from '../../hooks/options'
import { usePPRSnapshot, usePPRSnapshotAndIssues } from '../../hooks/ppr'
import LayoutPPR from './LayoutPPR'
import PreviewMode from '../../components/PreviewMode'

const RHOMBUS_L = 10
const BUTTON_H = 21
const BUTTON_W = 45

function SvgPill({ value, fill }) {
  return (
    <g>
      <rect
        rx={11}
        fill={fill}
        x={0}
        y={0}
        width={BUTTON_W}
        height={BUTTON_H}
      />
      <text fill="white" textAnchor="middle" x={BUTTON_W / 2} y={BUTTON_H - 6}>
        {value}
      </text>
    </g>
  )
}

function Rhombus({ color, x, y, l = RHOMBUS_L }) {
  return (
    <g
      style={{
        transform: `translate(${x}, ${y - l / 2}px)`,
      }}
    >
      <rect
        style={{ transform: 'rotate(45deg)' }}
        width={l}
        height={l}
        fill={color}
      />
    </g>
  )
}

export function BlockIssueType({ label, atRisk, forAttention }) {
  return (
    <div className="row ps-4 mt-4 align-items-center" style={{ minHeight: 65 }}>
      <div className="col-md-4 fw-300">{label}</div>
      <div className="col-md-4">
        <div>
          <svg width={'100%'} height={50}>
            <g>
              <line
                stroke="var(--color-basic-lightblue-1)"
                x1={0}
                x2={'100%'}
                y1={'50%'}
                y2={'50%'}
              ></line>
              {Array.from(Array(forAttention), (e, i) => {
                let x = '50%'
                if (forAttention === 1) {
                  x = '50%'
                } else if (forAttention === 2) {
                  x = `calc(45% + ${i * 10}%) `
                } else if (forAttention === 3) {
                  x = `calc(40% + ${i * 10}%) `
                } else if (forAttention === 4) {
                  x = `calc(35% + ${i * 10}%) `
                } else if (forAttention === 5) {
                  x = `calc(30% + ${i * 10}%) `
                } else if (forAttention === 6) {
                  x = `calc(25% + ${i * 10}%) `
                }
                return (
                  <Rhombus
                    key={i}
                    y={23}
                    x={x}
                    color={'var(--color-ppr-solid-yellow)'}
                  />
                )
              })}
            </g>
          </svg>
        </div>
        <div className="text-center">
          <svg width={BUTTON_W} height={BUTTON_H}>
            <defs>
              <linearGradient id="gradientYellow">
                <stop
                  offset="13.63%"
                  stopColor={'var(--color-ppr-yellow-solid)'}
                />
                <stop
                  offset="86.37%"
                  stopColor={'var(--color-ppr-yellow-light)'}
                />
              </linearGradient>
            </defs>
            <SvgPill fill={"url('#gradientYellow')"} value={forAttention} />
          </svg>
        </div>
      </div>
      <div className="col-md-4">
        <div>
          <svg width={'100%'} height={50}>
            <g>
              <line
                stroke="var(--color-basic-lightblue-1)"
                x1={0}
                x2={'100%'}
                y1={'50%'}
                y2={'50%'}
              ></line>
              {Array.from(Array(atRisk), (e, i) => {
                let x = '50%'
                if (atRisk === 1) {
                  x = '50%'
                } else if (atRisk === 2) {
                  x = `calc(45% + ${i * 10}%) `
                } else if (atRisk === 3) {
                  x = `calc(40% + ${i * 10}%) `
                } else if (atRisk === 4) {
                  x = `calc(35% + ${i * 10}%) `
                } else if (atRisk === 5) {
                  x = `calc(30% + ${i * 10}%) `
                } else if (atRisk === 6) {
                  x = `calc(25% + ${i * 10}%) `
                }
                return (
                  <Rhombus
                    key={i}
                    y={23}
                    x={x}
                    color={'var(--color-ppr-solid-red)'}
                  />
                )
              })}
            </g>
          </svg>
        </div>
        <div className="text-center">
          <svg width={BUTTON_W} height={BUTTON_H}>
            <defs>
              <linearGradient id="gradientRed">
                <stop
                  offset="13.63%"
                  stopColor={'var(--color-ppr-red-solid)'}
                />
                <stop
                  offset="86.37%"
                  stopColor={'var(--color-ppr-red-light)'}
                />
              </linearGradient>
            </defs>
            <SvgPill fill={"url('#gradientRed')"} value={atRisk} />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default function PPRSnapshotGroupCountry() {
  const { group, country } = useParams()
  const navigate = useNavigate()
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  const preview = searchParams.get('preview') ?? ''
  const apiParams = useMemoCompare({
    country,
    group,
    preview
  })
  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))

  const apiParamsGroup = useMemoCompare({
    group,
    preview
  })

  const [{ data }] = usePPRSnapshotAndIssues(year, apiParams)
  const [{ data: dataGroup }] = usePPRSnapshot(year, apiParamsGroup)
  const pprData = data?.ppr ?? null
  const issuesData = data?.issues ?? null

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const issuesAtRisk = useMemo(() => {
    if (issuesData && issuesData.length > 0) {
      return issuesData.filter((d) => d.severity_group === 'at-risk')[0]
    }
    return []
  }, [issuesData])

  const issuesForAttention = useMemo(() => {
    if (issuesData && issuesData.length > 0) {
      return issuesData.filter((d) => d.severity_group === 'for-attention')[0]
    }
    return []
  }, [issuesData])

  const countriesToUse = useMemo(() => {
    if (!dataGroup || dataGroup.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(dataGroup[0].countries, 'country').map(
      (c) => ({
        value: c.country,
        label: countriesByCode[c.country].name,
      })
    )
    if (year === 2021) {
      return distinctCountries.filter(
        (d) => !COUNTRIES_PPR_NO_SHOW.includes(d.value)
      )
    }
    return distinctCountries
  }, [countriesByCode, dataGroup, year])

  return (
    <LayoutPPR
      dataTest="page-ppr-snapshot-country"
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                preview,
                year: e.value,
              })
            }}
            className="mb-3"
            value={year}
            yearSelect
            options={yearsOptions}
          />
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../snapshot/${group}/${e.value}`,
                search: createSearchParams({ year }).toString(),
              })
            }}
            isDisabled={!dataGroup}
            options={[{ value: '', label: 'All countries' }].concat(
              countriesToUse
            )}
            value={country}
            label={'Country selected'}
          />
        </>
      }
    >
      <div className="h-100 w-100 pb-5">
        {!pprData && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
            <Spinner />
          </div>
        )}
        <div className="ps-5 percentage-info">
          Percentages may not add up due to rounding.
        </div>
        {pprData &&
          issuesData &&
          pprData.map((groupData, i) => (
            <Fragment key={groupData.group}>
              {groupData.countries.map((countryData, k) => (
                <div
                  key={countryData.country}
                  className="ps-4 d-flex flex-column min-width-card-300 h-100"
                >
                  <div className="d-flex h-100">
                    <div className="h-100">
                      <div className="text-center pt-5">
                        {countriesByCode[countryData.country].name}
                      </div>
                      <div style={{ height: 'calc(100% - 62px)' }}>
                        <BarChartStacked data={countryData} />
                      </div>
                    </div>
                    <div className="w-100 mt-4 h-100 pb-4">
                      <div className="asian-card-without-cursor h-100">
                        <div className="pt-4 ps-4 text-uppercase fs-10 fw-350 text-color-basic-dark-grey">
                          Number of issues
                        </div>
                        <div className="row fs-14 pt-5 ps-4">
                          <div className="col-md-4"></div>
                          <div className="col-md-4 text-center">
                            For attention
                          </div>
                          <div className="col-md-4 text-center">At risk</div>
                        </div>
                        <BlockIssueType
                          label={'Contract Award'}
                          atRisk={issuesAtRisk.contract_award}
                          forAttention={issuesForAttention.contract_award}
                        />
                        <BlockIssueType
                          label={'Disbursement'}
                          atRisk={issuesAtRisk.disbursment}
                          forAttention={issuesForAttention.disbursment}
                        />
                        <BlockIssueType
                          label={'Financial Mgmt'}
                          atRisk={issuesAtRisk.financial_management}
                          forAttention={issuesForAttention.financial_management}
                        />
                        <BlockIssueType
                          label={'Output'}
                          atRisk={issuesAtRisk.output}
                          forAttention={issuesForAttention.output}
                        />
                        <BlockIssueType
                          label={'Safeguards'}
                          atRisk={issuesAtRisk.safeguards}
                          forAttention={issuesForAttention.safeguards}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Fragment>
          ))}
      </div>
      {apiParams.preview && <PreviewMode />}
    </LayoutPPR>
  )
}

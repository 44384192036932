import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  COLOR_CLUSTER_WORD,
  FUND_ORDERS,
  MODALITY_ORDERS,
  SECTORS_ORDER,
} from '../../consts'
import mapOrder from '../../utils'
import { ChartCirclePack } from '../ChartCirclePack'
import styles from './CardSnapshotCluster.module.css'

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export default function CardSnapshotCluster({
  numProducts,
  className,
  totalAmount,
  title,
  link,
  clusterData,
  year,
  cluster: clusterParam,
}) {
  const [showTooltip, setShowTooltip] = useState(false)

  const computeColor = useCallback(
    (d) => {
      return `url('#color_${d.data[clusterParam]
        .toLowerCase()
        .replace(' ', '-')}')`
    },
    [clusterParam]
  )

  const computeValue = useCallback((node) => node.total_amount, [])
  const computeLabel = useCallback(
    (node) =>
      node.data.product_type || (node.data.fund_mnemonic === 'Others'
        ? 'Others/Cofinancing'
        : node.data.fund_mnemonic),
    []
  )

  return (
    <div
      style={{ overflowX: 'hidden', overflowY: 'auto' }}
      className={`${className} px-3 me-4 mb-1 asian-card text-center`}
    >
      <Link className="no-link cursor-custom" to={link}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Subtitle}>Amount in $ million</div>
        <div className={styles.ContainerAmount}>
          <div className={styles.LabelAmount}>Portfolio amount</div>
          <div className={styles.ValueAmount}>
            <span className={styles.Unit}>$</span>
            {amountFormatter.format(totalAmount)}
          </div>
          <div
            className={styles.ContainerChart}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <ChartCirclePack
              data={clusterData}
              cluster={clusterParam}
              computeColor={computeColor}
              computeValue={computeValue}
              computeLabel={computeLabel}
              height={200}
              marginLeft={0}
              year={year}
              marginRight={0}
              marginTop={10}
              marginBottom={10}
            />
            {showTooltip && (
              <div
                style={{
                  top: 0,
                  margin: 'auto',
                  left: 5,
                  zIndex: 10,
                  right: 5,
                  height: 'max-content',
                }}
                className={styles.TooltipChart}
                data-test="label-info"
              >
                {mapOrder(
                  clusterData,
                  clusterParam === 'product_type'
                    ? MODALITY_ORDERS
                    : clusterParam === 'fund_mnemonic'
                    ? FUND_ORDERS
                    : SECTORS_ORDER,
                  clusterParam === 'product_type'
                    ? 'product_type'
                    : clusterParam === 'fund_mnemonic'
                    ? 'fund_mnemonic'
                    : 'project__sector_code'
                ).map((cluster, i) => (
                  <div key={cluster[clusterParam]}>
                    <div className="w-100 d-flex justify-content-between align-items-center ps-3 mb-1">
                      <div
                        style={{ width: '33%' }}
                        className="fw-350 pt-1 fs-12 d-flex flex-nowrap align-items-center"
                      >
                        <div
                          style={{
                            backgroundColor: `var(--color-${
                              COLOR_CLUSTER_WORD[clusterParam]
                            }-solid-${cluster[clusterParam]
                              .toLowerCase()
                              .replace(' ', '-')})`,
                          }}
                          className={'CircleSector'}
                        ></div>
                        <div className="ms-2">
                          {cluster[clusterParam] === 'Others' &&
                          clusterParam === 'fund_mnemonic' ? (
                            <div className="text-start">
                              Others/
                              <br />
                              Cofinancing
                            </div>
                          ) : (
                            cluster[clusterParam]
                          )}
                        </div>
                      </div>
                      <div
                        style={{ width: '57%' }}
                        className="text-end  me-3 ms-3 fw-350 fs-14"
                      >
                        <span>
                          {amountFormatter.format(cluster.total_amount)}
                        </span>
                        <span className="fw-300 ms-1">$ mn</span>
                      </div>{' '}
                      <div
                        style={{ width: '21%' }}
                        className="text-end me-3 fs-14"
                      >
                        {((cluster.total_amount / totalAmount) * 100).toFixed(
                          0
                        )}{' '}
                        %
                      </div>{' '}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <hr className="mt-2 mx-3" style={{ color: '#E2EDF6', opacity: 1 }} />
          <div className="mt-2">
            <div className={styles.LabelAmount}>Number of products</div>
            <div className={styles.ValueAmount}>{numProducts}</div>
            <div className="d-flex flex-column flex-wrap mt-2" style={{ maxHeight: 120 }}>
              {mapOrder(
                clusterData,
                clusterParam === 'product_type'
                  ? MODALITY_ORDERS
                  : clusterParam === 'fund_mnemonic'
                  ? FUND_ORDERS
                  : SECTORS_ORDER,
                clusterParam === 'product_type'
                  ? 'product_type'
                  : clusterParam === 'fund_mnemonic'
                  ? 'fund_mnemonic'
                  : 'project__sector_code'
              ).map((cluster, i) => (
                <div key={cluster[clusterParam]} className={'col-md-6'}>
                  <div className="d-flex align-items-start px-1">
                    <div
                      style={{
                        color: `var(--color-${
                          COLOR_CLUSTER_WORD[clusterParam]
                        }-solid-${cluster[clusterParam]
                          .toLowerCase()
                          .replace(' ', '-')})`,
                        width: 50,
                        minWidth: 50,
                      }}
                      className="text-end me-3"
                    >
                      {amountFormatter.format(cluster.total_amount)}
                    </div>{' '}
                    <div className="fw-350 pt-1 fs-12 text-start text-color-basic-dark-grey">
                      {cluster[clusterParam] === 'Others' &&
                      clusterParam === 'fund_mnemonic'
                        ? 'Others / Cofinancing'
                        : cluster[clusterParam]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

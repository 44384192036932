import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import LayoutPortfolioInfo from './LayoutPortofolioInfo'
import { usePortfolioInfoTrend } from '../../hooks/portfolioInfo'
import Select from '../../components/Select'
import { LABELS_AND_COLORS_GROUP, OPTIONS_GROUP } from '../../consts'
import ProductsAreaChart from '../../components/ProductsAreaChart'
import { useDomain } from '../../Domain'
import { keyBy, uniqBy } from 'lodash'
import { Fragment, useMemo } from 'react'
import classNames from 'classnames'
import { LimitedChipsContainer } from '../Search/Search'
import Spinner from '../../components/Spinner'
import PreviewMode from '../../components/PreviewMode'

const ALL_COUNTRIES_VALUES = ['']

export default function TrendGroup() {
  const domain = useDomain()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { group } = useParams()
  const qsParams = {
    cluster: searchParams.get('cluster') ?? '',
    countries: searchParams.getAll('countries') ?? [],
    preview: searchParams.get('preview') ?? '',
  }
  const apiParams = useMemoCompare({
    group,
    ...qsParams,
  })
  const uiCountries =
    qsParams.countries.length > 0 ? qsParams.countries : ALL_COUNTRIES_VALUES
  const [{ data, dataParams, loading }] = usePortfolioInfoTrend(apiParams)

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!data || data.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(data[0].countries, 'project__country').map(
      (c) => ({
        value: c.project__country,
        label: countriesByCode[c.project__country].name,
      })
    )
    return distinctCountries
  }, [countriesByCode, data])

  const dataToUse = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].countries.filter((c) =>
        apiParams.countries.length > 0
          ? apiParams.countries.includes(c.project__country)
          : c
      )
    }
    return []
  }, [apiParams.countries, data])

  return (
    <LayoutPortfolioInfo
      dataTest="page-portfolio-trend-group"
      filters={
        <>
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../trend/${e.value}`,
              })
            }}
            className="mb-3"
            value={group}
            label={'Classification'}
            options={OPTIONS_GROUP}
            defaultValue={'fcas'}
          />
          <Select
            onChange={(e) => {
              if (
                dataParams.countries.length !== 0 &&
                e.filter((d) => d.value === '').length > 0
              ) {
                setSearchParams({
                  ...qsParams,
                  countries: [],
                })
              } else {
                setSearchParams({
                  ...qsParams,
                  countries: e.map((c) => c.value).filter(Boolean),
                })
              }
            }}
            closeMenuOnSelect={false}
            id="Countries"
            placeholder={'All countries'}
            components={
              dataParams &&
              dataParams.countries &&
              dataParams.countries.length > 0 && {
                MultiValue: LimitedChipsContainer,
              }
            }
            isMulti
            value={uiCountries}
            isDisabled={!data}
            options={[{ value: '', label: 'All countries' }].concat(
              countriesToUse
            )}
            label={'Countries comparison'}
          />
        </>
      }
    >
      <div className="h-100 w-100 position-relative overflow-y-scroll d-flex flex-column ps-5 pe-5 pb-5">
        {!data && loading && (
          <div className="h-100 w-100 d-flex align-items-center justify-content-cente">
            <Spinner />
          </div>
        )}
        {data &&
          data.map((countryData, j) => (
            <Fragment key={countryData.group}>
              {dataToUse.map((country, i) => (
                <Link
                  to={`${country.project__country}?cluster=${dataParams.cluster}&preview=${qsParams.preview}`}
                  className={classNames('w-100 no-link', {
                    'h-100': dataToUse.length > 3,
                  })}
                  key={country.project__country}
                  data-test="chart-countries"
                >
                  <div style={{ height: '27vh' }}>
                    <ProductsAreaChart
                      title={countriesByCode[country.project__country]?.name}
                      showYearsAxis={i + j === 0}
                      showYearsTicks={true}
                      country={country.project__country}
                      group={countryData.group}
                      syncId={'asian'}
                      colorStrong={LABELS_AND_COLORS_GROUP[group].color1}
                      colorLight={LABELS_AND_COLORS_GROUP[group].color2}
                      gradient={LABELS_AND_COLORS_GROUP[group].gradient}
                      cardBackground={true}
                      years={domain.years}
                      data={country.years}
                    />
                  </div>
                </Link>
              ))}
            </Fragment>
          ))}
        {/* <div className="bg-scroll"></div> */}
      </div>
      {qsParams.preview && <PreviewMode />}
    </LayoutPortfolioInfo>
  )
}

import Select from '../../components/Select'
import { useSearchParams } from 'react-router-dom'
import LayoutPortfolioInfo from './LayoutPortofolioInfo'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import {
  COLOR_CLUSTER_WORD,
  FUND_ORDERS,
  LABELS_AND_COLORS_GROUP,
  MODALITY_ORDERS,
  MODALITY_ORDERS_TOTALS_NONSOV,
  OPTIONS_CLUSTER,
  SECTORS_ORDER,
} from '../../consts'
import { useDomain } from '../../Domain'
import { useYearsOptions } from '../../hooks/options'
import {
  usePortfolioInfoSnapshot,
  usePortfolioInfoSnapshotTotals,
  usePortfolioInfoTrend,
  usePortfolioSnapshotTotals,
} from '../../hooks/portfolioInfo'
import CardSnapshotSingle from '../../components/CardSnapshotSingle'
import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import CardSnapshotCluster from '../../components/CardSnapshotCluster'
import { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import Spinner from '../../components/Spinner'
import mapOrder from '../../utils'
import PreviewMode from '../../components/PreviewMode'

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
})

function BlockTotals({ totals, clusterParam, year, preview }) {
  const [showTooltip, setShowTooltip] = useState(false)
  const cluster = useMemo(() => {
    return {
      cluster: clusterParam,
      preview
    }
  }, [clusterParam, preview])
  const [{ data: totalsToUse }] = usePortfolioSnapshotTotals(year, cluster)
  return (
    <div
      className="position-absolute d-flex pt-3 ps-5 pe-4 pb-5"
      style={{ left: '25%', right: 0, zIndex: 1000 }}
    >
      <div
        onMouseEnter={() => clusterParam && setShowTooltip('sov')}
        onMouseLeave={() => clusterParam && setShowTooltip(null)}
        className="col-md asian-card-without-cursor pt-3 pb-3 ps-4 pe-4 me-3"
        style={{ height: 'max-content' }}
      >
        <div>ADB Sovereign Portfolio</div>
        <div>
          <span className="fw-300 fs-12">Amount</span>
          <span className="fw-350 fs-14 ms-1">
            {totalsToUse &&
              amountFormatter.format(totalsToUse['totals_sov'].total)}
          </span>
          <span className="fw-300 fs-14"> $ mn</span>
        </div>
        {showTooltip &&
          showTooltip === 'sov' &&
          clusterParam !== 'fund_mnemonic' && (
            <div className="TooltipTotals">
              {mapOrder(
                totalsToUse.sov_cluster,
                clusterParam === 'product_type'
                  ? MODALITY_ORDERS
                  : clusterParam === 'fund_mnemonic'
                  ? FUND_ORDERS
                  : SECTORS_ORDER,
                'label'
              ).map((cluster, i) => (
                <div key={cluster.id}>
                  <div className="w-100 d-flex justify-content-between align-items-center ps-3 mb-1">
                    <div
                      style={{ width: '30%' }}
                      className="fw-350 pt-1 fs-12 d-flex align-items-center justify-content-start"
                    >
                      <div
                        style={{
                          backgroundColor: `var(--color-${
                            COLOR_CLUSTER_WORD[cluster.cluster]
                          }-solid-${cluster.label
                            .toLowerCase()
                            .replace(' ', '-')})`,
                        }}
                        className={'CircleSector'}
                      ></div>
                      <div className="ms-2">{cluster.label}</div>
                    </div>
                    <div
                      style={{ width: '40%' }}
                      className="text-end  me-3 ms-3 fw-350 fs-14"
                    >
                      <span>{amountFormatter.format(cluster.amount)}</span>
                      <span className="fw-300 ms-1">$ mn</span>
                    </div>{' '}
                    <div
                      style={{ width: '30%' }}
                      className="text-end me-3 fs-14"
                    >
                      {(
                        (cluster.amount / totalsToUse['totals_sov'].total) *
                        100
                      ).toFixed(1)}{' '}
                      %
                    </div>{' '}
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>
      <div
        onMouseEnter={() => clusterParam && setShowTooltip('nonsov')}
        onMouseLeave={() => clusterParam && setShowTooltip(null)}
        className="col-md asian-card-without-cursor pt-3 pb-3 ps-4 pe-4 me-3"
        style={{ height: 'max-content' }}
      >
        <div>ADB Non Sovereign Portfolio</div>
        <div>
          <span className="fw-300 fs-12">Amount</span>
          <span className="fw-350 fs-14 ms-1">
            {totalsToUse &&
              amountFormatter.format(totalsToUse['totals_nonsov'].total)}
          </span>
          <span className="fw-300 fs-14"> $ mn</span>
        </div>
        {showTooltip &&
          showTooltip === 'nonsov' &&
          clusterParam !== 'fund_mnemonic' && (
            <div className="TooltipTotals" style={{ zIndex: 1000 }}>
              {mapOrder(
                totalsToUse.sov_nonsov,
                clusterParam === 'product_type'
                  ? MODALITY_ORDERS_TOTALS_NONSOV
                  : clusterParam === 'fund_mnemonic'
                  ? FUND_ORDERS
                  : SECTORS_ORDER,
                'label'
              ).map((cluster, i) => (
                <div key={cluster[clusterParam]}>
                  <div className="d-flex justify-content-between align-items-center me-3 mb-1">
                    <div
                      style={{ width: '30%' }}
                      className="fw-350 pt-1 fs-12 d-flex align-items-center justify-content-start"
                    >
                      <div
                        style={{
                          backgroundColor: `var(--color-${
                            COLOR_CLUSTER_WORD[cluster.cluster]
                          }-solid-${cluster.label
                            .toLowerCase()
                            .replace(' ', '-')})`,
                        }}
                        className={'CircleSector'}
                      ></div>
                      <div className="ms-2">{cluster.label}</div>
                    </div>
                    <div
                      style={{ width: '40%' }}
                      className="text-end  me-3 ms-3 fw-350 fs-14"
                    >
                      <span>{amountFormatter.format(cluster.amount)}</span>
                      <span className="fw-300 ms-1">$ mn</span>
                    </div>{' '}
                    <div
                      style={{ width: '30%' }}
                      className="text-end me-3 fs-14"
                    >
                      {(
                        (cluster.amount / totalsToUse['totals_nonsov'].total) *
                        100
                      ).toFixed(1)}{' '}
                      %
                    </div>{' '}
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  )
}

export function CardsGroupCluster({ data, totals, cluster, year, preview }) {
  return (
    <div className="flex-1 d-flex flex-column position-relaative overflow-hidden">
      <BlockTotals year={year} clusterParam={cluster} totals={totals} preview={preview} />
      <div
        style={{ height: 'calc(100vh - 170px)', marginTop: 100 }}
        className="d-flex pt-3 ps-5 pe-4 pb-5"
      >
        {orderBy(data, 'total_amount', 'desc').map((groupData, i) => (
          <CardSnapshotCluster
            totalAmount={groupData.total_amount}
            title={LABELS_AND_COLORS_GROUP[groupData.group].label}
            cluster={cluster}
            className="col-md"
            year={year}
            clusterData={groupData.cluster}
            key={groupData.group}
            link={`${groupData.group}?cluster=${cluster}&year=${year}`}
            numProducts={groupData.num_projects}
          />
        ))}
      </div>
    </div>
  )
}

export function CardsGroup({ data, totals, year, preview }) {

  const filtersforData = useMemo(() => {
    return {
      preview: preview
    }
  }, [preview])

  const [{ data: trend }] = usePortfolioInfoTrend(filtersforData)

  const allImports = useMemo(() => {
    if (!trend) {
      return []
    }
    let all = []
    trend.map((t) => t.years.map((d) => all.push(d.total_amount)))
    return all
  }, [trend])

  const domain = extent(allImports)

  const scaleCircle = scaleLinear()
    .domain([domain[0], domain[1]])
    .range([10, 80])

  return (
    <div className="flex-1 d-flex flex-column overflow-hidden">
      <BlockTotals year={year} totals={totals} preview={preview} />
      <div
        style={{ height: 'calc(100vh - 170px)', marginTop: 100 }}
        className="d-flex pt-3 ps-5 pe-4 pb-5"
      >
        {orderBy(data, 'total_amount', 'desc').map((groupData, i) => (
          <CardSnapshotSingle
            totalAmount={groupData.total_amount}
            radiusCircle={scaleCircle(groupData.total_amount)}
            title={LABELS_AND_COLORS_GROUP[groupData.group].label}
            className="col-md"
            year={year}
            gradient={LABELS_AND_COLORS_GROUP[groupData.group].gradient}
            color1={LABELS_AND_COLORS_GROUP[groupData.group].color1}
            color2={LABELS_AND_COLORS_GROUP[groupData.group].color2}
            key={groupData.group}
            link={groupData.group + '?year=' + year+ '&preview='+preview}
            numProducts={groupData.num_projects}
          />
        ))}
      </div>
    </div>
  )
}

export default function SnaphostIndex() {
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))
  const apiParams = useMemoCompare({
    cluster: searchParams.get('cluster') ?? '',
    preview: searchParams.get('preview') ?? '',
  })
  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')

  const [{ data, dataParams, loading }] = usePortfolioInfoSnapshot(
    year,
    apiParams
  )
  const [{ data: totals }] = usePortfolioInfoSnapshotTotals(year, apiParams)

  return (
    <LayoutPortfolioInfo
      showSectorTooltip={apiParams.cluster === 'project__sector_code'}
      dataTest="page-portfolio-snapshot"
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                ...apiParams,
                year: e.value,
              })
            }}
            className="mb-3"
            value={year}
            yearSelect
            options={yearsOptions}
          />
          <Select
            onChange={(e) => {
              setSearchParams({
                ...apiParams,
                year,
                cluster: e.value,
              })
            }}
            value={apiParams.cluster}
            label={'Cluster By'}
            options={OPTIONS_CLUSTER}
          />
        </>
      }
    >
      {!data && loading && (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      )}
      {data &&
        totals &&
        (dataParams.cluster ? (
          <CardsGroupCluster
            cluster={dataParams.cluster}
            totals={totals}
            data={data}
            year={year}
            preview={apiParams.preview}
          />
        ) : (
          <CardsGroup year={year} totals={totals} data={data} preview={
            apiParams.preview
          } />
        ))}
      {/* <AutoTipModal type={'portfolio-snapshot'} imageSource={imageSource} /> */}
      {apiParams.preview && <PreviewMode />}
    </LayoutPortfolioInfo>
  )
}

import styles from './BarDCPercent.module.css'

export default function BarDCPercent({ className, percent, color, colorText }) {
  return (
    <div className={`${className} ${styles.ChartPercent}`}>
      <div className={styles.BgChartPercent}>
        <div
          className={styles.BgPercentActive}
          style={{
            width: `${percent}%`,
            background: color,
          }}
        ></div>
      </div>
      <div style={{ marginTop: '-4px'}}>
        <div style={{ color: colorText }} className="ms-3">
          {percent} %
        </div>
      </div>
    </div>
  )
}

import MenuLayout from '../../components/MenuLayout'
import { useSearchProducts } from '../../hooks/search'
import qs from 'query-string'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useMemo, useState } from 'react'
import styles from './Search.module.css'
import Select from '../../components/Select'
import { useSearchParams } from 'react-router-dom'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useYearsOptionsSearch } from '../../hooks/options'
import classNames from 'classnames'
import filtersIcon from './assets/filters.svg'
import searchIcon from './assets/search.svg'
import arrowIcon from './assets/arrow.svg'
import ProjectDetail from '../../components/ProjectDetail'
import { components } from 'react-select'
import { keyBy, orderBy, uniqBy } from 'lodash'
import { useDomain } from '../../Domain'
import Sorter, { SortControl } from '../../components/Sorter'
import Spinner from '../../components/Spinner'
import { useExportToken } from '../../hooks/exportToken'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import infoIcon from './assets/info.svg'
import Tutorial from '../../components/Tutorial'
import { useLastImportDate } from '../../LastImport'
import PreviewMode from '../../components/PreviewMode'

const ALL_DEFAULT_VALUES = ['']

const LINK_DOWNLOAD =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000'

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
})

export const LimitedChipsContainer = ({ children, ...props }) => {
  if (props.index > 0) return null

  const { length } = props.getValue()
  return length > 0 ? (
    <>
      <span>{props.selectProps.id}:</span>{' '}
      <span className={styles.FilterNumberSelect}>{`${length}`}</span>
    </>
  ) : (
    <components.MultiValue {...props} />
  )
}

export const LimitedChipsContainerDefault = ({
  children,
  hasValue,
  ...props
}) => {
  return (
    <components.ValueContainer {...props}>
      {/* {props.selectProps.placeholder} */}
      {children}
    </components.ValueContainer>
  )
}

export default function Search() {
  const domain = useDomain()
  const [searchBox, setSearchBox] = useState(false)
  // const [project, openProject] = useState(null)
  const [project, openProject] = useModalTrigger()
  const [hoverProduct, setHoverProduct] = useState(null)
  const [modalFilters, setOpenModalFilters] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [tutorial, actionsTutorial] = useModalTrigger()
  const [year, setYear] = useState(null)
  const qsParams = {
    group: searchParams.get('group') ?? '',
    country: searchParams.getAll('country') ?? [],
    year: searchParams.get('year') ?? Math.max(...domain.years),
    sector_code: searchParams.getAll('sector_code') ?? [],
    modality: searchParams.getAll('modality') ?? '',
    fund: searchParams.getAll('fund') ?? '',
    soveraign: searchParams.get('soveraign') ?? 'soveraign',
    archive: searchParams.get('archive') ?? 'archive',
    search: searchParams.get('search') ?? '',
    ordering: searchParams.get('ordering') ?? 'project__country',
    preview: searchParams.get('preview') ?? '',
  }

  const qsParamsFirst = {
    group: searchParams.get('group') ?? '',
    year: searchParams.get('year') ?? Math.max(...domain.years),
    soveraign: searchParams.get('soveraign') ?? 'soveraign',
    archive: searchParams.get('archive') ?? 'archive',
    preview: searchParams.get('preview') ?? '',
  }

  const apiParams = useMemoCompare({
    ...qsParams,
  })

  const apiParamsFirst = useMemoCompare({
    ...qsParamsFirst,
  })

  const exportToken = useExportToken()

  const link = useMemo(() => {
    if (exportToken) {
      return (
        LINK_DOWNLOAD +
        `/api/products/csv/?${qs.stringify({
          ...apiParams,
          token: exportToken,
        })}`
      )
    }
  }, [apiParams, exportToken])

  const uiCountries =
    qsParams.country.length > 0 ? qsParams.country : ALL_DEFAULT_VALUES

  const uiSectors =
    qsParams.sector_code.length > 0 ? qsParams.sector_code : ALL_DEFAULT_VALUES

  const uiModalities =
    qsParams.modality.length > 0 ? qsParams.modality : ALL_DEFAULT_VALUES

  const uiFunds = qsParams.fund.length > 0 ? qsParams.fund : ALL_DEFAULT_VALUES

  const [{ data, dataParams, loading }] = useSearchProducts(apiParams)
  const [{ data: dataFirst }] = useSearchProducts(apiParamsFirst)

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!dataFirst || dataFirst.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(dataFirst, 'project_data.country').map(
      (c) => ({
        value: c.project_data.country,
        label: countriesByCode[c.project_data.country].name,
      })
    )
    return orderBy(distinctCountries, 'label')
  }, [countriesByCode, dataFirst])

  const sectorsToUse = useMemo(() => {
    if (!dataFirst || dataFirst.length === 0) {
      return []
    }
    const distinctSectors = uniqBy(dataFirst, 'project_data.sector_code').map(
      (c) => ({
        value: c.project_data.sector_code,
        label: c.project_data.sector_code,
      })
    )
    return orderBy(distinctSectors, 'label')
  }, [dataFirst])

  const modalitiesToUse = useMemo(() => {
    if (!dataFirst || dataFirst.length === 0) {
      return []
    }
    const distinctModalities = uniqBy(dataFirst, 'product_type').map((c) => ({
      value: c.product_type,
      label: c.product_type,
    }))
    return orderBy(distinctModalities, 'label')
  }, [dataFirst])

  const fundsToUse = useMemo(() => {
    if (!dataFirst || dataFirst.length === 0) {
      return []
    }
    const distinctFunds = uniqBy(dataFirst, 'fund_mnemonic').map((c) => ({
      value: c.fund_mnemonic,
      label: c.fund_mnemonic,
    }))
    return orderBy(distinctFunds, 'label')
  }, [dataFirst])

  const yearsOptions = orderBy(useYearsOptionsSearch(), 'value', 'desc')

  const lastImport = useLastImportDate()

  return (
    <div
      style={{ backgroundColor: 'var(--color-basic-lightblue-1)' }}
      className="h-100 overflow-hidden"
      data-test="page-search"
    >
      <div className="d-flex justify-content-between align-items-center">
        <MenuLayout
          classLeft={false}
          className={'ps-4 pt-3'}
          noDate={true}
          title=""
          subtitle=""
        />
        <div
          className="d-flex flex-column align-items-end"
          style={{ marginTop: -20 }}
        >
          <div
            className="mb-4 me-4 pointer"
            onClick={() => actionsTutorial.open('search')}
          >
            <img src={infoIcon} alt="Tutorial" data-test="info-search"/>
          </div>
          <div className={styles.DownloadButton}>
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className={`no-link w-100 align-items-center d-flex ${styles.ButtonFiltersDownload}`}
            >
              <span className="me-3">Download data</span>
              <div className="d-flex flex-column align-items-center">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  className={styles.ArrowDownload}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1.5L7 12.75M7 12.75L12.25 7.5M7 12.75L1.75 7.5"
                    stroke="#0D8ECA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  width="16"
                  className={styles.LineDownload}
                  height="2"
                  viewBox="0 0 16 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.75 1L1.25 1"
                    stroke="#0D8ECA"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-end ps-3 pe-3 mb-4">
        <div className={styles.FiltersContainer}>
          {qsParams && (
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <div className={styles.Archive}>
                  {qsParams.archive === 'archive'
                    ? 'PORTFOLIO'
                    : 'COMPLETED PRODUCTS'}
                </div>
                <div className={styles.Filter}>
                  <span className={styles.FilterLabel}>
                    Financial Operations:
                  </span>
                  <span className={styles.FilterValue}>
                    {qsParams.soveraign === 'soveraign'
                      ? 'Sovereign'
                      : 'Non Sovereign'}
                  </span>
                </div>
                <div className={styles.Filter}>
                  <span className={styles.FilterLabel}>Year:</span>
                  <span className={styles.FilterValue}>
                    {lastImport.last_year === qsParams.year
                      ? `${qsParams.year} ${lastImport.last_quarter}`
                      : qsParams.year}
                  </span>
                </div>
                <div className={styles.Filter}>
                  <span className={styles.FilterLabel}>Classification:</span>
                  <span className={styles.FilterValue}>
                    {qsParams.group
                      ? qsParams.group === 'fcas'
                        ? 'FCAS'
                        : 'SIDS'
                      : 'All'}
                  </span>
                </div>
                <div className={styles.Filter}>
                  <span className={styles.FilterLabel}>Country:</span>
                  <span className={styles.FilterValue}>
                    {qsParams.country && qsParams.country.length > 0 ? (
                      <span className={styles.FilterNumber}>
                        {qsParams.country.length}
                      </span>
                    ) : (
                      'All'
                    )}
                  </span>
                </div>
                <div className={styles.Filter}>
                  <span className={styles.FilterLabel}>Modality:</span>
                  <span className={styles.FilterValue}>
                    {qsParams.modality && qsParams.modality.length > 0 ? (
                      <span className={styles.FilterNumber}>
                        {qsParams.modality.length}
                      </span>
                    ) : (
                      'All'
                    )}
                  </span>
                </div>
                <div className={styles.Filter}>
                  <span className={styles.FilterLabel}>Fund:</span>
                  <span className={styles.FilterValue}>
                    {qsParams.fund && qsParams.fund.length > 0 ? (
                      <span className={styles.FilterNumber}>
                        {qsParams.fund.length}
                      </span>
                    ) : (
                      'All'
                    )}
                  </span>
                </div>
                <div className={styles.Filter}>
                  <span className={styles.FilterLabel}>Sector:</span>
                  <span className={styles.FilterValue}>
                    {qsParams.sector_code && qsParams.sector_code.length > 0 ? (
                      <span className={styles.FilterNumber}>
                        {qsParams.sector_code.length}
                      </span>
                    ) : (
                      'All'
                    )}
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div
                  onClick={() => setOpenModalFilters(true)}
                  className={styles.ButtonFilters}
                  data-test="button-filters"
                >
                  <img className="me-1" src={filtersIcon} alt="Filters" />
                  FILTERS
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-100 d-flex justify-content-end ps-3 pe-3 mb-4">
        <input
          className={styles.Search}
          placeholder="Search"
          type={'search'}
          value={apiParams.search}
          onChange={(e) =>
            setSearchParams({
              ...apiParams,
              search: e.target.value,
            })
          }
          onFocus={() => setSearchBox(true)}
          onBlur={() => setSearchBox(false)}
          style={{
            width: searchBox ? '100%' : 150,
            background: `url(${searchIcon}) no-repeat scroll 15px 18px`,
            paddingLeft: 48,
            transition: 'all 0.3s linear',
          }}
          data-test="input-search"
        />
      </div>
      {loading && <Spinner />}
      {data && !loading && dataParams && (
        <div
          style={{ height: 'calc(100vh - 201px)' }}
          className="overflow-y-scroll"
        >
          <table
            onMouseLeave={() => setHoverProduct(null)}
            className="table ms-3 mb-2 table-borderless table-search"
          >
            <Sorter
              value={qsParams.ordering}
              onSortChange={(ordering) => {
                setSearchParams({
                  ...qsParams,
                  ordering,
                })
              }}
            >
              <thead>
                <tr>
                  <th>
                    <div className="d-flex">
                      Title
                      <SortControl field={'project__project_title'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      eOps
                      <SortControl field={'project__project_code'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Approval No.
                      <SortControl field={'approval_no'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Country
                      <SortControl field={'project__country'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Classification <SortControl field={'label_group'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Sector
                      <SortControl field={'project__sector_code'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Modality
                      <SortControl field={'product_type'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Fund Source
                      <SortControl field={'fund_mnemonic'} />
                    </div>
                    <span>&nbsp;</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Disbursement
                      <SortControl field={'disbursement'} />
                    </div>
                    <span className={styles.ThUnit}>($ million)</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Contract Award
                      <SortControl field={'contract_award'} />
                    </div>
                    <span className={styles.ThUnit}>($ million)</span>
                  </th>
                  <th>
                    <div className="d-flex">
                      Net amount
                      <SortControl field={'amount'} />
                    </div>
                    <span className={styles.ThUnit}>($ million)</span>
                  </th>
                </tr>
              </thead>
            </Sorter>
            <tbody>
              {data.map((product, i) => (
                <tr
                  className="pointer position-relative"
                  onClick={() => {
                    openProject.open(product.project_data.id)
                    setYear(qsParams.year)
                  }}
                  key={product.id}
                  data-test="search-table-row"
                >
                  <td
                    onMouseEnter={() => {
                      setHoverProduct(product.id)
                    }}
                  >
                    <div>
                      <div className="text-ellipsis">
                        {product.project_data.project_title}
                      </div>
                      <div>({product.project_data.project_code})</div>
                    </div>
                  </td>
                  <td className="text-nowrap">
                    {product.project_data.project_code}
                  </td>
                  <td>{product.approval_no.replace('.0', '')}</td>
                  <td>{product.project_data.country}</td>
                  <td>{product.label_group}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          backgroundColor: `var(--color-sectors-solid-${product.project_data.sector_code.toLowerCase()})`,
                        }}
                        className={styles.CircleSector}
                      ></div>
                      <div className="ms-2">
                        {product.project_data.sector_code}
                      </div>
                    </div>
                  </td>
                  <td>{product.product_type}</td>
                  <td>
                    {product.fund_mnemonic === 'Others'
                      ? 'Others/Cofinancing'
                      : product.fund_mnemonic}
                  </td>
                  <td>{amountFormatter.format(product.disbursement)}</td>
                  <td>{amountFormatter.format(product.contract_award)}</td>
                  <td>{amountFormatter.format(product.amount)}</td>
                  {hoverProduct && hoverProduct === product.id && (
                    <div
                      className="position-absolute pointer"
                      style={{
                        left: 0,
                        top: 0,
                        width: 'max-content',
                        background: '#E2EDF6',
                        transition: '0.2s linear all',
                        borderRadius: 10,
                      }}
                      onClick={() => openProject.open(product.project_data.id)}
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          <div>{product.project_data.project_title}</div>
                          <div>({product.project_data.project_code})</div>
                        </div>
                        <div>
                          <img src={arrowIcon} alt="Go to project" />
                        </div>
                      </div>
                    </div>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {data && dataParams && (
        <Modal
          isOpen={modalFilters}
          centered
          className={styles.ModalSearch}
          toggle={() => setOpenModalFilters(!modalFilters)}
          size={'xl'}
          data-test="modal-filters"
        >
          <ModalHeader
            children={
              <div className={styles.HeaderModal}>
                <div className="d-flex">
                  <div
                    onClick={() => {
                      setSearchParams({
                        ...apiParams,
                        archive: 'archive',
                      })
                    }}
                    className={classNames('text-primary-asian pointer', {
                      'text-decoration-underline search-active':
                        dataParams.archive === 'archive',
                      'search-disactive': dataParams.archive !== 'archive',
                    })}
                    data-test="switch-portfolio"
                  >
                    Portfolio
                  </div>
                  <div
                    onClick={() => {
                      setSearchParams({
                        ...apiParams,
                        archive: 'non-archive',
                      })
                    }}
                    className={classNames('ms-4 text-primary-asian pointer', {
                      'text-decoration-underline search-active':
                        dataParams.archive !== 'archive',
                      'search-disactive': dataParams.archive === 'archive',
                    })}
                    data-test="switch-completed"
                  >
                    Completed Products
                  </div>
                </div>
                <div
                  className={styles.CloseModal}
                  onClick={() => setOpenModalFilters(false)}
                  data-test="close-modal"
                >
                  <svg
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="45"
                      height="45"
                      className={styles.BurgerCircle}
                      rx="22.5"
                      fill="#F9FAFE"
                    />
                    <path
                      d="M18.4164 17.2717C18.2664 17.1268 18.0654 17.0466 17.8569 17.0484C17.6483 17.0502 17.4488 17.1339 17.3013 17.2814C17.1538 17.4289 17.0702 17.6284 17.0683 17.8369C17.0665 18.0455 17.1467 18.2464 17.2916 18.3965L21.8854 22.9902L17.2908 27.584C17.2148 27.6573 17.1542 27.7451 17.1126 27.8422C17.0709 27.9392 17.0489 28.0436 17.048 28.1492C17.0471 28.2548 17.0672 28.3596 17.1072 28.4573C17.1472 28.5551 17.2063 28.6439 17.281 28.7186C17.3556 28.7933 17.4445 28.8524 17.5422 28.8923C17.64 28.9323 17.7447 28.9525 17.8503 28.9516C17.956 28.9506 18.0603 28.9287 18.1574 28.887C18.2544 28.8453 18.3422 28.7847 18.4156 28.7087L23.0101 24.115L27.6039 28.7087C27.7539 28.8536 27.9548 28.9338 28.1634 28.932C28.372 28.9302 28.5715 28.8465 28.719 28.6991C28.8665 28.5516 28.9501 28.3521 28.9519 28.1435C28.9537 27.9349 28.8736 27.734 28.7287 27.584L24.1349 22.9902L28.7287 18.3965C28.8736 18.2464 28.9537 18.0455 28.9519 17.8369C28.9501 17.6284 28.8665 17.4289 28.719 17.2814C28.5715 17.1339 28.372 17.0502 28.1634 17.0484C27.9548 17.0466 27.7539 17.1268 27.6039 17.2717L23.0101 21.8654L18.4164 17.2709V17.2717Z"
                      fill="#0D8ECA"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="45"
                      height="45"
                      rx="22.5"
                      stroke="#0D8ECA"
                    />
                  </svg>
                </div>
              </div>
            }
          ></ModalHeader>
          <ModalBody className={styles.ModalSearchBody}>
            {dataParams && (
              <>
                <div className="row">
                  <div className="col-md-3">
                    <Select
                      // isDisabled={dataParams.archive !== 'archive'}
                      onChange={(e) => {
                        setSearchParams({
                          ...apiParams,
                          soveraign: e.value,
                        })
                      }}
                      label="ADB Operation"
                      value={qsParams.soveraign}
                      options={[
                        {
                          label: 'Sovereign',
                          value: 'soveraign',
                        },
                        { label: 'Non sovereign', value: 'non-soveraign' },
                      ]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      onChange={(e) => {
                        setSearchParams({
                          ...apiParams,
                          year: e.value,
                        })
                      }}
                      label={'Year'}
                      value={parseInt(qsParams.year)}
                      options={yearsOptions}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className={styles.LabelClassification}>
                      Classification by selected year
                    </div>
                    <div className="d-flex">
                      <div className={styles.ContainerSwitch}>
                        <div
                          onClick={() => {
                            setSearchParams({
                              ...apiParams,
                              group: '',
                            })
                          }}
                          className={
                            qsParams.group === ''
                              ? styles.ActiveItem
                              : styles.Item
                          }
                          data-test="switch-all"
                        >
                          All
                        </div>
                        <div
                          onClick={() => {
                            setSearchParams({
                              ...apiParams,
                              group: 'fcas',
                            })
                          }}
                          className={
                            qsParams.group === 'fcas'
                              ? styles.ActiveItem
                              : styles.Item
                          }
                          data-test="switch-fcas"
                        >
                          FCAS
                        </div>
                        <div
                          onClick={() => {
                            setSearchParams({
                              ...apiParams,
                              group: 'sids',
                            })
                          }}
                          className={
                            qsParams.group === 'sids'
                              ? styles.ActiveItem
                              : styles.Item
                          }
                          data-test="switch-sids"
                        >
                          SIDS
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-5 mb-5">
                  <div className="col-md-3">
                    <Select
                      onChange={(e) => {
                        if (
                          qsParams.country.length !== 0 &&
                          e.filter((d) => d.value === '').length > 0
                        ) {
                          setSearchParams({
                            ...qsParams,
                            country: [],
                          })
                        } else {
                          setSearchParams({
                            ...qsParams,
                            country: e.map((c) => c.value).filter(Boolean),
                          })
                        }
                      }}
                      id="Countries"
                      placeholder={'All Countries'}
                      closeMenuOnSelect={false}
                      components={
                        qsParams &&
                        qsParams.country &&
                        qsParams.country.length > 0 && {
                          MultiValue: LimitedChipsContainer,
                        }
                      }
                      isMulti
                      value={uiCountries}
                      isDisabled={!data}
                      options={[{ value: '', label: 'All countries' }].concat(
                        countriesToUse
                      )}
                      label={'Countries comparison'}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      onChange={(e) => {
                        if (
                          qsParams.sector_code.length !== 0 &&
                          e.filter((d) => d.value === '').length > 0
                        ) {
                          setSearchParams({
                            ...qsParams,
                            sector_code: [],
                          })
                        } else {
                          setSearchParams({
                            ...qsParams,
                            sector_code: e.map((c) => c.value).filter(Boolean),
                          })
                        }
                      }}
                      id="Sectors"
                      closeMenuOnSelect={false}
                      components={
                        qsParams &&
                        qsParams.sector_code &&
                        qsParams.sector_code.length > 0 && {
                          MultiValue: LimitedChipsContainer,
                        }
                      }
                      placeholder="All sectors"
                      isMulti
                      label="Sectors"
                      isDisabled={!data || !dataFirst || !dataParams}
                      value={uiSectors}
                      options={[{ value: '', label: 'All sectors' }].concat(
                        sectorsToUse
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      onChange={(e) => {
                        if (
                          qsParams.fund.length !== 0 &&
                          e.filter((d) => d.value === '').length > 0
                        ) {
                          setSearchParams({
                            ...qsParams,
                            fund: [],
                          })
                        } else {
                          setSearchParams({
                            ...qsParams,
                            fund: e.map((c) => c.value).filter(Boolean),
                          })
                        }
                      }}
                      closeMenuOnSelect={false}
                      id="Funds"
                      components={
                        qsParams &&
                        qsParams.fund &&
                        qsParams.fund.length > 0 && {
                          MultiValue: LimitedChipsContainer,
                        }
                      }
                      isMulti
                      isDisabled={!data || !dataFirst || !dataParams}
                      placeholder="All funds"
                      label="Fund sources"
                      value={uiFunds}
                      options={[{ value: '', label: 'All funds' }].concat(
                        fundsToUse
                      )}
                    />
                  </div>
                  <div className="col-md-3">
                    <Select
                      onChange={(e) => {
                        if (
                          qsParams.modality.length !== 0 &&
                          e.filter((d) => d.value === '').length > 0
                        ) {
                          setSearchParams({
                            ...qsParams,
                            modality: [],
                          })
                        } else {
                          setSearchParams({
                            ...qsParams,
                            modality: e.map((c) => c.value).filter(Boolean),
                          })
                        }
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      id="Modalities"
                      components={
                        qsParams &&
                        qsParams.modality &&
                        qsParams.modality.length > 0 && {
                          MultiValue: LimitedChipsContainer,
                        }
                      }
                      placeholder="All modalities"
                      isDisabled={!data || !dataFirst || !dataParams}
                      label="Modality"
                      value={uiModalities}
                      options={[{ value: '', label: 'All modalities' }].concat(
                        modalitiesToUse
                      )}
                    />
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="d-flex pt-5 pe-5 pb-5 align-items-center justify-content-between">
            <div
              onClick={() => {
                setSearchParams({})
              }}
              className={styles.ResetFilters}
              style={{
                opacity:
                  apiParams.country.length === 0 &&
                  apiParams.sector_code.length === 0 &&
                  apiParams.fund.length === 0 &&
                  apiParams.modality.length === 0 &&
                  apiParams.group === '' &&
                  apiParams.search === ''
                    ? 0.5
                    : 1,
                cursor:
                  apiParams.country.length === 0 &&
                  apiParams.sector_code.length === 0 &&
                  apiParams.fund.length === 0 &&
                  apiParams.modality.length === 0 &&
                  apiParams.group === '' &&
                  apiParams.search === ''
                    ? 'initial'
                    : 'pointer',
              }}
              data-test="button-reset"
            >
              RESET FILTERS
            </div>
            <div className="d-flex align-items-center">
              <div className={styles.Results}>
                {data && data.length} Results
              </div>
              <Button
                className={styles.ButtonResults}
                onClick={() => setOpenModalFilters(false)}
                data-test="button-show-result"
              >
                Show Result
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
      {!loading && project.value && (
        <ProjectDetail
          isOpen={project.isOpen}
          toggle={() => {
            setYear(null)
            openProject.toggle()
          }}
          project={project.value}
          year={year}
          onExited={() => openProject.onClosed()}
        />
      )}
      <Tutorial
        openSection={tutorial.value}
        tutorial={tutorial}
        actionsTutorial={actionsTutorial}
      />
      {qsParams.preview && <PreviewMode />}
    </div>
  )
}

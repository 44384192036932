export default function GradientClusters() {
  return (
    <>
      <linearGradient id="color_anr">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-anr)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-anr)"></stop>
      </linearGradient>
      <linearGradient id="color_others_sectors">
        <stop
          offset="13.63%"
          stopColor="var(--color-sectors-solid-others)"
        ></stop>
        <stop
          offset="86.37%"
          stopColor="var(--color-sectors-light-others)"
        ></stop>
      </linearGradient>
      <linearGradient id="color_psm">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-psm)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-psm)"></stop>
      </linearGradient>
      <linearGradient id="color_hlt">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-hlt)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-hlt)"></stop>
      </linearGradient>
      <linearGradient id="color_ind">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-ind)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-ind)"></stop>
      </linearGradient>
      <linearGradient id="color_wus">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-wus)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-wus)"></stop>
      </linearGradient>
      <linearGradient id="color_ene">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-ene)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-ene)"></stop>
      </linearGradient>
      <linearGradient id="color_edu">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-edu)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-edu)"></stop>
      </linearGradient>
      <linearGradient id="color_ict">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-ict)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-ict)"></stop>
      </linearGradient>
      <linearGradient id="color_fin">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-fin)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-fin)"></stop>
      </linearGradient>
      <linearGradient id="color_tra">
        <stop offset="13.63%" stopColor="var(--color-sectors-solid-tra)"></stop>
        <stop offset="86.37%" stopColor="var(--color-sectors-light-tra)"></stop>
      </linearGradient>
      <linearGradient id="color_grant">
        <stop offset="13.63%" stopColor="var(--color-modality-solid-grant)"></stop>
        <stop offset="86.37%" stopColor="var(--color-modality-light-grant)"></stop>
      </linearGradient>
      <linearGradient id="color_loan">
        <stop offset="13.63%" stopColor="var(--color-modality-solid-loan)"></stop>
        <stop offset="86.37%" stopColor="var(--color-modality-light-loan)"></stop>
      </linearGradient>
      <linearGradient id="color_ta">
        <stop offset="13.63%" stopColor="var(--color-modality-solid-ta)"></stop>
        <stop offset="86.37%" stopColor="var(--color-modality-light-ta)"></stop>
      </linearGradient>
      <linearGradient id="color_guarantee">
        <stop offset="13.63%" stopColor="var(--color-modality-solid-guarantee)"></stop>
        <stop offset="86.37%" stopColor="var(--color-modality-light-guarantee)"></stop>
      </linearGradient>
      <linearGradient id="color_fund">
        <stop offset="13.63%" stopColor="var(--color-modality-solid-fund)"></stop>
        <stop offset="86.37%" stopColor="var(--color-modality-light-fund)"></stop>
      </linearGradient>
      <linearGradient id="color_direct-investment">
        <stop offset="13.63%" stopColor="var(--color-modality-solid-direct-investment)"></stop>
        <stop offset="86.37%" stopColor="var(--color-modality-light-direct-investment)"></stop>
      </linearGradient>
      <linearGradient id="color_equity">
        <stop offset="13.63%" stopColor="var(--color-modality-solid-equity)"></stop>
        <stop offset="86.37%" stopColor="var(--color-modality-light-equity)"></stop>
      </linearGradient>
      <linearGradient id="color_tasf">
        <stop offset="13.63%" stopColor="var(--color-fund-solid-tasf)"></stop>
        <stop offset="86.37%" stopColor="var(--color-fund-light-tasf)"></stop>
      </linearGradient>
      <linearGradient id="color_adf">
        <stop offset="13.63%" stopColor="var(--color-fund-solid-adf)"></stop>
        <stop offset="86.37%" stopColor="var(--color-fund-light-adf)"></stop>
      </linearGradient>
      <linearGradient id="color_col">
        <stop offset="13.63%" stopColor="var(--color-fund-solid-col)"></stop>
        <stop offset="86.37%" stopColor="var(--color-fund-light-col)"></stop>
      </linearGradient>
      <linearGradient id="color_ocr">
        <stop offset="13.63%" stopColor="var(--color-fund-solid-ocr)"></stop>
        <stop offset="86.37%" stopColor="var(--color-fund-light-ocr)"></stop>
      </linearGradient>
      <linearGradient id="color_others">
        <stop offset="13.63%" stopColor="var(--color-fund-solid-others)"></stop>
        <stop offset="86.37%" stopColor="var(--color-fund-light-others)"></stop>
      </linearGradient>
    </>
  )
}

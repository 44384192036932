import { keyBy, orderBy, uniqBy } from 'lodash'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useMemo } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import CardDCSnapshot from '../../components/CardDCSnapshot'
import Select from '../../components/Select'
import { OPTIONS_GROUP_PPR } from '../../consts'
import { useDomain } from '../../Domain'
import { useDCSnapshot, useDCTrend } from '../../hooks/disbandcontract'
import { useYearsOptions } from '../../hooks/options'
import { LimitedChipsContainer } from '../Search/Search'
import LayoutDisbursmentContract from './LayoutDisbursmentContract'
import PreviewMode from '../../components/PreviewMode'

export default function DCSnapshotGroup() {
  const { group } = useParams()
  const domain = useDomain()
  const [searchParams, setSearchParams] = useSearchParams()
  // const apiParams = useMemoCompare({
  //   group,
  // })
  const qsParams = {
    countries: searchParams.getAll('countries') ?? [],
    year: Number(searchParams.get('year') ?? Math.max(...domain.years)),
    preview: searchParams.get('preview') ?? '',
  }

  const apiParams = useMemoCompare({
    ...qsParams,
    group,
  })

  const ALL_COUNTRIES_VALUES = ['']

  const uiCountries =
    qsParams.countries.length > 0 ? qsParams.countries : ALL_COUNTRIES_VALUES

  const yearsOptions = orderBy(useYearsOptions(), 'value', 'desc')
  const year = Number(searchParams.get('year') ?? Math.max(...domain.years))
  const [{ data, dataParams }] = useDCSnapshot(year, apiParams)

  const navigate = useNavigate()

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!data || data.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(data[0].countries, 'project__country').map(
      (c) => ({
        value: c.project__country,
        label: countriesByCode[c.project__country].name,
      })
    )
    return distinctCountries
  }, [countriesByCode, data])

  const dataToUse = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].countries.filter((c) =>
        apiParams.countries.length > 0
          ? apiParams.countries.includes(c.project__country)
          : c
      )
    }
    return []
  }, [apiParams.countries, data])

  const fcasFilter = useMemoCompare({
    group: 'fcas',
  })

  const sidsFilter = useMemoCompare({
    group: 'sids',
  })

  const [{ data: fcasTrend }] = useDCTrend(fcasFilter)
  const [{ data: sidsTrend }] = useDCTrend(sidsFilter)

  const allImports = useMemo(() => {
    if (!fcasTrend || !sidsTrend) {
      return []
    }
    let all = []
    fcasTrend.map((t) =>
      t.countries.map((d) => d.years.map((c) => all.push(c.total_approved)))
    )
    sidsTrend.map((t) =>
      t.countries.map((d) => d.years.map((c) => all.push(c.total_approved)))
    )
    return all
  }, [fcasTrend, sidsTrend])

  return (
    <LayoutDisbursmentContract
      dataTest="page-disbursement-snapshot-group"
      filters={
        <>
          <Select
            onChange={(e) => {
              setSearchParams({
                ...qsParams,
                year: e.value,
              })
            }}
            className={'mb-3'}
            value={year}
            options={yearsOptions}
            yearSelect
          />
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../snapshot/${e.value}`,
                search: createSearchParams({ year }).toString(),
              })
            }}
            value={group}
            className="mb-3"
            label={'Classification'}
            options={OPTIONS_GROUP_PPR}
            defaultValue={'fcas'}
          />
          <Select
            onChange={(e) => {
              if (
                dataParams.countries.length !== 0 &&
                e.filter((d) => d.value === '').length > 0
              ) {
                setSearchParams({
                  ...qsParams,
                  countries: [],
                })
              } else {
                setSearchParams({
                  ...qsParams,
                  countries: e.map((c) => c.value).filter(Boolean),
                })
              }
            }}
            closeMenuOnSelect={false}
            id="Countries"
            className="mb-3"
            placeholder={'All countries'}
            components={
              dataParams &&
              dataParams.countries &&
              dataParams.countries.length > 0 && {
                MultiValue: LimitedChipsContainer,
              }
            }
            isMulti
            value={uiCountries}
            isDisabled={!data}
            options={[{ value: '', label: 'All countries' }].concat(
              countriesToUse
            )}
            label={'Countries comparison'}
          />
        </>
      }
    >
      <div className="d-flex overflow-y-scroll h-100 pt-4 ps-5 pe-5 pb-5 m-0">
        {data &&
          dataToUse &&
          dataToUse.map((countryData, k) => (
            <CardDCSnapshot
              allImports={allImports}
              className={'min-width-card'}
              title={countriesByCode[countryData.project__country]?.name}
              allData={data[0].countries}
              cursor={false}
              singleData={countryData}
              key={k}
            />
          ))}
      </div>
      {apiParams.preview && <PreviewMode />}
    </LayoutDisbursmentContract>
  )
}

import { useMemo } from 'react'
import { useDomain } from '../Domain'
import { useLastImportDate } from '../LastImport'

export function useContryOptions() {
  const domain = useDomain()
  return useMemo(() => {
    return domain.countries_data.map((c) => ({ value: c.code, label: c.name }))
  }, [domain])
}

export function useYearsOptions() {
  const domain = useDomain()
  const lastImport = useLastImportDate()
  return useMemo(() => {
    return [...domain.years].reverse().map((year) => ({
      value: year,
      label:
        year === lastImport.last_year
          ? `${year} ${lastImport.last_quarter}`
          : year,
    }))
  }, [domain, lastImport])
}

export function useYearsOptionsSearch() {
  const domain = useDomain()
  const lastImport = useLastImportDate()
  return useMemo(() => {
    return [...domain.years]
      .reverse()
      .map((year) => ({
        value: year,
        label:
          year === lastImport.last_year
            ? `${year} ${lastImport.last_quarter}`
            : year,
      }))
  }, [domain, lastImport])
}

export function useModalitiesOptions() {
  const domain = useDomain()
  return useMemo(() => {
    return domain.product_types.map((modality) => ({
      value: modality,
      label: modality,
    }))
  }, [domain])
}

export function useFunds() {
  const domain = useDomain()
  return useMemo(() => {
    return domain.funds_mnemonic.map((fund) => ({ value: fund, label: fund }))
  }, [domain])
}

export function useSectorsCompletedOptions() {
  const domain = useDomain()
  return useMemo(() => {
    return domain.sectors_completed.map((sector) => ({
      value: sector,
      label: sector,
    }))
  }, [domain])
}

export function useSectorsIndicativePipelinesOptions() {
  const domain = useDomain()
  return useMemo(() => {
    return domain.ppr_completed_sectors.map((sector) => ({
      value: sector,
      label: sector,
    }))
  }, [domain])
}

import styles from './About.module.css'
import arrow from './assets/arrow-black.svg'

export default function FragileAndConflict() {
  return (
    <div className="row pt-5 mb-5" data-test="text-fragile-conflict">
      <div className="offset-md-1 col-md-10">
        <h2 className={styles.Title}>
          Fragile and Conflict-Affected Situations
        </h2>
        <div className={styles.Subtitle}>
          Many of the region’s poor and most vulnerable live in areas affected
          by fragility and conflict. Countries experiencing fragility and
          conflict face multitude of challenges including weakened governance
          capacity, economic and social disruption, geographic isolation, and
          insecurity. Public services delivery systems seldom function well, and
          the government's ability to guarantee the basic security of its people
          is often limited. Conflicts and fragility not only impact countries
          where they are happening but also neighbouring countries and the
          global community.
        </div>
        <div className={styles.BlockContent}>
          <div className={styles.MiniTitle}>classifying countries as fcas</div>
          <div className={styles.Paragraph}>
            The ADB approach in FCAS is governed by its 2013 Operational Plan
            for ADB’s Effectiveness in Fragile and Conflict-Affected Situations
            (FCAS Operational Plan). Under the FCAS Operational Plan, ADB uses
            the harmonized Multilateral Development Banks (MDBs) FCAS
            classification methodology.
          </div>
          <div className={styles.Paragraph}>
            The classification of a country as FCAS means that it is eligible
            for additional funding under the Asian Development Fund 13 (ADF) and
            the Technical Assistance Special Fund (TASF7).
          </div>
          <div className={styles.Paragraph}>
            ADB conducts a country performance assessment using the country
            policy and institutional assessments questionnaire. Each country's
            performance is assessed based on the coherence of its macroeconomic
            and structural policies, quality of its governance and public sector
            management, the degree to which its policies and institutions
            promote equity and inclusion, and performance of its portfolio of
            ongoing projects and programs.
          </div>
          <div className={styles.Paragraph}>
            A country is considered FCAS if it has an average rating of 3.2 or
            less based on the ADB country performance assessment and the World
            Bank Group country policy and institutional assessment. A country is
            also considered FCAS if a United Nations and/or regional
            peace-keeping or peace-building mission has been present during the
            past 3 years.
          </div>
          <div className={styles.MiniTitle}>
            2022 LIST OF COUNTRIES CLASSIFIED AS FCAS
          </div>
          <div className={styles.Paragraph}>
            In 2022, ADB classified the following 12 developing member countries
            as FCAS: Afghanistan, Lao People’s Democratic Republic, Kiribati,
            Marshall Islands, Federated States of Micronesia (FSM), Myanmar,
            Nauru, Palau, Papua New Guinea, Solomon Islands, Timor-Leste, and
            Tuvalu.
          </div>
          <div className={styles.Paragraph}>
            The next FCAS country classification will be conducted in 2024,
            following the completion of the next country performance assessment.
          </div>
          <div className={styles.Paragraph}>
            No CPA was conducted for Afghanistan and Myanmar in 2022 due to lack
            of information exchange or other communication with the respective
            governments. Therefore, ADB will continue to apply the 2020 CPA for
            these countries.
          </div>
          <div className={styles.MiniTitle}>
            A differentiated approach for FCAS and SIDS
          </div>
          <div className={styles.Paragraph}>
            The challenges of countries experiencing fragility and conflict
            require long-term solutions, and building capable and legitimate
            institutions is critical. Refining ADB’s approach to context
            sensitive situations is an important element in implementing
            Strategy 2030. Focusing on FCAS and defining the necessary
            adjustments in SIDS operations will be key part of the ADB’s support
            for the fulfillment of the Sustainable Development Goals (SDGs).
            Consistent with the broader global dialogue on the
            peace-humanitarian nexus and aid effectiveness, ADB is adapting its
            approach to FCAS and SIDS developing and strengthening linkages with
            the seven operational priorities and the private sector plan that
            support the implementation of Strategy 2030.
          </div>
          <h2 className={styles.MiniTitle}>Note</h2>
          <div className={styles.Paragraph}>
            ADB placed its development assistance in Afghanistan on hold
            effective from 15 August 2021, but the bank remains committed to
            supporting the economic and social development of the Afghan people.
          </div>
          <div className={styles.Paragraph}>
            ADB placed a temporary hold on sovereign project disbursements and
            new contracts in Myanmar effective 1 February 2021. ADB continues to
            monitor the situation in the country.
          </div>
          <div className={styles.MiniTitle}>external links</div>
          <div className={styles.ParagraphLink}>
            <a
              rel="noreferrer"
              target={'_blank'}
              className={styles.ExternalLink}
              href="https://www.adb.org/documents/operational-plan-enhancing-adb-effectiveness-fragile-and-conflict-affected-situations"
            >
              Operational Plan for ADB’s Effectiveness in Fragile and
              Conflict-Affected Situations (FCAS Operational Plan)
              <span className="ms-5">
                <img src={arrow} alt="Indicative pipeline" />
              </span>
            </a>
          </div>
          <div className={styles.ParagraphLink}>
            <a
              rel="noreferrer"
              target={'_blank'}
              className={styles.ExternalLink}
              href="https://data.adb.org/dataset/country-performance-assessment-cpa-data"
            >
              Country Performance Assessment (CPA)
              <span className="ms-5">
                <img src={arrow} alt="Indicative pipeline" />
              </span>
            </a>
          </div>
          <div className={styles.ParagraphLink}>
            <a
              rel="noreferrer"
              target={'_blank'}
              className={styles.ExternalLink}
              href="https://www.adb.org/what-we-do/themes/fcas-sids/main"
            >
              FCAS and SIDS Approach (FSA)
              <span className="ms-5">
                <img src={arrow} alt="Indicative pipeline" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

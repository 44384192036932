import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import BarChartStacked from '../BarChartStacked/BarChartStacked'
import './TetrisBarChart.css'
import { useLastImportDate } from '../../LastImport'

const YEAR_HEIGHT = 20
const BAR_SIZE = 27
const CHART_MARGIN_LEFT = 30
const CHART_HEIGHT = 210
const MARGIN_BOTTOM = 20
const MARGIN_LEFT = 20
const MARGIN_RIGHT = 20
const LEGEND_H = 25
const BUTTON_H = 21
const BUTTON_W = 30

const RHOMBUS_L = 8

function Rhombus({ color, x, y, l = RHOMBUS_L }) {
  return (
    <g
      style={{
        transform: `translate(${x}px, ${y - l / 2}px)`,
      }}
    >
      <rect
        style={{ transform: 'rotate(45deg)' }}
        width={l}
        height={l}
        fill={color}
      />
    </g>
  )
}

function DemoniacRhombus({ width, height, color, count }) {
  const l = RHOMBUS_L

  let comb = null
  switch (count) {
    case 0:
      return null
    case 1:
      comb = (
        <g>
          <Rhombus color={color} x={width / 2} y={height / 2} />
        </g>
      )
      break
    case 2:
      comb = (
        <g>
          <Rhombus color={color} x={width / 2 - l * 1.5} y={height / 2} />
          <Rhombus color={color} x={width / 2 + l * 1.5} y={height / 2} />
        </g>
      )
      break
    case 3:
      comb = (
        <g>
          <Rhombus color={color} x={width / 2 - l * 2} y={height / 2} />
          <Rhombus color={color} x={width / 2} y={height / 2} />
          <Rhombus color={color} x={width / 2 + l * 2} y={height / 2} />
        </g>
      )
      break
    case 4:
      comb = (
        <g>
          <Rhombus color={color} x={width / 2 - l * 1.1} y={height / 2} />
          <Rhombus color={color} x={width / 2} y={height / 2 - l * 1.1} />
          <Rhombus color={color} x={width / 2} y={height / 2 + l * 1.1} />
          <Rhombus color={color} x={width / 2 + l * 1.1} y={height / 2} />
        </g>
      )
      break
    case 6:
      comb = (
        <g>
          <g>
            <Rhombus
              color={color}
              x={width / 2 - l * 1.2}
              y={height / 2 - l * 1.2}
            />
            <Rhombus
              color={color}
              x={width / 2 + l * 1.2}
              y={height / 2 + l * 1.2}
            />
            <Rhombus
              color={color}
              x={width / 2 - l * 1.2}
              y={height / 2 + l * 1.2}
            />
            <Rhombus
              color={color}
              x={width / 2 + l * 1.2}
              y={height / 2 - l * 1.2}
            />
            <Rhombus color={color} x={width / 2} y={height / 2} />
            <Rhombus color={color} x={width / 4} y={height / 2} />
          </g>
        </g>
      )
      break
    case 7:
      comb = (
        <g>
          <g>
            <Rhombus
              color={color}
              x={width / 2 - l * 1.2}
              y={height / 2 - l * 1.2}
            />
            <Rhombus
              color={color}
              x={width / 2 + l * 1.2}
              y={height / 2 + l * 1.2}
            />
            <Rhombus
              color={color}
              x={width / 2 - l * 1.2}
              y={height / 2 + l * 1.2}
            />
            <Rhombus
              color={color}
              x={width / 2 + l * 1.2}
              y={height / 2 - l * 1.2}
            />
            <Rhombus color={color} x={width / 2} y={height / 2} />
            <Rhombus color={color} x={width / 4} y={height / 2} />
            <Rhombus color={color} x={width * 4} y={height / 2} />
          </g>
        </g>
      )
      break
    default:
      comb = (
        <g>
          <Rhombus
            color={color}
            x={width / 2 - l * 1.2}
            y={height / 2 - l * 1.2}
          />
          <Rhombus
            color={color}
            x={width / 2 + l * 1.2}
            y={height / 2 + l * 1.2}
          />
          <Rhombus
            color={color}
            x={width / 2 - l * 1.2}
            y={height / 2 + l * 1.2}
          />
          <Rhombus
            color={color}
            x={width / 2 + l * 1.2}
            y={height / 2 - l * 1.2}
          />
          <Rhombus color={color} x={width / 2} y={height / 2} />
        </g>
      )
  }

  return (
    <g>
      {/* <rect
        stroke="green"
        fill="transparent"
        x={0}
        y={0}
        height={height}
        width={width}
      /> */}
      {comb}
    </g>
  )
}

function SvgPill({ value, color }) {
  return (
    <g className="tetris-bar-chart-pill">
      <rect
        rx={11}
        fill={color}
        x={0}
        y={0}
        width={BUTTON_W}
        height={BUTTON_H}
      />
      <text fill="white" textAnchor="middle" x={BUTTON_W / 2} y={BUTTON_H - 6}>
        {value}
      </text>
    </g>
  )
}

const RHOMBUS_OVERAP = 1

function StupidColOfRhombus({ width, height, top = LEGEND_H, data }) {
  const x = CHART_MARGIN_LEFT + BAR_SIZE / 2
  const h = (height - top) / data.length
  return (
    <svg width={width} height={height} data-test="chart-rhombus">
      <defs>
        <linearGradient id="gradientYellow">
          <stop offset="13.63%" stopColor={'var(--color-ppr-yellow-solid)'} />
          <stop offset="86.37%" stopColor={'var(--color-ppr-yellow-light)'} />
        </linearGradient>
        <linearGradient id="gradientRed">
          <stop offset="13.63%" stopColor={'var(--color-ppr-red-solid)'} />
          <stop offset="86.37%" stopColor={'var(--color-ppr-red-light)'} />
        </linearGradient>
      </defs>
      <line
        className="tetris-bar-chart-middle-line"
        x1={x}
        x2={x}
        y1={0}
        y2={height}
        stroke="#ccc"
      />
      <g style={{ transform: `translateY(${top - 15}px)` }}>
        {data.map((datum, i) => (
          <g key={datum.field}>
            <g
              style={{
                transform: `translate(0px, ${
                  (i * h) / 2 + RHOMBUS_OVERAP + 2
                }px)`,
              }}
            >
              <DemoniacRhombus
                count={datum.valueA}
                color="var(--color-ppr-solid-yellow)"
                height={h / 2}
                width={x * 2}
              />
            </g>
            <g
              style={{
                transform: `translate(${x * 1.7}px, ${
                  (i * h) / 2 + RHOMBUS_OVERAP + h / 5
                }px)`,
              }}
            >
              <SvgPill color={"url('#gradientYellow')"} value={datum.valueA} />
            </g>
          </g>
        ))}
      </g>
      <g style={{ transform: `translateY(${top + h * 2.4}px)` }}>
        {data.map((datum, i) => (
          <g key={datum.field}>
            <g
              style={{
                transform: `translate(0px, ${
                  (i * h) / 2 + RHOMBUS_OVERAP + 2
                }px)`,
              }}
            >
              <DemoniacRhombus
                count={datum.valueB}
                color="var(--color-ppr-solid-red)"
                height={h / 2}
                width={x * 2}
              />
            </g>
            <g
              style={{
                transform: `translate(${x * 1.7}px, ${
                  (i * h) / 2 + RHOMBUS_OVERAP + h / 5
                }px)`,
              }}
            >
              <SvgPill color={"url('#gradientRed')"} value={datum.valueB} />
            </g>
          </g>
        ))}
      </g>
    </svg>
  )
}

function TetrisBarChartCol({ barData, issueData }) {
  const flatIssueData = useMemo(() => {
    const fields = [
      'contract_award',
      'disbursment',
      'financial_management',
      'output',
      'safeguards',
    ]
    return fields.map((field) => ({
      field,
      valueA: issueData['for-attention'][field],
      valueB: issueData['at-risk'][field],
    }))
  }, [issueData])

  const ref = useRef(null)
  const [height, setHeight] = useState(0)

  const onResize = useCallback(() => {
    if (ref.current) setHeight(ref.current.clientHeight)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const date = useLastImportDate()

  return (
    <div className="col-md h-100 position-relative tetris-bar-chart-container d-flex flex-column">
      <div
        className="tetris-bar-chart-year"
        style={{ height: YEAR_HEIGHT, marginLeft: CHART_MARGIN_LEFT }}
      >
        {barData.year
          .toString()
          .replace(date.last_year, `${date.last_year} ${date.last_quarter}`)}
      </div>
      <div
        className={'d-flex flex-column tetris-bar-chart-card'}
        style={{
          width: '100%',
          left: 0,
          top: YEAR_HEIGHT,
          bottom: 0,
          position: 'absolute',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: CHART_HEIGHT,
            marginLeft: CHART_MARGIN_LEFT,
          }}
        >
          {/* NOTE: BURN IN HELL  */}
          <div
            className="tetris-bar-chart-tick"
            style={{
              position: 'absolute',
              height: 8,
              width: 1,
              border: '1px solid #ccc',
              top: 0,
              left: BAR_SIZE / 2 - 1,
            }}
          />
          <div
            className="tetris-bar-chart-tick"
            style={{
              position: 'absolute',
              height: 8,
              width: 1,
              border: '1px solid #ccc',
              bottom: 0,
              left: BAR_SIZE / 2 - 1,
            }}
          />
          <BarChartStacked
            marginBottom={5}
            marginTop={5}
            showTotal={false}
            barBorderRadius={3}
            labelMargin={5}
            labelWidth={130}
            barSize={BAR_SIZE}
            data={barData}
          />
          <div
            className="project-rated mt-1 ms-1"
            style={{ fontSize: 12, color: '#8F9091' }}
          >
            {barData.at_risk + barData.on_track + barData.for_attention}{' '}
            projects rated
          </div>
        </div>
        <div
          ref={ref}
          style={{
            marginBottom: MARGIN_BOTTOM,
            height: `calc(100% - ${CHART_HEIGHT}px)`,
          }}
        >
          <StupidColOfRhombus
            width={'100%'}
            height={height}
            data={flatIssueData}
          />
        </div>
      </div>
    </div>
  )
}

export default function TetrisBarChart({ data, issuesData, countryName }) {
  const height = CHART_HEIGHT + YEAR_HEIGHT

  return (
    <div className="h-100 w-100 ps-5 pe-5 d-flex position-relative">
      <div
        className="d-flex flex-1 flex-column"
        style={{
          position: 'absolute',
          top: height + LEGEND_H,
          bottom: MARGIN_BOTTOM,
          left: MARGIN_LEFT,
          right: MARGIN_RIGHT,
        }}
      >
        <div
          className="flex-1 mb-2  d-flex align-items-center"
          style={{ background: 'rgba(249, 250, 254, 0.7)', borderRadius: 10 }}
        >
          <div className={'label-vertical'}>For attention</div>
        </div>
        <div
          className="flex-1 position-relative d-flex align-items-center"
          style={{ background: 'rgba(249, 250, 254, 0.7)', borderRadius: 10 }}
        >
          <div className={'label-vertical'}>At risk</div>
        </div>
      </div>
      <div
        className="d-flex flex-column"
        style={{ marginBottom: MARGIN_BOTTOM, zIndex: 1 }}
      >
        <div
          style={{
            height: height,
            paddingTop: YEAR_HEIGHT,
            minWidth: 140,
            maxWidth: 180,
            minHeight: 220,
            whiteSpace: 'break-spaces',
          }}
        >
          {countryName}
          <div className="fw-300 fs-10">
            <div className="d-flex align-items-center">
              <div
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: '50%',
                  background: 'var(--color-ppr-solid-green)',
                }}
                className="me-2"
              ></div>
              On track
            </div>
            <div className="d-flex align-items-center">
              <div
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: '50%',
                  background: 'var(--color-ppr-solid-yellow)',
                }}
                className="me-2"
              ></div>
              For attention
            </div>
            <div className="d-flex align-items-center">
              <div
                style={{
                  height: 8,
                  width: 8,
                  borderRadius: '50%',
                  background: 'var(--color-ppr-solid-red)',
                }}
                className="me-2"
              ></div>
              At risk
            </div>
          </div>
        </div>

        <div className="d-flex flex-column flex-1 ps-2">
          <div
            className="fs-10 pt-2 fw-350 text-uppercase text-color-basic-dark-grey"
            style={{ height: LEGEND_H }}
          >
            number of issues
          </div>
          <div className="d-flex flex-column flex-1  pt-2">
            <div className="fs-14 d-flex align-items-center flex-1 fw-300">
              Contract Award
            </div>
            <div className="fs-14 d-flex align-items-center mt-3  flex-1 fw-300 ">
              Disbursement
            </div>
            <div className="fs-14 d-flex align-items-center mt-3  flex-1 fw-300">
              Financial Mgmt
            </div>
            <div className="fs-14 d-flex align-items-center mt-3 pt-1 flex-1 fw-300">
              Output
            </div>
            <div className="fs-14 d-flex align-items-center mt-3 flex-1 fw-300">
              Safeguards
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-1 pt-2  ps-2">
          <div className="fs-14 d-flex align-items-center flex-1 pt-2 fw-300">
            Contract Award
          </div>
          <div className="fs-14 d-flex align-items-center flex-1 fw-300 ">
            Disbursement
          </div>
          <div className="fs-14 d-flex align-items-center flex-1 fw-300">
            Financial Mgmt
          </div>
          <div className="fs-14 d-flex align-items-center flex-1 fw-300">
            Output
          </div>
          <div className="fs-14 d-flex align-items-center flex-1 fw-300">
            Safeguards
          </div>
        </div>
      </div>
      {data.map((barData) => (
        <TetrisBarChartCol
          issueData={issuesData[barData.year]}
          barData={barData}
          key={barData.year}
        />
      ))}
    </div>
  )
}

import FragileAndConflict from './FragileAndConflict'
import LayoutAbout from './LayoutAbout'
import ProjectPerformanceRating from './ProjectPerformanceRating'
import SmallIslandDevelopingStates from './SmallIslandDevelopingStates'
import styles from './About.module.css'
import { useEffect, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import DataSourceAndUpdates from './DataSourceAndUpdates'
import { useSearchParams } from 'react-router-dom'

export default function About() {
  const [activePage, setActivePage] = useState('')
  const fragileRef = useRef()
  const smallRef = useRef()
  const pprRef = useRef()
  const [searchParams] = useSearchParams()
  const dataSourceRef = useRef()
  useEffect(() => {
    if(searchParams.get('page') === 'ppr'){
      pprRef.current.scrollIntoView()
    }
  },[searchParams])

  return (
    <LayoutAbout
      filters={
        <>
          <div className={styles.ContainerLink}>
            <div
              className={
                activePage === 'fragile' ? styles.LinkActive : styles.Link
              }
              onClick={() =>
                fragileRef.current.scrollIntoView({
                  alignToTop: true,
                  behavior: 'smooth',
                })
              }
              data-test="filter-fragile-conflict"
            >
              Fragile and Conflict-Affected Situations
            </div>
          </div>
          <div className={styles.ContainerLink}>
            <div
              className={
                activePage === 'small' ? styles.LinkActive : styles.Link
              }
              onClick={() =>
                smallRef.current.scrollIntoView({
                  alignToTop: true,
                  behavior: 'smooth',
                })
              }
              data-test="filter-small-island"
            >
              Small Island Developing States
            </div>
          </div>
          <div className={styles.ContainerLink}>
            <div
              className={activePage === 'ppr' ? styles.LinkActive : styles.Link}
              onClick={() =>
                pprRef.current.scrollIntoView({
                  alignToTop: true,
                  behavior: 'smooth',
                })
              }
              data-test="filter-ppr"
            >
              Project Performance Rating (PPR)
            </div>
          </div>
          <div className={styles.ContainerLink}>
            <div
              className={
                activePage === 'data_source' ? styles.LinkActive : styles.Link
              }
              onClick={() =>
                dataSourceRef.current.scrollIntoView({
                  alignToTop: true,
                  behavior: 'smooth',
                })
              }
              data-test="filter-data-source"
            >
              Data Source and Update
            </div>
          </div>
        </>
      }
    >
      <div className={`${styles.About} container-fluid`} data-test="page-about">
        <Waypoint
          fireOnRapidScroll
          onEnter={() => {
            setActivePage('fragile')
          }}
        >
          <div ref={fragileRef}>
            <FragileAndConflict />
          </div>
        </Waypoint>
        <Waypoint onEnter={() => setActivePage('small')}>
          <div ref={smallRef}>
            <SmallIslandDevelopingStates />
          </div>
        </Waypoint>
        <Waypoint onEnter={() => setActivePage('ppr')}>
          <div ref={pprRef}>
            <ProjectPerformanceRating />
          </div>
        </Waypoint>
        <Waypoint onEnter={() => setActivePage('data_source')}>
          <div ref={dataSourceRef}>
            <DataSourceAndUpdates />
          </div>
        </Waypoint>
      </div>
    </LayoutAbout>
  )
}

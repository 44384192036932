import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom'
// import * as Sentry from '@sentry/react'
import { Outlet } from 'react-router'
import Auth from './Auth'
import { RequireAuth, RequireGuest } from './routing'
import PageNotFound from './components/PageNotFound'
import PortfolioInformation from './pages/PorfolioInformation'
import Domain from './Domain'
import ProjectPerformanceRating from './pages/ProjectPerformanceRating'
import DisbursmentAndContract from './pages/DisbursmentAndContract'
import CompletedProducts from './pages/CompletedProducts/CompletedProducts'
import Search from './pages/Search/Search'
import Home from './pages/Home/Home'
import Login from './pages/Login'
import LastImport from './LastImport'
import About from './pages/About'
import Import from './pages/Import'
import { Logout } from './pages/Logout'
import queryString from 'query-string'
import { useMemo } from 'react'

export default function App() {
  //query params for preview mode from query string
  const qs = useMemo(() => queryString.parse(window.location.search), [])
  const preview = useMemo(() => qs.preview, [qs])
  return (
    <Auth>
      <LastImport preview={preview}>
        <Domain preview={preview}>
          <Router>
            <Routes>
              {/* GUEST */}
              <Route
                path="/"
                element={
                  <RequireGuest>
                    <Outlet />
                  </RequireGuest>
                }
              >
                <Route path="login" element={<Login />} />
              </Route>
              {/* AUTH */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Outlet />
                  </RequireAuth>
                }
              >
                <Route index element={<Home />} />
                <Route
                  path="portfolio-distribution/*"
                  element={<PortfolioInformation />}
                />
                <Route
                  path="project-performance-rating/*"
                  element={<ProjectPerformanceRating />}
                />
                <Route
                  path="completed-products/*"
                  element={<CompletedProducts />}
                />
                {/* <Route
                  path="indicative-pipelines/*"
                  element={<IndicativePipelines />}
                /> */}
                <Route path="search/*" element={<Search />} />
                <Route
                  path="disbursment-and-contract-awarded/*"
                  element={<DisbursmentAndContract />}
                />
                <Route path="about" element={<About />} />
                {}
                <Route
                  path="import"
                  element={
                    <RequireAuth
                      redirectTest={(user) => {
                        if (!user.is_tool_admin) {
                          return '/'
                        } else {
                          return null
                        }
                      }}
                    >
                      <Import />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="logout" element={<Logout />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        </Domain>
      </LastImport>
    </Auth>
  )
}

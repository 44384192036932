import { keyBy, difference, uniq, orderBy } from 'lodash'
import { PureComponent, useMemo } from 'react'
import {
  Area,
  Dot,
  AreaChart,
  ComposedChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import classNames from 'classnames'
import { COUNTRIES_EXCEPTIONS_TREND } from '../../consts'
import { useLastImportDate } from '../../LastImport'

const MARGIN_LEFT = 40
const MARGIN_RIGHT = 50

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
})

function LineTick(props) {
  const { x, y } = props
  return (
    <line
      x1={x}
      y1={y - 20}
      x2={x}
      y2={y + 12}
      stroke="var(--color-light-blue-grey)"
    />
  )
}

export class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, value, strokeColor } = this.props

    return (
      <text
        x={x}
        y={y}
        dy={-12}
        fill={strokeColor}
        fontSize={12}
        textAnchor="middle"
      >
        {amountFormatter.format(
          parseFloat((value + Number.EPSILON) * 100).toFixed(2) / 100
        )}
      </text>
    )
  }
}

export function SlimYearsAxis({
  showYearsAxis,
  years,
  syncId,
  marginLeft,
  marginLeftExtra = 0,
  marginRightExtra = 0,
}) {
  const data = useMemo(
    () =>
      orderBy(
        years.map((year) => ({ year })),
        'year'
      ),
    [years]
  )

  const date = useLastImportDate()

  return (
    <div style={{ height: showYearsAxis ? 32 : 20, width: '100%' }}>
      <ResponsiveContainer>
        <ComposedChart
          className={
            showYearsAxis ? 'top-years-lines-with-numbers' : 'top-years-lines'
          }
          data={data}
          syncId={syncId}
          margin={{
            bottom: 0,
            top: 0,
            right: MARGIN_RIGHT + marginRightExtra,
            left: marginLeft
              ? marginLeft + marginLeftExtra
              : MARGIN_LEFT + marginLeftExtra,
          }}
        >
          <YAxis axisLine={false} tickLine={false} />
          <XAxis
            tickSize={13}
            fontSize={12}
            stroke="var(--color-light-blue-grey)"
            tickMargin={showYearsAxis ? undefined : 0}
            tick={showYearsAxis ? undefined : <LineTick />}
            axisLine={false}
            tickLine={showYearsAxis}
            xAxisId={'years'}
            orientation="top"
            // dataKey={(x) => x.year}
            dataKey={(x) =>
              x.year
                .toString()
                .replace(
                  date.last_year,
                  `${date.last_year} ${date.last_quarter}`
                )
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default function ProductsAreaChart({
  data,
  years,
  syncId,
  title,
  showTitle = true,
  marginLeft,
  showYearsAxis = false,
  showYearsTicks = true,
  showClusterName = false,
  cardBackground = false,
  titleFixed = false,
  country = null,
  widthTitle = null,
  group = null,
  cursor = true,
  clusterName = null,
  cluster = null,
  colorStrong = 'var(--color-primary-solid)',
  colorLight = 'var(--color-primary-light)',
  gradient = 'primary',
}) {
  const chartData = useMemo(() => {
    const missYears = difference(years, uniq(data.map((d) => d.year)))
    let filledData = data.concat(
      missYears.map((year) => ({
        year,
        num_projects: null,
        total_amount: !COUNTRIES_EXCEPTIONS_TREND.includes(country) ? 0 : null,
      }))
    )
    filledData = orderBy(filledData, 'year', 'asc')
    return filledData
  }, [data, years, country])

  const showDotsYears = useMemo(() => {
    const dots = []
    chartData.forEach((datum, i) => {
      let show = true
      if (i > 0 && chartData[i - 1].total_amount !== null) {
        show = false
      } else if (
        i < chartData.length - 1 &&
        chartData[i + 1].total_amount !== null
      ) {
        show = false
      }
      if (show) {
        dots.push(datum.year)
      }
    })
    return dots
  }, [chartData])

  const dataByYears = useMemo(() => keyBy(chartData, 'year'), [chartData])

  return (
    <div className="h-100 w-100 d-flex flex-column" style={{ zIndex: 2 }}>
      {showYearsTicks && (
        <SlimYearsAxis
          years={years}
          syncId={syncId}
          showYearsAxis={showYearsAxis}
        />
      )}
      <div
        className={classNames('flex-1 w-100 d-flex flex-column', {
          'asian-card': cardBackground && cursor,
          'asian-card-without-cursor': cardBackground && !cursor,
        })}
      >
        {title && showTitle && !titleFixed && (
          <div className="fw-350 text-dark-grey-basic mt-1 text-center">
            {title}
          </div>
        )}
        {title && showTitle && titleFixed && (
          <div
            style={{
              zIndex: 100,
              backgroundColor: 'var(--color-basic-white-100)',
              position: 'fixed',
              width: `calc(${widthTitle ? widthTitle : '100%'} - 6rem)`,
              maxWidth: 'inherit',
            }}
            className="fw-350 text-dark-grey-basic text-center"
          >
            {title}
          </div>
        )}
        {title && showTitle && country === 'LAO' && group === 'fcas' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Lao PDR has been classified as FCAS beginning Q4 2020. Portfolio
            information covers 2021 and onwards.
          </div>
        )}
        {title && showTitle && country === 'NIU' && group === 'sids' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Niue has been a part of SIDS beginning 2019. Portfolio information
            covers 2019 and onwards.
          </div>
        )}
        {title && showTitle && country === 'PAL' && group === 'fcas' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Palau has been classified as FCAS beginning 2023. Portfolio
            information covers 2023 and onwards.
          </div>
        )}
        <div className="flex-1 w-100">
          <ResponsiveContainer className={'pointer'}>
            <AreaChart
              margin={{
                right: MARGIN_RIGHT,
                left: marginLeft ? marginLeft : MARGIN_LEFT,
                bottom: 15,
                top: 40,
              }}
              data={chartData}
              syncId={syncId}
            >
              <defs>
                <linearGradient id={`color_${gradient}`} x2="0%" y2="100%">
                  <stop offset="13.63%" stopColor={colorStrong} />
                  <stop offset="86.37%" stopColor={colorLight} />
                </linearGradient>
              </defs>

              <XAxis
                stroke={colorStrong}
                fontWeight={400}
                fontSize={12}
                tickMargin={10}
                padding={0}
                height={0}
                tick={true}
                // tickFormatter={(v) => {
                //   const totalAmount = dataByYears[v].total_amount
                //   return totalAmount === null
                //     ? ''
                //     : amountFormatter.format(totalAmount)
                // }}
                axisLine={false}
                tickLine={false}
                dataKey="year"
              />
              <XAxis
                fontWeight={300}
                fontSize={12}
                tickMargin={8}
                stroke={colorStrong}
                tickFormatter={(v) => {
                  const numProjects = dataByYears[v].num_projects
                  return numProjects === null ? '' : numProjects
                }}
                axisLine={false}
                tickLine={false}
                xAxisId={'count'}
                dataKey="year"
              />

              <YAxis
                fontSize={12}
                stroke="var(--grey-3)"
                fontWeight={300}
                tickCount={2}
                axisLine={false}
                tickLine={false}
              >
                {showClusterName &&
                  clusterName &&
                  (clusterName === 'Direct Investment' ? (
                    <>
                      <Label
                        fontSize={14}
                        fontWeight={375}
                        value={'Direct'}
                        fill="var(--color-basic-dark-grey)"
                        dx={-46}
                        dy={-135}
                        position="bottom"
                      />
                      <Label
                        fontSize={14}
                        fontWeight={375}
                        value={'Investment'}
                        fill="var(--color-basic-dark-grey)"
                        dx={-46 + clusterName.length}
                        dy={-120}
                        position="bottom"
                      />
                    </>
                  ) : clusterName === 'Others' &&
                    cluster === 'fund_mnemonic' ? (
                    <>
                      <Label
                        fontSize={14}
                        fontWeight={375}
                        value={'Others /'}
                        fill="var(--color-basic-dark-grey)"
                        dx={-46 + clusterName.length}
                        dy={-135}
                        position="bottom"
                      />
                      <Label
                        fontSize={14}
                        fontWeight={375}
                        value={'Cofinancing'}
                        fill="var(--color-basic-dark-grey)"
                        dx={-35 + clusterName.length}
                        dy={-120}
                        position="bottom"
                      />
                    </>
                  ) : (
                    <Label
                      fontSize={14}
                      fontWeight={375}
                      value={clusterName}
                      fill="var(--color-basic-dark-grey)"
                      dx={
                        clusterName === 'Guarantee'
                          ? -30
                          : clusterName.length > 2
                          ? -50 + clusterName.length
                          : -55
                      }
                      dy={-135}
                      position="bottom"
                    />
                  ))}
                <Label
                  fill="var(--black-chart)"
                  fontWeight={300}
                  fontSize={12}
                  value={'Amount'}
                  strokeWidth={0.5}
                  dx={-40}
                  dy={-16}
                  position="center"
                />
                <Label
                  fontSize={12}
                  fontWeight={300}
                  value={'($ million)'}
                  strokeWidth={0.5}
                  fill="var(--black-chart)"
                  dy={0}
                  dx={-35}
                  position="center"
                />
                <Label
                  fontSize={12}
                  fontWeight={300}
                  fontFamily={'Ideal Sans'}
                  strokeWidth={0.5}
                  value={'Number of'}
                  fill="var(--black-chart)"
                  dy={0}
                  dx={-34}
                  position="bottom"
                />
                <Label
                  fontSize={12}
                  fontWeight={300}
                  fontFamily={'Ideal Sans'}
                  strokeWidth={0.5}
                  value={'products'}
                  fill="var(--black-chart)"
                  dy={14}
                  dx={-39}
                  position="bottom"
                />
              </YAxis>
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <Area
                isAnimationActive={true}
                type="monotone"
                label={<CustomizedLabel strokeColor={colorStrong} />}
                dataKey="total_amount"
                dot={(props) => {
                  if (showDotsYears.includes(props.payload.year)) {
                    return <Dot {...props} className="recharts-area-dot" />
                  }
                  return <Dot {...props} className="recharts-area-dot" />
                }}
                stroke={colorStrong}
                fill={`url(#color_${gradient})`}
                fillOpacity={0.2}
                strokeWidth={3}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

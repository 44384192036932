import { Route, Routes } from 'react-router-dom'
import SnapshotGroup from './SnaphostGroup'
import SnaphostGroupCountry from './SnaphostGroupCountry'
import SnaphostIndex from './SnaphostIndex'
import TrendGroup from './TrendGroup'
import TrendGroupCountry from './TrendGroupCountry'
import TrendIndex from './TrendIndex'

export default function PortfolioInformation() {
  return (
    <Routes>
      <Route path="trend" element={<TrendIndex />} />
      <Route path="trend/:group" element={<TrendGroup />} />
      <Route path="trend/:group/:country" element={<TrendGroupCountry />} />
      <Route path="snapshot" element={<SnaphostIndex />} />
      <Route path="snapshot/:group" element={<SnapshotGroup />} />
      <Route
        path="snapshot/:group/:country"
        element={<SnaphostGroupCountry />}
      />
    </Routes>
  )
}

import classNames from 'classnames'
import dayjs from 'dayjs'
import useModalTrigger from 'magik-react-hooks/useModalTrigger'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { useAuthUser } from 'use-eazy-auth'
import { useLastImportDate } from '../../LastImport'
import Tutorial from '../Tutorial'
import styles from './MenuLayout.module.css'
import arrowBlack from './assets/arrow-black.svg'
import arrowBlu from './assets/arrow-blu.svg'
import infoIcon from './assets/info.svg'
import { rj, useRunRj } from 'react-rocketjump'
import request from 'superagent'

const CheckPreviewState = rj({
  name: 'CheckPreviewState',
  effectCaller: 'configured',
  effect: (token) => () => {
    const r = request
      .get('/api/check-preview/')
      .set('Authorization', `Bearer ${token}`)
    return r.then((r) => r.body)
  },
})

export function Menu({ menu, actionsMenu }) {
  const { user } = useAuthUser()

  const [searchParams] = useSearchParams()

  const preview = searchParams.get('preview') ?? ''

  const [{ data: checkJob }] = useRunRj(CheckPreviewState)

  const navigate = useNavigate()

  return (
    <Modal
      onClosed={actionsMenu.onClosed}
      className={styles.Menu}
      isOpen={menu.isOpen}
      toggle={() => actionsMenu.toggle()}
      data-test="modal-topbar"
    >
      <ModalBody className={styles.MenuContent}>
        <div className={styles.CloseMenu} onClick={() => actionsMenu.toggle()}>
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              className={styles.BurgerCircle}
              rx="22.5"
              fill="#F9FAFE"
            />
            <path
              d="M18.4164 17.2717C18.2664 17.1268 18.0654 17.0466 17.8569 17.0484C17.6483 17.0502 17.4488 17.1339 17.3013 17.2814C17.1538 17.4289 17.0702 17.6284 17.0683 17.8369C17.0665 18.0455 17.1467 18.2464 17.2916 18.3965L21.8854 22.9902L17.2908 27.584C17.2148 27.6573 17.1542 27.7451 17.1126 27.8422C17.0709 27.9392 17.0489 28.0436 17.048 28.1492C17.0471 28.2548 17.0672 28.3596 17.1072 28.4573C17.1472 28.5551 17.2063 28.6439 17.281 28.7186C17.3556 28.7933 17.4445 28.8524 17.5422 28.8923C17.64 28.9323 17.7447 28.9525 17.8503 28.9516C17.956 28.9506 18.0603 28.9287 18.1574 28.887C18.2544 28.8453 18.3422 28.7847 18.4156 28.7087L23.0101 24.115L27.6039 28.7087C27.7539 28.8536 27.9548 28.9338 28.1634 28.932C28.372 28.9302 28.5715 28.8465 28.719 28.6991C28.8665 28.5516 28.9501 28.3521 28.9519 28.1435C28.9537 27.9349 28.8736 27.734 28.7287 27.584L24.1349 22.9902L28.7287 18.3965C28.8736 18.2464 28.9537 18.0455 28.9519 17.8369C28.9501 17.6284 28.8665 17.4289 28.719 17.2814C28.5715 17.1339 28.372 17.0502 28.1634 17.0484C27.9548 17.0466 27.7539 17.1268 27.6039 17.2717L23.0101 21.8654L18.4164 17.2709V17.2717Z"
              fill="#0D8ECA"
            />
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              rx="22.5"
              stroke="#0D8ECA"
            />
          </svg>
        </div>
        <div className={`row`}>
          <div className={`${styles.BlockHomePage} col-md-3 position-relative`}>
            <div>
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkBlack}
                to={'/?preview=' + preview}
                data-test="topbar-homepage"
              >
                Homepage{' '}
                <span className="ms-1">
                  <img src={arrowBlack} alt="Homepage" />
                </span>
              </Link>
            </div>
            <div className="pt-3">
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkBlack}
                to={'/about?preview=' + preview}
                data-test="topbar-about"
              >
                About
                <span className="ms-1">
                  <img src={arrowBlack} alt="About" />
                </span>
              </Link>
            </div>
          </div>
          <div className={`${styles.BlockPortfolioInformation} col-md-3`}>
            <div className={styles.LabelBlock}>Portfolio information</div>
            <div className="pt-2">
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkMenu}
                to={'/portfolio-distribution/trend/?preview=' + preview}
                data-test="topbar-portfolio"
              >
                Portfolio distribution{' '}
                <span className="ms-1">
                  <img src={arrowBlu} alt="Portfolio distribution" />
                </span>
              </Link>
            </div>
            <div className="pt-3">
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkMenu}
                to={'/project-performance-rating/trend?preview=' + preview}
                data-test="topbar-ppr"
              >
                Project Performance Rating{' '}
                <span className="ms-1">
                  <img src={arrowBlu} alt="Project Performance Rating" />
                </span>
              </Link>
            </div>
            <div className="pt-3">
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkMenu}
                to={
                  '/disbursment-and-contract-awarded/trend?preview=' + preview
                }
                data-test="topbar-disbursement"
              >
                Contract Award and Disbursement{' '}
                <span className="ms-1">
                  <img src={arrowBlu} alt="Contract Award and Disbursement" />
                </span>
              </Link>
            </div>
          </div>
          <div className={`${styles.CompletedProductsAndPipelines} col-md-3`}>
            <div className={styles.LabelBlock}></div>
            <div className="pt-4">
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkMenu}
                to={'/completed-products?preview=' + preview}
                data-test="topbar-completed"
              >
                Completed products
                <span className="ms-1">
                  <img src={arrowBlu} alt="Completed products" />
                </span>
              </Link>
            </div>
            {/* <div className="pt-3">
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkMenu}
                to="/indicative-pipelines"
              >
                Indicative pipeline
                <span className="ms-1">
                  <img src={arrowBlu} alt="Indicative pipeline" />
                </span>
              </Link>
            </div> */}
          </div>
          <div className={`${styles.BlockSearch} col-md-2`}>
            <div>
              <Link
                onClick={() => actionsMenu.toggle()}
                className={styles.LinkMenu}
                to={'/search?preview=' + preview}
                data-test="topbar-search"
              >
                Search
                <span className="ms-1">
                  <img src={arrowBlu} alt="Search" />
                </span>
              </Link>
            </div>
          </div>
          {/* <div className={`${styles.BlockAbout} col-md-1`}>
            
          </div> */}
        </div>
        <div className="row ps-5 pe-5">
          <div className={`${styles.BlockBorder} col-md-12 ps-5 pe-5`}></div>
        </div>
        <div className="row">
          <div className={`${styles.BlockImportData} col-md-2 pe-5`}>
            <div className="pt-5 w-100">
              {user?.is_tool_admin && (
                <Link
                  onClick={() => actionsMenu.toggle()}
                  className={styles.LinkBlack}
                  to="/import"
                  data-test="topbar-import"
                >
                  Import data
                  <span className="ms-1">
                    <img src={arrowBlack} alt="Import" />
                  </span>
                </Link>
              )}
            </div>
            <div className="pt-3 w-100">
              {user?.is_tool_admin && checkJob && checkJob.preview && (
                <div
                  onClick={() => {
                    actionsMenu.toggle()
                    const pathname = window.location.pathname
                    if(preview ===''){
                    navigate(pathname + '?preview=True')
                    } else {
                      navigate(pathname + '?preview=')
                    }
                    window.location.reload()
                  }}
                  className={classNames(styles.LinkBlack, 'pointer')}
                >
                  {preview ? 'Exit preview' : 'Show preview'}
                  <span className="ms-1">
                    <img src={arrowBlack} alt="Import" />
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={`${styles.BlockLogout} offset-md-8 col-md-2 pe-5`}>
            <div className="pt-5 w-100">
              <Link className={styles.LinkMenu} to="/logout">
                Logout
                <span className="ms-2">
                  <svg
                    width="31"
                    height="26"
                    viewBox="0 0 31 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1667 12.9998L25.6667 12.9998M25.6667 12.9998L19.8334 7.1665M25.6667 12.9998L19.8334 18.8332"
                      stroke="#0D8ECA"
                      className={styles.ArrowLogout}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2 6.36842V6C16.2 3.23858 13.9614 1 11.2 1H6C3.23858 1 1 3.23858 1 6V20C1 22.7614 3.23857 25 6 25H11.2C13.9614 25 16.2 22.7614 16.2 20V19.8"
                      stroke="#0D8ECA"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default function MenuLayout({
  children,
  title = 'Portfolio Information',
  className,
  classLeft = true,
  subtitle = null,
  filters = null,
  noDate = true,
  is100 = true,
  info = '',
  showLearnMorePpr = false,
}) {
  const [menu, actionsMenu] = useModalTrigger()
  const date = useLastImportDate()
  const [tutorial, actionsTutorial] = useModalTrigger()

  return (
    <div
      className={classNames('h-100 d-flex', {
        'w-100': is100,
      })}
    >
      <div className={classLeft ? styles.LeftSidebar : className}>
        <div className={styles.BurgerMenu} onClick={() => actionsMenu.open()}>
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              rx="22.5"
              className={styles.BurgerCircle}
              fill="#F9FAFE"
            />
            <path
              d="M15 29H31M15 17H31H15ZM15 23H31H15Z"
              stroke="#0D8ECA"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="45"
              height="45"
              rx="22.5"
              stroke="#0D8ECA"
            />
          </svg>
        </div>
        <Menu menu={menu} actionsMenu={actionsMenu} />
        <h3 className={styles.Title}>{title}</h3>
        <div className="d-flex">
          {subtitle && <h4 className={styles.Subtitle}>{subtitle}</h4>}
          {info !== '' && (
            <span
              className="pointer ms-4"
              onClick={() => actionsTutorial.open(info)}
              data-test={`info-${info}`}
            >
              <img alt="Info Tutorial" src={infoIcon} />
            </span>
          )}
        </div>
        {showLearnMorePpr && (
          <Link className={styles.LearnMore} to="/about?page=ppr">
            Learn more
          </Link>
        )}
        {!noDate && (
          <h5 className={styles.Date}>
            {date ? dayjs(date).format('MMM DD, YYYY') : ''}
          </h5>
        )}
        <div className={styles.Filters}>{filters}</div>
      </div>
      <div className={`${styles.Page} h-100`}>{children}</div>
      <Tutorial
        openSection={tutorial.value}
        tutorial={tutorial}
        actionsTutorial={actionsTutorial}
      />
    </div>
  )
}

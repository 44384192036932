import { Rectangle } from 'recharts'

const BAR_PADDING_Y = 4
const BAR_PADDING_Y_BOTTOM = 8
const BAR_PADDING_Y_TOP = 8
const BAR_PADDING_X = 4
const DEFAULT_BORDER_RADIUS = 8

export default function RoundedShapeBar({
  x,
  y,
  width,
  height,
  fill,
  isTopBar,
  isBottomBar,
  borderRadius = DEFAULT_BORDER_RADIUS,
}) {
  let bgRadius = [0, 0, 0, 0]
  if (isBottomBar) {
    bgRadius[2] = borderRadius
    bgRadius[3] = borderRadius
  }
  if (isTopBar) {
    bgRadius[0] = borderRadius
    bgRadius[1] = borderRadius
  }
  let paddingYTop = 0
  let paddingYBottom = 0
  if (isTopBar) {
    paddingYBottom = BAR_PADDING_Y
    paddingYTop = BAR_PADDING_Y_TOP
  }
  if (isBottomBar) {
    paddingYBottom = BAR_PADDING_Y_BOTTOM
  }
  if (!isBottomBar && !isTopBar) {
    paddingYTop = 0
    paddingYBottom = BAR_PADDING_Y
  }


  return (
    <g>
      <Rectangle
        radius={bgRadius}
        x={x}
        y={y}
        width={width}
        height={height}
        fill="var(--color-basic-lightblue-1)"
      />
      <Rectangle
        radius={[borderRadius, borderRadius, borderRadius, borderRadius]}
        x={x + BAR_PADDING_X}
        y={y + paddingYTop}
        width={Math.max(width - BAR_PADDING_X * 2, 0)}
        height={Math.max(height - (paddingYTop + paddingYBottom), 0)}
        fill={fill}
      />
    </g>
  )
}

import AutoTSMenuLayout from '../../components/AutoTSMenuLayout'

/**
 * @param {{
 *  children: React.ReactNode
 *  filters: React.ReactNode
 * }} props
 */
export default function LayoutDisbursmentContract({
  dataTest,
  children,
  ...props
}) {
  return (
    <AutoTSMenuLayout
      subtitle="Contract Award and Disbursement"
      breadcrumbLabel="Contract Award and Disbursement"
      info="disbursment"
      dataTest={dataTest}
      {...props}
    >
      {children}
    </AutoTSMenuLayout>
  )
}

import { difference, keyBy, orderBy, uniq } from 'lodash'
import { useMemo } from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  LabelList,
  Label,
  Rectangle,
} from 'recharts'
import classNames from 'classnames'
import RoundedShapeBar from '../RoundedShapeBar'
import { useLastImportDate } from '../../LastImport'

const MARGIN_LEFT = 20
const MARGIN_RIGHT = 0
const BAR_SIZE = 25
const LABEL_MARGIN_X = 8

function LineTick(props) {
  const { x, y } = props
  return (
    <line
      x1={x}
      y1={y - 20}
      x2={x}
      y2={y + 12}
      stroke="var(--color-light-blue-grey)"
    />
  )
}

export function SlimYearsAxis({ showYearsAxis, years, syncId }) {
  const data = useMemo(() => orderBy(years.map((year) => ({ year })),'year'), [years])
  const date = useLastImportDate()
  return (
    <div style={{ height: showYearsAxis ? 32 : 20, width: '100%' }}>
      <ResponsiveContainer>
        <BarChart
          className={
            showYearsAxis ? 'top-years-lines-with-numbers' : 'top-years-lines'
          }
          data={data}
          syncId={syncId}
          margin={{
            bottom: 0,
            top: 0,
            right: MARGIN_RIGHT,
            left: MARGIN_LEFT,
          }}
        >
          <YAxis axisLine={false} tickLine={false} />
          <XAxis
            tickSize={13}
            fontSize={12}
            stroke="var(--color-light-blue-grey)"
            tickMargin={showYearsAxis ? undefined : 0}
            tick={showYearsAxis ? undefined : <LineTick />}
            axisLine={false}
            tickLine={showYearsAxis}
            xAxisId={'years'}
            orientation="top"
            dataKey={(x) =>
              x.year
                .toString()
                .replace(
                  date.last_year,
                  `${date.last_year} ${date.last_quarter}`
                )
            }
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

// NOTE: In future we can wrap <RoundedShapeBar /> with another Component
// and memoize the calculation above ... but if no perf problem keep here lol
function calculateBarFlags(data, attr) {
  const dataAttributes = [
    'at_risk_percent',
    'for_attention_percent',
    'on_track_percent',
  ]
  const flat = dataAttributes.reduce((f, k) => {
    if (data[k]) {
      f.push(k)
    }
    return f
  }, [])
  const index = flat.indexOf(attr)
  let isTopBar = false
  let isBottomBar = false
  if (index === 0) {
    isBottomBar = true
  }
  if (index === flat.length - 1) {
    isTopBar = true
  }
  return { isTopBar, isBottomBar }
}

function BarLabel({ content, value, ...props }) {
  if (value > 0) {
    return <Label value={`${Math.round(value)}%`} {...props} />
  }
  return null
}

export default function BarChartStackedMulti({
  data,
  years,
  syncId,
  title,
  showTitle = true,
  showYearsAxis = false,
  showYearsTicks = true,
  cardBackground = false,
  group = null,
  country = null,
}) {
  const chartData = useMemo(() => {
    const missYears = difference(years, uniq(data.map((d) => d.year)))
    let filledData = data.concat(
      missYears.map((year) => ({
        year,
        at_risk_percent: null,
        for_attention_percent: null,
        on_track_percent: null,
      }))
    )
    filledData = orderBy(filledData, 'year', 'asc')
    return filledData
  }, [data, years])

  const dataByYears = useMemo(() => keyBy(chartData, 'year'), [chartData])

  const deBars = [
    {
      dataKey: 'at_risk_percent',
      label: 'At risk',
      fill: "url('#gradientRed')",
      fillLabel: 'var(--color-ppr-red-solid)',
    },
    {
      dataKey: 'for_attention_percent',
      label: 'For attention',
      fill: "url('#gradientYellow')",
      fillLabel: 'var(--color-ppr-yellow-solid)',
    },
    {
      dataKey: 'on_track_percent',
      label: 'On Track',
      fill: "url('#gradientGreen')",
      fillLabel: 'var(--color-ppr-green-solid)',
    },
  ]

  return (
    <div className="h-100 w-100 d-flex flex-column" style={{ zIndex: 2 }}>
      {showYearsTicks && (
        <SlimYearsAxis
          years={years}
          syncId={syncId}
          showYearsAxis={showYearsAxis}
        />
      )}
      <div
        className={classNames('flex-1 w-100 d-flex flex-column', {
          'asian-card': cardBackground,
        })}
      >
        {title && showTitle && (
          <div className="fw-350 text-dark-grey-basic text-center">{title}</div>
        )}
        {title && showTitle && country === 'LAO' && group === 'fcas' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Lao PDR has been classified as FCAS beginning Q4 2020. PPR information covers 2021 and onwards.
          </div>
        )}
        {title && showTitle && country === 'NIU' && group === 'sids' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Niue has been identified as SIDS beginning 2019. PPR information covers 2019 and onwards.
          </div>
        )}
        {title && showTitle && country === 'PAL' && group === 'fcas' && (
          <div className="fw-300 text-dark-grey-basic mt-1 text-center">
            Palau has been classified as FCAS beginning 2023. PPR information covers 2023 and onwards.
          </div>
        )}
        <div className="flex-1 w-100" data-test="chart-bar">
          <ResponsiveContainer className={'pointer'}>
            <BarChart
              margin={{
                right: MARGIN_RIGHT,
                left: MARGIN_LEFT,
                bottom: 20,
                top: 0,
              }}
              stackOffset="expand"
              barSize={BAR_SIZE}
              data={chartData}
              syncId={syncId}
            >
              <defs>
                <linearGradient
                  x1={0}
                  x2={'50%'}
                  y1={0}
                  y2={'100%'}
                  id="gradientGreen"
                >
                  <stop
                    offset="13.63%"
                    stopColor={'var(--color-ppr-green-solid)'}
                  />
                  <stop
                    offset="86.37%"
                    stopColor={'var(--color-ppr-green-light)'}
                  />
                </linearGradient>
                <linearGradient
                  x1={0}
                  x2={'50%'}
                  y1={0}
                  y2={'100%'}
                  id="gradientYellow"
                >
                  <stop
                    offset="13.63%"
                    stopColor={'var(--color-ppr-yellow-solid)'}
                  />
                  <stop
                    offset="86.37%"
                    stopColor={'var(--color-ppr-yellow-light)'}
                  />
                </linearGradient>
                <linearGradient
                  x1={0}
                  x2={'50%'}
                  y1={0}
                  y2={'100%'}
                  id="gradientRed"
                >
                  <stop
                    offset="13.63%"
                    stopColor={'var(--color-ppr-red-solid)'}
                  />
                  <stop
                    offset="86.37%"
                    stopColor={'var(--color-ppr-red-light)'}
                  />
                </linearGradient>
              </defs>
              <YAxis tickFormatter={() => ''} axisLine={false} tickLine={false}>
                <Label
                  fill="var(--color-ppr-solid-green)"
                  dy={20}
                  position={'insideTop'}
                  value={'On track'}
                />
                <Label
                  fill="var(--color-ppr-solid-yellow)"
                  position={'insideLeft'}
                  value={'For attention'}
                />
                <Label
                  dy={-20}
                  dx={-5}
                  fill="var(--color-ppr-solid-red)"
                  position={'insideBottom'}
                  value={'At risk'}
                />
                <Label
                  fontSize={12}
                  fontWeight={300}
                  fontFamily={'Ideal Sans'}
                  strokeWidth={0.5}
                  value={'Number of'}
                  fill="var(--black-chart)"
                  dy={0}
                  dx={0}
                  position="bottom"
                />
                <Label
                  fontSize={12}
                  fontWeight={300}
                  fontFamily={'Ideal Sans'}
                  strokeWidth={0.5}
                  value={'projects rated'}
                  fill="var(--black-chart)"
                  dy={15}
                  dx={7}
                  position="bottom"
                />
              </YAxis>
              <XAxis
                // stroke={colorStrong}
                fontWeight={400}
                fontSize={12}
                tickMargin={10}
                padding={0}
                height={0}
                tick={true}
                // tickFormatter={(v) => {
                //   const totalAmount = dataByYears[v].total_amount
                //   return totalAmount === null
                //     ? ''
                //     : amountFormatter.format(totalAmount)
                // }}
                axisLine={false}
                tickLine={false}
                dataKey="year"
              />
              <XAxis
                fontWeight={375}
                fontSize={12}
                tickMargin={8}
                // type='number'
                stroke={'#8F9091'}
                tickFormatter={(v) => {
                  return (
                    dataByYears[v].on_track +
                      dataByYears[v].for_attention +
                      dataByYears[v].at_risk || ''
                  )
                }}
                axisLine={false}
                tickLine={false}
                xAxisId={'count'}
                dataKey="year"
              />
              {deBars.map((deBar) => (
                <Bar
                  key={deBar.dataKey}
                  shape={(props) => {
                    return props.payload.at_risk === 0 &&
                      props.payload.for_attention === 0 &&
                      props.payload.on_track === 0 ? (
                      <Rectangle
                        radius={2}
                        x={props.x}
                        y={4}
                        width={props.width}
                        height={224}
                        fill="var(--color-basic-lightblue-1)"
                      />
                    ) : (
                      <RoundedShapeBar
                        {...props}
                        borderRadius={2}
                        {...calculateBarFlags(props.payload, deBar.dataKey)}
                      />
                    )
                  }}
                  dataKey={deBar.dataKey}
                  stackId="a"
                  name={deBar.dataKey}
                  fill={deBar.fill}
                >
                  <LabelList
                    dx={LABEL_MARGIN_X}
                    position={'right'}
                    content={({ index, ...props }) => {
                      return (
                        <BarLabel
                          {...props}
                          fill={deBar.fillLabel}
                          value={chartData[index][deBar.dataKey]}
                        />
                      )
                    }}
                  />
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import Select from '../../components/Select'
import { OPTIONS_GROUP_DC } from '../../consts'
import { useDomain } from '../../Domain'
import { keyBy, uniqBy } from 'lodash'
import { useMemo } from 'react'
import { useDCTrend } from '../../hooks/disbandcontract'
import LayoutDisbursmentContract from './LayoutDisbursmentContract'
import DisbusmentContractAreaChart from '../../components/DisbursmentContractAreaChart/DisbursmentContractAreaChart'
import { LimitedChipsContainer } from '../Search/Search'
import classNames from 'classnames'
import PreviewMode from '../../components/PreviewMode'

export default function DCTrendGroup() {
  const domain = useDomain()
  const navigate = useNavigate()
  const { group } = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const qsParams = {
    countries: searchParams.getAll('countries') ?? [],
    preview: searchParams.get('preview') ?? '',
  }

  const apiParams = useMemoCompare({
    ...qsParams,
    group,
  })

  const ALL_COUNTRIES_VALUES = ['']

  const uiCountries =
    qsParams.countries.length > 0 ? qsParams.countries : ALL_COUNTRIES_VALUES

  const [{ data, dataParams }] = useDCTrend(apiParams)

  const countriesByCode = useMemo(
    () => keyBy(domain.countries_data, 'code'),
    [domain.countries_data]
  )

  const countriesToUse = useMemo(() => {
    if (!data || data.length === 0) {
      return []
    }
    const distinctCountries = uniqBy(data[0].countries, 'project__country').map(
      (c) => ({
        value: c.project__country,
        label: countriesByCode[c.project__country].name,
      })
    )
    return distinctCountries
  }, [countriesByCode, data])

  const dataToUse = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].countries.filter((c) =>
        apiParams.countries.length > 0
          ? apiParams.countries.includes(c.project__country)
          : c
      )
    }
    return []
  }, [apiParams.countries, data])

  return (
    <LayoutDisbursmentContract
      dataTest="page-disbursement-trend-group"
      filters={
        <>
          <Select
            onChange={(e) => {
              navigate({
                pathname: `../trend/${e.value}`,
              })
            }}
            value={group}
            className={'mb-3'}
            label={'Classification'}
            options={OPTIONS_GROUP_DC}
            defaultValue={'fcas'}
          />
          <Select
            onChange={(e) => {
              if (
                dataParams.countries.length !== 0 &&
                e.filter((d) => d.value === '').length > 0
              ) {
                setSearchParams({
                  ...qsParams,
                  countries: [],
                })
              } else {
                setSearchParams({
                  ...qsParams,
                  countries: e.map((c) => c.value).filter(Boolean),
                })
              }
            }}
            id="Countries"
            className="mb-3"
            placeholder={'All countries'}
            components={
              dataParams &&
              dataParams.countries &&
              dataParams.countries.length > 0 && {
                MultiValue: LimitedChipsContainer,
              }
            }
            closeMenuOnSelect={false}
            isMulti
            value={uiCountries}
            isDisabled={!data}
            options={[{ value: '', label: 'All countries' }].concat(
              countriesToUse
            )}
            label={'Countries comparison'}
          />
        </>
      }
    >
      <div className="h-100 w-100 overflow-y-scroll d-flex flex-column ps-5 pe-5 pb-5">
        {data &&
          dataToUse &&
          dataToUse.map((country, i) => (
            <div
              key={country.project__country}
              className={classNames({
                'h-100': dataToUse.length > 2,
              })}
            >
              <div style={{ height: 300 }}>
                <DisbusmentContractAreaChart
                  title={countriesByCode[country.project__country]?.name}
                  showYearsAxis={i === 0}
                  showYearsTicks={true}
                  country={country.project__country}
                  group={data[0].group}
                  syncId={'asian'}
                  cursor={false}
                  cardBackground={true}
                  years={domain.years}
                  data={country.years}
                />
              </div>
            </div>
          ))}
      </div>
      {apiParams.preview && <PreviewMode />}
    </LayoutDisbursmentContract>
  )
}

import styles from './About.module.css'
import arrow from './assets/arrow-black.svg'
import green from './assets/green.svg'
import yellow from './assets/yellow.svg'
import red from './assets/red.svg'

export default function ProjectPerformanceRating() {
  return (
    <div className="row pt-5 mb-5" data-test="text-ppr">
      <div className="offset-md-1 col-md-10">
        <h2 className={styles.Title}>Project Performance Rating (PPR)</h2>
        <div className={styles.Subtitle}>
          The PPR is a risk and project management tool. The objective of the
          PPR is to identify risks and issues early in project implementation
          and take corrective actions to address them. The PPR is to be used in
          an effective and constructive manner to measure the progress of
          outputs and compliance with financial and safeguards covenants. The
          PPR should not be used as a tool to assess the performance of project
          teams or individual team members.
        </div>
        <div className={styles.BlockContent}>
          <div className={styles.MiniTitle}>five performance indicators</div>
          <div className={styles.Paragraph}>
            Projects are rated quarterly starting at loan effectiveness until
            they are financially closed based on the following five performance
            indicators. These indicators are weighted equally.
            <ul className="mt-4">
              <li className="mb-3">
                <span className="fw-350">Contract award</span>. The percentage
                of total actual contracts awarded to the total original or
                revised contract award projections as of a given period starting
                at loan effectiveness until projects are financially closed. The
                actual contract award values are derived from the financial
                system.
              </li>
              <li className="mb-3">
                <span className="fw-350">Disbursement</span>. The percentage of
                total actual disbursements to the total original or revised
                disbursement projections as of a given period at loan
                effectiveness until projects are financially closed.
                Disbursement data will be automatically sourced from the
                financial system.
              </li>
              <li className="mb-3">
                <span className="fw-350">Financial management (FM)</span>. The
                FM compliance of each EA in a project is assessed based on three
                elements: (i) audited project financial statements (APFS), (ii)
                audited entity financial statements (AEFS), and (iii) time-bound
                financial covenants. The timeliness of submission of audited
                financial statements, the nature of the audit opinion, the
                additional opinion on the use of loan proceeds, availability and
                contents of the management letter, and compliance with
                time-bound financial covenants are assessed based on data
                uploaded in the eOperations. The aggregated FM score will be
                computed per EA based on the scores of individual elements and
                converted into a percentage. The total FM rating will be an
                average of the scores of all EAs for the project. This will be
                automatically assessed quarterly based on inputs into the
                eOperations.
              </li>
              <li className="mb-3">
                <span className="fw-350">Output</span>. Output progress tracking
                is based on the achievement of output indicator targets defined
                in the project’s DMF. The project team rates each output
                indicator based on its assessment of whether progress towards
                achieving the target is on track (green), for attention (amber),
                or at risk (red). The values assigned to each output rating
                (green = 1 point, amber = 0.7 points, and red = 0 points) are
                multiplied by the assigned weights. The aggregated ratings
                result in the overall output-based indicator score and will be
                converted into a percentage. The output indicator rating should
                be updated at least once in a calendar year reflecting the
                status/progress of the project.
              </li>
              <li className="mb-3">
                <span className="fw-350">Safeguards</span>. This indicator
                tracks compliance with safeguards requirements. The overall
                composite safeguards indicator includes eight tracking
                indicators to address compliance in the most critical safeguards
                areas linked to: (i) meeting safeguard requirements prior to
                contract awards for civil works; (ii) obtaining required
                safeguards-related clearances and permits as well as meeting
                pertinent social safeguards requirements prior to site access
                and/or start of civil works; (iii) identifying any high-risk
                grievance issues in safeguards implementation; (iv) identifying
                any other important safeguards non-compliance issues; and (v)
                submitting safeguards monitoring reports. Each tracking
                indicator will assess whether a project is on track (green), for
                attention (amber), or at risk (red) based on a response provided
                to a binary question. There are additional response options for
                cases with incomplete information and cases which are not
                applicable. In cases when PPR applies but the project is not
                categorized for safeguards purposes, the safeguards tab will be
                disabled. All eight safeguards indicators are required to be
                updated at least biannually; however, tracking indicators will
                remain accessible for update at any point in time.
              </li>
            </ul>
          </div>
          <div className={styles.MiniTitle}>
            Exceptions and Special Guidance
          </div>
          <div className={styles.Paragraph}>
            The PPR applies to all ADB-administered sovereign projects with some
            exceptions and special guidance (the PPR does not apply to
            nonsovereign projects). The following provisions will apply:
          </div>
          <div className={styles.Paragraph}>
            i) Modalities and funds that the PPR does not apply to:
            <ul className="mt-4">
              <li className="mb-3">
                <span className="fw-350">
                  Asia Pacific Disaster Response Fund (APDRF) projects
                </span>
              </li>
              <li className="mb-3">
                <span className="fw-350">Guarantee</span>
              </li>
              <li className="mb-3">
                <span className="fw-350">Policy-based lending</span>. The PPR
                does not apply to policy-based loans which include contingent
                disaster financing (CDF), Countercyclical Support Facility (CSF)
                and COVID-19 Pandemic Response Option (CPRO).
              </li>
              <li className="mb-3">
                <span className="fw-350">Technical assistance</span>
              </li>
              <li className="mb-3">
                <span className="fw-350">
                  Project readiness financing (PRF)
                </span>
              </li>
              <li className="mb-3">
                <span className="fw-350">
                  Small expenditure financing facility (SEEF)
                </span>
              </li>
            </ul>
          </div>
          <div className={styles.Paragraph}>
            ii) Modalities and special funds that the PPR applies to but needs
            specific guidance:
            <ul className="mt-4">
              <li className="mb-3">
                <span className="fw-350">Additional financing</span>. One PPR
                applies to the entire project lending including additional
                financing.
              </li>
              <li className="mb-3">
                <span className="fw-350">
                  Financial intermediation loan (FIL)
                </span>
                . One PPR applies to the entire FIL, which has one DMF
                describing both the FIL and project (investment) component/s.
                Since contract awards are not directly managed by ADB, only four
                indicators apply to FILs: disbursements, financial management,
                outputs, and safeguards. The project rating is the simple
                average of the four indicators’ ratings. Disbursements used for
                the PPR are disbursements from ADB or cofinanciers to the
                intermediaries. For financial management, an audited Report on
                Utilization of Loan Proceeds will be submitted instead of an
                APFS; AEFS and financial covenants apply. In case an FIL and a
                project loan are combined under one project, one PPR applies to
                the entire project and the contract award indicator applies to
                the project loan only.
              </li>
              <li className="mb-3">
                <span className="fw-350">
                  Multitranche financing facility (MFF)
                </span>
                . The PPR applies to each tranche, but not to the facility. For
                an MFF supporting a large stand-alone project, the output
                indicator for each tranche will use the facility DMF for
                assessment since separate DMF is not required for the tranches.
              </li>
              <li className="mb-3">
                <span className="fw-350">Results-based lending (RBL)</span>.
                Since contract awards are not managed by ADB, only four
                indicators apply to RBL: disbursements, financial management,
                outputs, and safeguards. The project rating is the simple
                average of the four indicators’ ratings.
              </li>
              <li className="mb-3">
                <span className="fw-350">Sector development program</span>. One
                PPR applies to the entire sector development program, which has
                one DMF describing both the policy-based (actions/reforms) and
                project (investment) components. The policy-based component is
                released in tranches against agreed conditions (if applicable)
                and completed policy actions, whereas the investment project
                component is implemented through contracts. The contract award
                indicator only applies to the investment component. The other
                four indicators apply to the entire sector development program:
                disbursement, financial management, outputs, and safeguards.
              </li>
              <li className="mb-3">
                <span className="fw-350">
                  ADB-financed project administered by an external agency.
                </span>{' '}
                For a project administered by an external agency such as United
                Nations or non-government agency and in case contract awards are
                not managed by ADB, only four indicators apply: disbursements,
                financial management, outputs, and safeguards. The project
                rating is the simple average of the four indicators’ ratings.{' '}
              </li>
            </ul>
          </div>
          <div className={styles.Paragraph}>
            iii) Modalities that the PPR applies to as per the general
            guidelines:
            <ul className="mt-4">
              <li className="mb-3 fw-350">
                Emergency assistance loan except APDRF
              </li>
              <li className="mb-3 fw-350">Project loan</li>
              <li className="mb-3 fw-350">Sector loan</li>
            </ul>
          </div>
          <div className={styles.MiniTitle}>Rating</div>
          <div className={styles.Paragraph}>
            The PPR will use a three-level traffic light applied to each
            indicator, and to the aggregation of the individual indicators into
            a single project rating.
          </div>
          <div className={styles.Paragraph}>
            <div className={styles.Rating}>
              <img src={green} alt="green" />
              <div className={styles.BlockRating}>
                <div className={styles.OnTrack}>on track</div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">
                    Contract award and disbursement rating:
                  </span>{' '}
                  The S-curve for either actual contract award or disbursement
                  is {'>'}90% of original projection.
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Financial management rating</span>:{' '}
                  {'>'}75%
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Output rating:</span>. ≥85%
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Safeguards rating</span>. All eight
                  safeguards tracking indicators should be on track (green).
                </div>
              </div>
            </div>
          </div>
          <div className={styles.Paragraph}>
            <div className={styles.Rating}>
              <img src={yellow} alt="Yellow" />
              <div className={styles.BlockRating}>
                <div className={styles.ForAttention}>for attention</div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">
                    Contract award and disbursement rating:
                  </span>{' '}
                  The S-curve for either actual contract award or disbursement
                  is {'≥'}75% to {'≤'}90% of original projection.
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Financial management rating</span>:{' '}
                  {'>'}50% to ≤75%
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Output rating:</span>. ≥70% to {'<'}
                  85%
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Safeguards rating</span>. No
                  safeguards tracking indicator should be at risk (red) and one
                  or more safeguards tracking data is for attention (amber).
                </div>
              </div>
            </div>
          </div>
          <div className={styles.Paragraph}>
            <div className={styles.Rating}>
              <img src={red} alt="Red" />
              <div className={styles.BlockRating}>
                <div className={styles.AtRisk}>AT RISK</div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">
                    Contract award and disbursement rating:
                  </span>{' '}
                  The S-curve for either actual contract award or disbursement
                  is {'<'}75% of original projection.
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Financial management rating</span>: ≤
                  50%
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Output rating:</span>. {'<'}70%
                </div>
                <div className={styles.Paragraph}>
                  <span className="fw-350">Safeguards rating</span>. One or more
                  safeguards tracking indicator is at risk (red).
                </div>
              </div>
            </div>
          </div>
          <div className={styles.MiniTitle}>Project rating</div>
          <div className={styles.Paragraph}>
            The five indicator ratings will be aggregated into an overall
            project rating by generating an average rating score for the
            project. The following values will be assigned to each indicator
            rating: on track (Green) = 1 point, for attention (Amber) = 0.7
            points and at risk (Red) = 0 points. The assigned values for each of
            the five indicators are summed up and divided by 5 to produce an
            overall project rating score between 0 and 1. Applying the
            percentage thresholds, a project with a total rating score ‘greater
            than or equal to 0.90’ is on-track (Green); a project with a total
            rating score of ‘less than 0.90 and greater than or equal to 0.65’
            is for attention (Amber); and a project with a total rating score of
            ‘less than 0.65’ is at risk (Red).
          </div>
          <div className={styles.MiniTitle}>External links</div>
          <div className={styles.Paragraph}>
            <a
              rel="noreferrer"
              target={'_blank'}
              className={styles.ExternalLink}
              href="https://lnadbg1.adb.org/cos0003p.nsf/f47286b4d1eb6e9b482569e500308fb9/9e729fd15174640448257b0100294b6b!OpenDocument"
            >
              Project Administration Instruction (PAI) No. 5.07
              <span className="ms-5">
                <img src={arrow} alt="Indicative pipeline" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

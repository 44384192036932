import { rj, SUCCESS, useRunRj } from 'react-rocketjump'
import { forkJoin } from 'rxjs'
import api from '../api'

const PPRState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[0]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'PPR',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/ppr/project-performance-trend', params),
  })

const PPRSnapshotState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[1]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'PPRSnapshot',
    effectCaller: 'configured',
    effect:
      (t) =>
      (year, params = {}) =>
        api.auth(t).get(`/api/ppr/project-performance/${year}`, params),
  })

const PPRTrendIssuesState = rj().effect({
  name: 'PPRTrendIssuesState',
  effectCaller: 'configured',
  effect:
    (t) =>
    (params = {}) =>
      api
        .auth(t)
        .get(`/api/ppr/project-performance-trend-country-issues`, params),
})

const PPRSnapshotIssuesState = rj().effect({
  name: 'PPRSnapshotIssuesState',
  effectCaller: 'configured',
  effect:
    (t) =>
    (year, params = {}) =>
      api
        .auth(t)
        .get(`/api/ppr/project-performance-country-issues/${year}`, params),
})

const PPRAndIssuesState = rj().effect({
  name: 'PPRAndIssues',
  effectCaller: 'configured',
  effect:
    (t) =>
    (params = {}) =>
      forkJoin({
        ppr: api.auth(t).get('/api/ppr/project-performance-trend', params),
        issues: api
          .auth(t)
          .get(`/api/ppr/project-performance-trend-country-issues`, params),
      }),
})

const PPRSnapshotAndIssuesState = rj().effect({
  name: 'PPRSnapshotAndIssues',
  effectCaller: 'configured',
  effect:
    (t) =>
    (year, params = {}) =>
      forkJoin({
        ppr: api.auth(t).get(`/api/ppr/project-performance/${year}`, params),
        issues: api
          .auth(t)
          .get(`/api/ppr/project-performance-country-issues/${year}`, params),
      }),
})

export function usePPR(params) {
  return useRunRj(PPRState, [params], false)
}

export function usePPRSnapshot(year, params) {
  return useRunRj(PPRSnapshotState, [year, params], false)
}

export function usePPRTrendIssues(params) {
  return useRunRj(PPRTrendIssuesState, [params], false)
}

export function usePPRSnapshotIssues(year, params) {
  return useRunRj(PPRSnapshotIssuesState, [year, params], false)
}

export function usePPRAndIssues(params) {
  return useRunRj(PPRAndIssuesState, [params], false)
}

export function usePPRSnapshotAndIssues(year, params) {
  return useRunRj(PPRSnapshotAndIssuesState, [year, params], false)
}

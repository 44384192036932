import { useEffect } from 'react'
import { useAuthActions } from 'use-eazy-auth'

export function Logout() {
  const { logout } = useAuthActions()

  useEffect(() => {
    logout()
  }, [logout])

  return null
}

import classNames from 'classnames'
import { extent } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { useMemo } from 'react'
import BarDCPercent from '../BarDCPercent'
import styles from './CardDCSnapshot.module.css'

export const amountFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
})

export default function CardDCSnapshot({
  singleData,
  color1 = 'var(--color-primary-solid)',
  color2 = 'var(--color-primary-light)',
  title,
  className,
  cursor = true,
  titleUpp = false,
  allImports
}) {
  const domainTotalAmount = useMemo(() => {
    if (allImports) {
      return extent(allImports)
    }
    return []
  }, [allImports])

  const scaleCircle = useMemo(() => {
    if (domainTotalAmount) {
      return scaleLinear()
        .domain([domainTotalAmount[0], domainTotalAmount[1]])
        .range([20, 100])
    }
  }, [domainTotalAmount])

  const disbursmentPercent = useMemo(() => {
    return Math.round(
      (singleData.total_disbursment / singleData.total_amount) * 100
    )
  }, [singleData])

  const awardedPercent = useMemo(() => {
    return Math.round(
      (singleData.total_approved / singleData.total_amount) * 100
    )
  }, [singleData])

  return (
    <div
      style={{ overflowX: 'hidden', overflowY: 'auto' }}
      className={classNames(
        `${className} text-center h-100 asian-card px-3 me-4`,
        {
          'asian-card': cursor,
          'asian-card-without-cursor': !cursor,
        }
      )}
      key={singleData.group}
    >
      <div className={titleUpp ? styles.TitleUppercase : styles.Title}>
        {title}
      </div>
      <div className={styles.ContainerAmount}>
        <div className={styles.Subtitle}>Amount in $ million</div>
        <div className={styles.LabelAmount}>Approved amount</div>

        <div className={styles.ValueAmount}>
          <span className={styles.Unit}>$</span>
          {amountFormatter.format(singleData.total_amount)}{' '}
        </div>
        <svg
          className={`mt-2`}
          width={'100%'}
          height={200}
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="gradient">
              <stop offset="13.63%" stopColor={color1} />
              <stop offset="86.37%" stopColor={color2} />
            </linearGradient>
          </defs>
          <circle
            className={`${styles.Circle} mt-2`}
            fill="url('#gradient')"
            cx={'50%'}
            cy={'50%'}
            r={scaleCircle(singleData.total_amount)}
          ></circle>
        </svg>
        <div className={styles.LabelAmount}>Cumulative disbursement</div>
        <div className={styles.ValueAmount}>
          <span className={styles.Unit}>$</span>
          {amountFormatter.format(singleData.total_disbursment)}{' '}
        </div>
        <BarDCPercent
          color={'linear-gradient(119.02deg, #68C5EA 13.63%, #ABDDF1 86.37%)'}
          percent={disbursmentPercent}
          colorText={'var(--color-sectors-solid-ene)'}
          className={'ms-2'}
        />
        <div className={styles.LabelAmount}>Cumulative contract award</div>
        <div className={styles.ValueAmount}>
          <span className={styles.Unit}>$</span>
          {amountFormatter.format(singleData.total_approved)}{' '}
        </div>
        <BarDCPercent
          className={'ms-2'}
          colorText={'var(--color-sectors-solid-psm)'}
          color={'linear-gradient(119.02deg, #055A82 13.63%, #7698A8 86.37%)'}
          percent={awardedPercent}
        />
        <hr className="mt-1 mx-3" style={{ color: '#E2EDF6', opacity: 1 }} />
        <div>
          <div className={styles.LabelAmount}>Number of products</div>
          <div className={styles.ValueAmount}>{singleData.num_projects}</div>
        </div>
      </div>
    </div>
  )
}

import classNames from 'classnames'
import { createContext, useCallback, useContext, useMemo } from 'react'

export function SortControl({ field }) {
  const { getDirection, handleSortChange } = useContext(SortContext)
  const direction = getDirection(field)

  return (
    <div className="ms-1 text-t-dark-grey" style={{ whiteSpace: 'nowrap' }}>
      <span
        onClick={() => handleSortChange(field)}
        className={classNames('bi ms-1 bi-sort-down pointer', {
          'opacity-05': !direction,
        })}
      >
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.73009 11.79C10.0201 12.07 10.4801 12.07 10.7701 11.79L13.5201 9.14C13.5944 9.07256 13.6545 8.99089 13.6967 8.89982C13.7389 8.80875 13.7624 8.71013 13.7658 8.60982C13.7692 8.5095 13.7524 8.40952 13.7164 8.31581C13.6805 8.2221 13.6261 8.13656 13.5564 8.06426C13.4868 7.99196 13.4034 7.93437 13.3111 7.8949C13.2188 7.85543 13.1195 7.83489 13.0192 7.8345C12.9188 7.8341 12.8194 7.85385 12.7268 7.89259C12.6342 7.93132 12.5503 7.98825 12.4801 8.06L11.0001 9.486V0.75C11.0001 0.551088 10.9211 0.360322 10.7804 0.21967C10.6398 0.0790175 10.449 0 10.2501 0C10.0512 0 9.86041 0.0790175 9.71976 0.21967C9.57911 0.360322 9.50009 0.551088 9.50009 0.75V9.486L8.02009 8.06C7.9499 7.98825 7.86602 7.93132 7.77342 7.89259C7.68082 7.85385 7.58139 7.8341 7.48102 7.8345C7.38064 7.83489 7.28137 7.85543 7.18908 7.8949C7.09679 7.93437 7.01337 7.99196 6.94374 8.06426C6.87412 8.13656 6.81972 8.2221 6.78376 8.31581C6.7478 8.40952 6.73102 8.5095 6.73441 8.60982C6.7378 8.71013 6.76129 8.80875 6.80349 8.89982C6.84569 8.99089 6.90575 9.07256 6.98009 9.14L9.73009 11.79ZM4.28009 0.22C4.13947 0.0795496 3.94884 0.000659943 3.75009 0.000659943C3.55134 0.000659943 3.36072 0.0795496 3.22009 0.22L0.470091 2.97C0.337611 3.11217 0.265487 3.30022 0.268916 3.49452C0.272344 3.68882 0.351056 3.87421 0.488469 4.01162C0.625882 4.14903 0.811267 4.22775 1.00557 4.23117C1.19987 4.2346 1.38792 4.16248 1.53009 4.03L3.00009 2.56V11.25C3.00009 11.4489 3.07911 11.6397 3.21976 11.7803C3.36041 11.921 3.55118 12 3.75009 12C3.949 12 4.13977 11.921 4.28042 11.7803C4.42107 11.6397 4.50009 11.4489 4.50009 11.25V2.56L5.97009 4.03C6.11227 4.16248 6.30031 4.2346 6.49461 4.23117C6.68891 4.22775 6.8743 4.14903 7.01171 4.01162C7.14913 3.87421 7.22784 3.68882 7.23127 3.49452C7.23469 3.30022 7.16257 3.11217 7.03009 2.97L4.28009 0.22Z"
            fill="#1C1C1C"
          />
        </svg>
      </span>
    </div>
  )
}

function getSortData(sort) {
  if (!sort) {
    return {
      direction: null,
      sortedField: null,
    }
  }
  const isDown = sort.indexOf('-') === 0
  const sortedField = isDown ? sort.slice(1) : sort
  return {
    sortedField,
    direction: isDown ? 'down' : 'up',
  }
}

function useSorter(value, onSortChange) {
  const getDirection = useCallback(
    (field) => {
      const { direction, sortedField } = getSortData(value)
      if (sortedField === field) {
        return direction
      }
      return null
    },
    [value]
  )

  const handleSortChange = useCallback(
    (field) => {
      let nextSort = ''
      const { direction, sortedField } = getSortData(value)
      if (sortedField !== field) {
        nextSort = `-${field}`
      } else if (direction === 'down') {
        nextSort = field
      }
      onSortChange(nextSort)
    },
    [onSortChange, value]
  )

  return useMemo(
    () => ({
      getDirection,
      handleSortChange,
    }),
    [getDirection, handleSortChange]
  )
}

const SortContext = createContext(null)

export default function Sorter({ value, onSortChange, children }) {
  return (
    <SortContext.Provider value={useSorter(value, onSortChange)}>
      {children}
    </SortContext.Provider>
  )
}

import { rj, SUCCESS, useRunRj } from 'react-rocketjump'
import api from '../api'

const DisbursmentAndContractState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[0]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'DisbursmentAndContractState',
    effectCaller: 'configured',
    effect:
      (t) =>
      (params = {}) =>
        api.auth(t).get('/api/products/disbursment-and-contract', params),
  })

const DisbursmentAndContractSnapshotState = rj()
  .combineReducers({
    params: (state = null, action) => {
      if (action.type === SUCCESS) {
        return action.payload.params[1]
      }
      return state
    },
  })
  .computed({
    data: 'getData',
    loading: 'isLoading',
    error: 'getError',
    dataParams: (s) => s.params,
  })
  .effect({
    name: 'DisbursmentAndContractSnapshotState',
    effectCaller: 'configured',
    effect:
      (t) =>
      (year, params = {}) =>
        api.auth(t).get(`/api/products/disbursment-and-contract-snapshot/${year}`, params),
  })


export function useDCTrend(params) {
  return useRunRj(DisbursmentAndContractState, [params], false)
}

export function useDCSnapshot(year, params) {
  return useRunRj(DisbursmentAndContractSnapshotState, [year, params], false)
}

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap'
import styles from './Tutorial.module.css'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import PortfolioTutorial from './components/PortfolioTutorial'
import PprTutorial from './components/PprTutorial'
import DisbursementTutorial from './components/DisbursmentTutorial'
import CompletedTutorial from './components/CompletedTutorial'
import IndicativeTutorial from './components/IndicativeTutorial'
import SearchTutorial from './components/SearchTutorial'

export default function Tutorial({ tutorial, actionsTutorial, openSection }) {
  const [open, setOpen] = useState('portfolio-distribution')
  const [openPage, setOpenPage] = useState('portfolio-distribution-1')
  const toggle = (id) => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
      setOpenPage(`${id}-1`)
    }
  }

  useEffect(() => {
    if (openSection) {
      setOpen(openSection)
      setOpenPage(`${openSection}-1`)
    }
  }, [openSection])

  return (
    <Modal
      onClosed={actionsTutorial.onClosed}
      className={styles.Tutorial}
      isOpen={tutorial.isOpen}
      toggle={() => actionsTutorial.toggle()}
      data-test="modal-tutorial"
    >
      <ModalHeader className={styles.ModalHeader}>
        <div>
          HOW TO <span className="fw-350">use the tool</span>
        </div>
        <div className={styles.Close} onClick={() => actionsTutorial.toggle()}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.41626 0.271633C1.26624 0.126735 1.0653 0.0465575 0.856739 0.0483699C0.648173 0.0501823 0.448663 0.13384 0.30118 0.281323C0.153696 0.428807 0.0700386 0.628317 0.0682262 0.836882C0.0664139 1.04545 0.146591 1.24638 0.29149 1.39641L4.88524 5.99016L0.290694 10.5839C0.21472 10.6573 0.154121 10.7451 0.112432 10.8421C0.0707427 10.9392 0.0487994 11.0435 0.0478816 11.1492C0.0469638 11.2548 0.06709 11.3595 0.107086 11.4573C0.147082 11.555 0.206147 11.6439 0.280835 11.7185C0.355522 11.7932 0.444336 11.8523 0.542095 11.8923C0.639853 11.9323 0.744598 11.9524 0.850218 11.9515C0.955838 11.9506 1.06022 11.9286 1.15727 11.8869C1.25431 11.8453 1.34209 11.7847 1.41547 11.7087L6.01001 7.11493L10.6038 11.7087C10.7538 11.8536 10.9547 11.9338 11.1633 11.9319C11.3719 11.9301 11.5714 11.8465 11.7188 11.699C11.8663 11.5515 11.95 11.352 11.9518 11.1434C11.9536 10.9349 11.8734 10.7339 11.7285 10.5839L7.13478 5.99016L11.7285 1.39641C11.8734 1.24638 11.9536 1.04545 11.9518 0.836882C11.95 0.628317 11.8663 0.428807 11.7188 0.281323C11.5714 0.13384 11.3719 0.0501823 11.1633 0.0483699C10.9547 0.0465575 10.7538 0.126735 10.6038 0.271633L6.01001 4.86538L1.41626 0.270838V0.271633Z"
              fill="#0D8ECA"
            />
          </svg>
        </div>
      </ModalHeader>
      <ModalBody className="h-100 pb-0">
        <div className="row h-100">
          <div
            className={`${styles.ContainerAccordion} col-md-4 ps-0 pe-0 bg-white`}
          >
            <Accordion className="ps-3 pe-3 pt-4" open={open} toggle={toggle}>
              <AccordionItem className={styles.AccordionItem}>
                <AccordionHeader
                  className={styles.AccordionHeader}
                  targetId="portfolio-distribution"
                  data-test="tutorial-portfolio"
                >
                  Portfolio distribution
                </AccordionHeader>
                <AccordionBody
                  className={styles.AccordionBody}
                  accordionId="portfolio-distribution"
                >
                  <div
                    onClick={() => setOpenPage('portfolio-distribution-1')}
                    className={classNames('pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'portfolio-distribution-1',
                    })}
                    data-test="subitem-portfolio-FCAS-SIDS"
                  >
                    FCAS and SIDS Portfolio
                  </div>
                  <div
                    onClick={() => setOpenPage('portfolio-distribution-2')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'portfolio-distribution-2',
                    })}
                    data-test="subitem-portfolio-trend"
                  >
                    Trend and Snapshot
                  </div>
                  <div
                    onClick={() => setOpenPage('portfolio-distribution-3')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'portfolio-distribution-3',
                    })}
                    data-test="subitem-portfolio-number-products"
                  >
                    Portfolio amount and number of products{' '}
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className={styles.AccordionItem}>
                <AccordionHeader
                  className={styles.AccordionHeader}
                  targetId="ppr"
                  data-test="tutorial-ppr"
                >
                  Project Performance Rating
                </AccordionHeader>
                <AccordionBody
                  className={styles.AccordionBody}
                  accordionId="ppr"
                >
                  <div
                    onClick={() => setOpenPage('ppr-1')}
                    className={classNames('pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'ppr-1',
                    })}
                    data-test="subitem-ppr-FCAS-SIDS"
                  >
                    FCAS and SIDS Project Performance Ratings
                  </div>
                  <div
                    onClick={() => setOpenPage('ppr-2')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'ppr-2',
                    })}
                    data-test="subitem-ppr-trend"
                  >
                    Trend and Snapshot
                  </div>
                  <div
                    onClick={() => setOpenPage('ppr-3')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'ppr-3',
                    })}
                    data-test="subitem-ppr-performance"
                  >
                    Project Performance Rating by Country
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className={styles.AccordionItem}>
                <AccordionHeader
                  className={styles.AccordionHeader}
                  targetId="disbursment"
                  data-test="tutorial-disbursement"
                >
                  Contract Award and Disbursement
                </AccordionHeader>
                <AccordionBody
                  className={styles.AccordionBody}
                  accordionId="disbursment"
                >
                  <div
                    onClick={() => setOpenPage('disbursment-1')}
                    className={classNames('pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'disbursment-1',
                    })}
                    data-test="subitem-disbursement-FCAS-SIDS"
                  >
                    FCAS and SIDS Contract Award and Disbursement
                  </div>
                  <div
                    onClick={() => setOpenPage('disbursment-2')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'disbursment-2',
                    })}
                    data-test="subitem-disbursement-trend"
                  >
                    Trend and Snapshot
                  </div>
                  <div
                    onClick={() => setOpenPage('disbursment-3')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'disbursment-3',
                    })}
                    data-test="subitem-disbursement-contract"
                  >
                    Contract Award and Disbursement
                  </div>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className={styles.AccordionItem}>
                <AccordionHeader
                  className={styles.AccordionHeader}
                  targetId="completed"
                  data-test="tutorial-completed"
                >
                  Completed products
                </AccordionHeader>
                <AccordionBody
                  className={styles.AccordionBody}
                  accordionId="completed"
                >
                  <div
                    onClick={() => setOpenPage('completed-1')}
                    className={classNames('pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'completed-1',
                    })}
                    data-test="subitem-completed-timeline"
                  >
                    Timeline
                  </div>
                  <div
                    onClick={() => setOpenPage('completed-2')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'completed-2',
                    })}
                    data-test="subitem-completed-project"
                  >
                    Project details
                  </div>
                </AccordionBody>
              </AccordionItem>
              {/* <AccordionItem className={styles.AccordionItem}>
                <AccordionHeader
                  className={styles.AccordionHeader}
                  targetId="indicative"
                >
                  Indicative pipeline
                </AccordionHeader>
                <AccordionBody
                  className={styles.AccordionBody}
                  accordionId="indicative"
                >
                  <div
                    onClick={() => setOpenPage('indicative-1')}
                    className={classNames('pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'indicative-1',
                    })}
                  >
                    Timeline
                  </div>
                </AccordionBody>
              </AccordionItem> */}
              <AccordionItem className={styles.AccordionItem}>
                <AccordionHeader
                  className={styles.AccordionHeader}
                  targetId="search"
                  data-test="tutorial-search"
                >
                  Search
                </AccordionHeader>
                <AccordionBody
                  className={styles.AccordionBody}
                  accordionId="search"
                >
                  <div
                    onClick={() => setOpenPage('search-1')}
                    className={classNames('pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'search-1',
                    })}
                    data-test="subitem-search-filters"
                  >
                    Filters
                  </div>
                  <div
                    onClick={() => setOpenPage('search-2')}
                    className={classNames('mt-1 pointer tutorial-item', {
                      'text-primary-asian tutorial-item-active':
                        openPage === 'search-2',
                    })}
                    data-test="subitem-search-project"
                  >
                    Project details
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="col-md-8" data-test="text-tutorial">
            {openPage === 'portfolio-distribution-1' && (
              <div className={styles.TutPage} data-test="subtext-portfolio-FCAS-SIDS">
                <div className={styles.TitTutPage}>FCAS and SIDS Portfolio</div>
                <div>
                  Portfolio information is provided for sovereign and
                  nonsovereign operations in FCAS and SIDS. Click a card to
                  access additional layer of information.
                </div>
              </div>
            )}
            {openPage === 'portfolio-distribution-2' && (
              <div className={styles.TutPage} data-test="subtext-portfolio-trend">
                <div className={styles.TitTutPage}>Trend and Snapshot</div>
                <div>
                  On the top right of the page, a button allows switching
                  between “trend” and “snapshot” views. Select the preferred
                  visualization and obtain portfolio information for a specific
                  year or over periods of time.
                </div>
              </div>
            )}
            {openPage === 'portfolio-distribution-3' && (
              <div className={styles.TutPage} data-test="subtext-portfolio-number-products">
                <div className={styles.TitTutPage}>
                  Portfolio amount and number of products
                </div>
                <div>
                  From the dropdown on the left, select the year displayed or
                  cluster information by sector, modality or fund. A tooltip
                  showing detailed information will appear when hovering on a
                  card.
                </div>
                <div className='mt-2'>
                  Click a card to display country-by-country distribution while
                  clicking further provides product information of selected
                  country.
                </div>
              </div>
            )}
            {openPage === 'ppr-1' && (
              <div className={styles.TutPage} data-test="subtext-ppr-FCAS-SIDS">
                <div className={styles.TitTutPage}>
                  FCAS and SIDS Project Performance Ratings
                </div>
                <div>
                  Project performance rating uses a three-level traffic light
                  (green, amber and red) to assess progress (on track, for
                  attention, or at risk, respectively). Click a card to show
                  country-by-country ratings.
                </div>
              </div>
            )}
            {openPage === 'ppr-2' && (
              <div className={styles.TutPage} data-test="subtext-ppr-trend">
                <div className={styles.TitTutPage}>Trend and Snapshot</div>
                <div>
                  On the top right of the page, a button allows to switch
                  between “trend” and “snapshot” views. Select the preferred
                  visualization and obtain portfolio information over time or
                  for a specific year.
                </div>
              </div>
            )}
            {openPage === 'ppr-3' && (
              <div className={styles.TutPage} data-test="subtext-ppr-performance">
                <div className={styles.TitTutPage}>
                  Project Performance Rating by Country
                </div>
                <div>
                  Click a card to show the aggregate project performance rating
                  of a country including information on low ratings obtained
                  across the five performance indicators (contract award,
                  disbursement, financial management, output and safeguards).
                </div>
              </div>
            )}
            {openPage === 'disbursment-1' && (
              <div className={styles.TutPage} data-test="subtext-disbursement-FCAS-SIDS">
                <div className={styles.TitTutPage}>
                  FCAS and SIDS Contract Award and Disbursement
                </div>
                <div>
                  View contract award and disbursement information on FCAS and
                  SIDS. Click a card to show the contract award and disbursement
                  of countries in a group.
                </div>
              </div>
            )}
            {openPage === 'disbursment-2' && (
              <div className={styles.TutPage} data-test="subtext-disbursement-trend">
                <div className={styles.TitTutPage}>Trend and Snapshot</div>
                <div>
                  On the top right of the page, a button allows to switch
                  between “trend” and “snapshot” views. Select the preferred
                  visualization and obtain portfolio information over time or
                  for a specific year.
                </div>
              </div>
            )}
            {openPage === 'disbursment-3' && (
              <div className={styles.TutPage} data-test="subtext-disbursement-contract">
                <div className={styles.TitTutPage}>
                  Contracts Awarded and Disbursement
                </div>
                <div>
                  From the dropdowns on the left, it is possible to change the
                  selection for the year displayed and to cluster data by
                  modality. Clicking on the card allows drilling down and
                  displaying the country-by-country distributions.
                </div>
              </div>
            )}
            {openPage === 'completed-1' && (
              <div className={styles.TutPage} data-test="subtext-completed-timeline">
                <div className={styles.TitTutPage}>Timeline</div>
                <div>
                  Completed products are visualized across a timeline. A button
                  at the top right corner allows switching between the different
                  groups while the dropdown slightly below allows selecting by
                  sector and countries.
                </div>
                <div className='mt-2'>
                  A tooltip showing detailed information will appear when
                  hovering on a bubble.
                </div>
              </div>
            )}
            {openPage === 'completed-2' && (
              <div className={styles.TutPage} data-test="subtext-completed-project">
                <div className={styles.TitTutPage}>Project details</div>
                <div>
                  Click on a bubble to open a visualization of the project and
                  its components. A tooltip showing relevant data will appear
                  when hovering on a year in the timeline.
                </div>
              </div>
            )}
            {openPage === 'indicative-1' && (
              <div className={styles.TutPage}>
                <div className={styles.TitTutPage}>Timeline</div>
                <div>
                  Products in the pipeline are distributed by country over a
                  two-year period. A tooltip showing detailed information will
                  appear when hovering on a bubble.
                </div>
                <div>
                  A button at the top right corner allows switching between the
                  different groups while the dropdown allows selecting by sector
                  and countries.
                </div>
              </div>
            )}
            {openPage === 'search-1' && (
              <div className={styles.TutPage} data-test="subtext-search-filters">
                <div className={styles.TitTutPage}>Filters</div>
                <div>
                  This feature allows advanced exploration of portfolio data.
                  Users can make multiple selections in the filter panel while
                  using the search bar to look for specific information in the
                  list. Resulting dataset or output is downloadable in Excel.
                </div>
              </div>
            )}
            {openPage === 'search-2' && (
              <div className={styles.TutPage} data-test="subtext-search-project">
                <div className={styles.TitTutPage}>Project details</div>
                <div>
                  Clicking a row in the results table will provide a
                  visualization of the project components. A tooltip showing
                  relevant data will appear when hovering on a year in the
                  timeline.
                </div>
              </div>
            )}
            {open === 'portfolio-distribution' && (
              <PortfolioTutorial
                openPage={openPage}
                setOpen={setOpen}
                setOpenPage={setOpenPage}
              />
            )}
            {open === 'ppr' && (
              <PprTutorial
                setOpen={setOpen}
                openPage={openPage}
                setOpenPage={setOpenPage}
              />
            )}
            {open === 'disbursment' && (
              <DisbursementTutorial
                openPage={openPage}
                setOpen={setOpen}
                setOpenPage={setOpenPage}
              />
            )}
            {open === 'completed' && (
              <CompletedTutorial
                openPage={openPage}
                setOpen={setOpen}
                setOpenPage={setOpenPage}
              />
            )}
            {open === 'indicative' && (
              <IndicativeTutorial
                openPage={openPage}
                setOpen={setOpen}
                setOpenPage={setOpenPage}
              />
            )}
            {open === 'search' && (
              <SearchTutorial
                setOpen={setOpen}
                openPage={openPage}
                setOpenPage={setOpenPage}
              />
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
